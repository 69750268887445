import { RequestTaskDTO } from 'pmrv-api';

export const requestTaskTypeToPeerReviewRequestTaskType: Partial<
  Record<RequestTaskDTO['type'], RequestTaskDTO['type']>
> = {
  PERMIT_ISSUANCE_APPLICATION_REVIEW: 'PERMIT_ISSUANCE_APPLICATION_PEER_REVIEW',
  PERMIT_SURRENDER_APPLICATION_REVIEW: 'PERMIT_SURRENDER_APPLICATION_PEER_REVIEW',
  PERMIT_REVOCATION_APPLICATION_SUBMIT: 'PERMIT_REVOCATION_APPLICATION_PEER_REVIEW',
  PERMIT_NOTIFICATION_APPLICATION_REVIEW: 'PERMIT_NOTIFICATION_APPLICATION_PEER_REVIEW',
  PERMIT_VARIATION_REGULATOR_LED_APPLICATION_SUBMIT: 'PERMIT_VARIATION_REGULATOR_LED_APPLICATION_PEER_REVIEW',
  PERMIT_VARIATION_APPLICATION_REVIEW: 'PERMIT_VARIATION_APPLICATION_PEER_REVIEW',
  PERMIT_TRANSFER_B_APPLICATION_REVIEW: 'PERMIT_TRANSFER_B_APPLICATION_PEER_REVIEW',
  NON_COMPLIANCE_DAILY_PENALTY_NOTICE: 'NON_COMPLIANCE_DAILY_PENALTY_NOTICE_APPLICATION_PEER_REVIEW',
  NON_COMPLIANCE_NOTICE_OF_INTENT: 'NON_COMPLIANCE_NOTICE_OF_INTENT_APPLICATION_PEER_REVIEW',
  NON_COMPLIANCE_CIVIL_PENALTY: 'NON_COMPLIANCE_CIVIL_PENALTY_APPLICATION_PEER_REVIEW',
  NER_APPLICATION_REVIEW: 'NER_APPLICATION_PEER_REVIEW',
  DOAL_APPLICATION_SUBMIT: 'DOAL_APPLICATION_PEER_REVIEW',
  DRE_APPLICATION_SUBMIT: 'DRE_APPLICATION_PEER_REVIEW',
  WITHHOLDING_OF_ALLOWANCES_APPLICATION_SUBMIT: 'WITHHOLDING_OF_ALLOWANCES_APPLICATION_PEER_REVIEW',
  EMP_ISSUANCE_UKETS_APPLICATION_REVIEW: 'EMP_ISSUANCE_UKETS_APPLICATION_PEER_REVIEW',
  EMP_ISSUANCE_CORSIA_APPLICATION_REVIEW: 'EMP_ISSUANCE_CORSIA_APPLICATION_PEER_REVIEW',
  EMP_VARIATION_UKETS_APPLICATION_REVIEW: 'EMP_VARIATION_UKETS_APPLICATION_PEER_REVIEW',
  EMP_VARIATION_CORSIA_APPLICATION_REVIEW: 'EMP_VARIATION_CORSIA_APPLICATION_PEER_REVIEW',
  EMP_VARIATION_UKETS_REGULATOR_LED_APPLICATION_SUBMIT: 'EMP_VARIATION_UKETS_REGULATOR_LED_APPLICATION_PEER_REVIEW',
  EMP_VARIATION_CORSIA_REGULATOR_LED_APPLICATION_SUBMIT: 'EMP_VARIATION_CORSIA_REGULATOR_LED_APPLICATION_PEER_REVIEW',
  AVIATION_DRE_UKETS_APPLICATION_SUBMIT: 'AVIATION_DRE_UKETS_APPLICATION_PEER_REVIEW',
  RETURN_OF_ALLOWANCES_APPLICATION_SUBMIT: 'RETURN_OF_ALLOWANCES_APPLICATION_PEER_REVIEW',
  AVIATION_NON_COMPLIANCE_DAILY_PENALTY_NOTICE: 'AVIATION_NON_COMPLIANCE_DAILY_PENALTY_NOTICE_APPLICATION_PEER_REVIEW',
  AVIATION_NON_COMPLIANCE_NOTICE_OF_INTENT: 'AVIATION_NON_COMPLIANCE_NOTICE_OF_INTENT_APPLICATION_PEER_REVIEW',
  AVIATION_NON_COMPLIANCE_CIVIL_PENALTY: 'AVIATION_NON_COMPLIANCE_CIVIL_PENALTY_APPLICATION_PEER_REVIEW',
};
