<ng-container *ngFor="let notification of notifications$ | async">
  <div
    class="govuk-notification-banner"
    role="region"
    aria-labelledby="govuk-notification-banner-title"
    data-module="govuk-notification-banner"
  >
    <div class="govuk-notification-banner__header">
      <h2 class="govuk-notification-banner__title" id="govuk-notification-banner-title">
        {{ notification?.subject }}
      </h2>
    </div>
    <div class="govuk-notification-banner__content">
      <p class="govuk-notification-banner__heading">
        {{ notification?.body }}
      </p>
    </div>
  </div>
</ng-container>
