<app-page-heading size="xl">Source stream categories</app-page-heading>
<p class="govuk-body">
  The GHGE permit application requires source streams to be defined in another section of this permit application. They
  are the fuels and materials which are the source of greenhouse gas emissions at the installation.
</p>

<p class="govuk-body">
  A GHGE permit monitoring plan requires that each source stream is defined within a category which reflects the amount
  of greenhouse gas emissions that it accounts for in tonnes - while at the same time considering the proportion of
  emissions as a percentage (%) within each category.
</p>

<p class="govuk-body">
  A source stream can exist in one or more categories. For example an installation may have a natural gas fuel used for
  two different purposes where one is a major source stream and the other, minor.
</p>

<p class="govuk-body">
  In general, major source stream categories will require a greater degree of accuracy in accounting for emissions than
  minor source stream categories.
</p>

<h2 class="govuk-heading-l">Planning for how to include source stream categories</h2>
<p class="govuk-body">Source stream categories must be defined according to a set of rules.</p>

<p class="govuk-body">
  You will only be able to assess if your source stream categories have met these rules and are in the correct
  categories once you have included and accounted for all of the source stream categories at your installation.
</p>

<p class="govuk-body">
  The next step for source stream categories (excluding those within fall-back) is to review and apply the relevant tier
  (as defined in the Monitoring and Reporting Regulations (MRR)). Since the tier requirements change depending upon the
  source stream category, it would be prudent to ensure you have included and reviewed all of your source stream
  categories prior to moving on to the next step to apply tiers.
</p>

<h2 class="govuk-heading-l">Source stream category rules</h2>
<h2 class="govuk-heading-m">Marginal</h2>
<p class="govuk-body">
  Any number of source streams can be included within the marginal category providing that they jointly do not add up to
  account for more than 10t CO2e
</p>

<h2 class="govuk-heading-m">De-Minimis</h2>
<p class="govuk-body">
  Any number of source streams can be included within the de-minimis category providing that they jointly do not add up
  to exceed either:
</p>
<p class="govuk-body">1,000 tonnes of CO2e per year</p>
<p class="govuk-body">OR</p>
<p class="govuk-body">
  Do not account for more than 2% of the emissions at the installation, up to a total maximum contribution of 20,000
  tonnes of CO2e per year
</p>

<h2 class="govuk-heading-m">Minor</h2>
<p class="govuk-body">
  Any number of source streams can be included within the minor category providing that they jointly do not add up to
  exceed either:
</p>
<p class="govuk-body">5,000 tonnes of CO2e per year</p>
<p class="govuk-body">OR</p>
<p class="govuk-body">
  Account for no more than 10% of the emissions at the installation, up to a total maximum contribution of 100,000
  tonnes of CO2e per year
</p>
<p class="govuk-body"></p>

<h2 class="govuk-heading-m">Major</h2>
<p class="govuk-body">Any number of source streams can be included within the major category</p>
<p class="govuk-body">
  Source streams which cannot be included in any other lesser category must be included within the major category
</p>
