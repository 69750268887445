<div formControlName="name" govuk-text-input hint="The name of the holding company" label="Holding company name"></div>
<div
  formControlName="registrationNumber"
  govuk-text-input
  hint="The holding company registration number or equivalent, for example the company number shown on the certificate of incorporation."
  label="Holding company registration number (optional)"
></div>
<div formGroupName="address">
  <div govuk-text-input autoComplete="address-line1" formControlName="line1" label="Address line 1"></div>
  <div govuk-text-input autoComplete="address-line2" formControlName="line2" label="Address line 2 (optional)"></div>
  <div
    govuk-text-input
    autoComplete="address-level2"
    formControlName="city"
    label="Town or city"
    widthClass="govuk-!-width-one-third"
  ></div>
  <div
    govuk-text-input
    autoComplete="postal-code"
    formControlName="postcode"
    label="Post code"
    widthClass="govuk-!-width-one-third"
  ></div>
</div>
