<ng-container *ngIf="action$ | async as action">
  <app-request-action-heading
    [headerText]="action.decision?.type === 'AGREE' ? 'Peer review approval' : 'Peer review rejection'"
    [timelineCreationDate]="action.creationDate"
  >
  </app-request-action-heading>
  <hr class="govuk-!-margin-bottom-6" />
  <h2 class="govuk-heading-m">Details</h2>
  <dl govuk-summary-list *ngIf="action" [hasBorders]="false">
    <div govukSummaryListRow>
      <dt govukSummaryListRowKey>Decision</dt>
      <dd govukSummaryListRowValue>{{ action.decision?.type | determinationAssessment }}</dd>
    </div>
    <div govukSummaryListRow>
      <dt govukSummaryListRowKey>Supporting notes</dt>
      <dd class="pre-wrap" govukSummaryListRowValue>{{ action.decision?.notes }}</dd>
    </div>
    <div govukSummaryListRow>
      <dt govukSummaryListRowKey>Peer reviewer</dt>
      <dd govukSummaryListRowValue>{{ action.submitter }}</dd>
    </div>
  </dl>
</ng-container>
