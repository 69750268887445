<app-page-heading size="xl">Monitoring Approaches</app-page-heading>
<p class="govuk-body">
  Most installations generate greenhouse gas emissions only through the combustion of fuel for heat and power. The fuels
  used are typically commercially available standard fuels.
</p>

<p class="govuk-body">
  Installations in this situation are able to produce a monitoring plan using a calculation approach to estimate the
  amount of carbon emissions based on the amount of fuel used.
</p>

<p class="govuk-body">
  For situations where a calculation approach is not possible, the Monitoring and Reporting Regulations (MRR) should be
  reviewed to learn more about using a monitoring plan based on a measurement approach - where greenhouse gases are
  measured directly where they are emitted.
</p>

<p class="govuk-body">
  If a monitoring plan cannot be created based on either a calculation or measurement approach, then the last resort is
  a fall-back approach where greenhouse gas emissions are estimated.
</p>

<p class="govuk-body">
  Installations which emit nitrous oxide (N20) or Perflourocarbons (PFC) must also include a monitoring plan for these
  emissions in alongside a plan for their other emissions.
</p>
