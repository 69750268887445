<dl govuk-summary-list>
  <div govukSummaryListRow>
    <dt govukSummaryListRowKey>Are there additional documents you would like to provide?</dt>
    <dd govukSummaryListRowValue>{{ additionalDocuments.exist ? 'Yes' : 'No' }}</dd>
    <dd govukSummaryListRowActions *ngIf="editable">
      <a govukLink routerLink=".." [state]="{ changing: true }">Change</a>
    </dd>
  </div>
  <ng-container *ngIf="additionalDocuments.exist">
    <div govukSummaryListRow>
      <dt govukSummaryListRowKey>Uploaded files</dt>
      <dd govukSummaryListRowValue>
        <app-summary-download-files [files]="documents"> </app-summary-download-files>
      </dd>
      <dd govukSummaryListRowActions *ngIf="editable">
        <a govukLink [routerLink]="['..']" [state]="{ changing: true }">Change</a>
      </dd>
    </div>
  </ng-container>
  <div govukSummaryListRow *ngIf="additionalDocuments.comment">
    <dt govukSummaryListRowKey>Comments</dt>
    <dd govukSummaryListRowValue class="pre-wrap">{{ additionalDocuments.comment }}</dd>
    <dd govukSummaryListRowActions *ngIf="editable">
      <a govukLink [routerLink]="['..']" [state]="{ changing: true }">Change</a>
    </dd>
  </div>
</dl>
