<ng-container *ngIf="vm$ | async as vm">
  <ng-container *ngIf="vm.userRoleType === 'VERIFIER' && vm.total === 0; else search">
    <app-page-heading size="xl">There are no accounts to view.</app-page-heading>
  </ng-container>

  <ng-template #search>
    <ng-container *ngIf="vm.userRoleType !== 'VERIFIER' || vm.total > 0">
      <govuk-error-summary *ngIf="vm.isSummaryDisplayed" [form]="searchForm"></govuk-error-summary>

      <app-page-heading size="xl">Accounts</app-page-heading>
      <span class="govuk-hint">Enter ID, name or code</span>

      <div class="govuk-grid-row">
        <form (ngSubmit)="onSearch()" [formGroup]="searchForm" id="search-form">
          <div class="govuk-grid-column-full">
            <div class="govuk-grid-row">
              <div class="govuk-grid-column-three-quarters">
                <div formControlName="term" govuk-text-input></div>
              </div>
              <div class="govuk-grid-column-one-quarter">
                <button appPendingButton govukButton type="submit">Search</button>
              </div>
            </div>
          </div>
          <div class="govuk-grid-column-full">
            <ng-container *ngIf="vm.accounts?.length; else noResults">
              <app-accounts-list
                [accounts]="vm.accounts"
                (selectAccount)="navigateToAccount($event.id)"
              ></app-accounts-list>
              <app-pagination
                *ngIf="vm.total > vm.pageSize"
                [count]="vm.total"
                (currentPageChange)="onPageChange($event)"
                [pageSize]="vm.pageSize"
              ></app-pagination>
            </ng-container>
            <ng-template #noResults>
              <div class="govuk-grid-row">
                <div class="govuk-grid-column-full">
                  <p class="govuk-body" tabindex="-1">There are no results to show</p>
                </div>
              </div>
            </ng-template>
          </div>
        </form>
      </div>
    </ng-container>
  </ng-template>
</ng-container>
