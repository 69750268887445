<dl govuk-summary-list>
  <div govukSummaryListRow>
    <dt govukSummaryListRowKey>Reason for decision</dt>
    <dd govukSummaryListRowValue class="pre-wrap">
      {{ determination.reason }}
    </dd>
    <dd govukSummaryListRowActions *ngIf="editable">
      <a govukLink [routerLink]="['..', 'reason']" [state]="{ changing: true }">Change</a>
    </dd>
  </div>
</dl>
