import { SourceStream } from 'pmrv-api';

export const descriptionOptions: SourceStream['description'][] = [
  'ACETYLENE',
  'ANTHRACITE',
  'BIODIESELS',
  'BIOGASOLINE',
  'BIOMASS',
  'BITUMEN',
  'BLAST_FURNACE_GAS',
  'BLENDED_FUEL_GAS',
  'CARBON_MONOXIDE',
  'CHARCOAL',
  'COAL',
  'COAL_TAR',
  'COKE',
  'COKE_OVEN_COKE_LIGNITE_COKE',
  'COKE_OVEN_GAS',
  'COKING_COAL',
  'COLLIERY_METHANE',
  'CRUDE_OIL',
  'ETHANE',
  'FUEL_GAS',
  'FUEL_OIL',
  'GAS_COKE',
  'GAS_DIESEL_OIL',
  'GAS_OIL',
  'GAS_WORKS',
  'HIGH_PRESSURE_FLARE_GAS',
  'IMPORT_FUEL_GAS',
  'INDUSTRIAL_WASTES',
  'KEROSENE_OTHER_THAN_JET_KEROSENE',
  'LANDFILL_GAS',
  'LIGNITE',
  'LIQUEFIED_PETROLEUM_GASES',
  'LOW_LOW_PRESSURE_FLARE_GAS',
  'LOW_PRESSURE_FLARE_GAS',
  'LUBRICANTS',
  'MATERIAL',
  'MEDIUM_PRESSURE_FLARE_GAS',
  'METHANE',
  'MOTOR_GASOLINE',
  'MSW',
  'NAPHTHA',
  'NATURAL_GAS',
  'NATURAL_GAS_LIQUIDS',
  'NON_BIOMASS_PACKAGING_WASTE',
  'OIL_SHALE_AND_TAR_SANDS',
  'OPG',
  'ORIMULSION',
  'OTHER',
  'OTHER_BIOGAS',
  'OTHER_BITUMINOUS_COAL',
  'OTHER_LIQUID_BIOFUELS',
  'OTHER_PETROLEUM_PRODUCTS',
  'OTHER_PRIMARY_SOLID_BIOMASS',
  'OXYGEN_STEEL_FURNACE_GAS',
  'PARAFFIN_WAXES',
  'PATENT_FUEL',
  'PEAT',
  'PETROL',
  'PETROLEUM_COKE',
  'PILOT_AND_PURGE_FLARE_GAS',
  'PILOT_FLARE_GAS',
  'PROPANE',
  'REFINERY_FEEDSTOCKS',
  'REFINERY_GAS',
  'RESIDUAL_FUEL_OIL',
  'SCRAP_TYRES',
  'SHALE_OIL',
  'SLUDGE_GAS',
  'SOUR_GAS',
  'SOUR_GAS_FLARE',
  'SSF',
  'SUB_BITUMINOUS_COAL',
  'WASTE_OILS',
  'WASTE_SOLVENTS',
  'WASTE_TYRES',
  'WHITE_SPIRIT_SBP',
  'WOOD_WOOD_WASTE',
];

export const typeOptions: SourceStream['type'][] = [
  'NON_FERROUS_SEC_ALUMINIUM_MASS_BALANCE_METHODOLOGY',
  'NON_FERROUS_SEC_ALUMINIUM_PROCESS_EMISSIONS',
  'AMMONIA_FUEL_AS_PROCESS_INPUT',
  'BULK_ORGANIC_CHEMICALS_MASS_BALANCE_METHODOLOGY',
  'CARBON_BLACK_MASS_BALANCE_METHODOLOGY',
  'CEMENT_CLINKER_CKD',
  'CEMENT_CLINKER_CLINKER_OUTPUT_METHOD_B',
  'CEMENT_CLINKER_KILN_INPUT_BASED_METHOD_A',
  'CEMENT_CLINKER_NON_CARBONATE_CARBON',
  'CERAMICS_ALKALI_OXIDE_METHOD_B',
  'CERAMICS_CARBON_INPUTS_METHOD_A',
  'CERAMICS_SCRUBBING',
  'COKE_CARBONATE_INPUT_METHOD_A',
  'COKE_FUEL_AS_PROCESS_INPUT',
  'COKE_MASS_BALANCE',
  'COKE_OXIDE_OUTPUT_METHOD_B',
  'COMBUSTION_COMMERCIAL_STANDARD_FUELS',
  'COMBUSTION_FLARES',
  'COMBUSTION_GAS_PROCESSING_TERMINALS',
  'COMBUSTION_OTHER_GASEOUS_LIQUID_FUELS',
  'COMBUSTION_SCRUBBING_CARBONATE',
  'COMBUSTION_SCRUBBING_GYPSUM',
  'COMBUSTION_SOLID_FUELS',
  'GLASS_AND_MINERAL_WOOL_CARBONATES_INPUT',
  'HYDROGEN_AND_SYNTHESIS_GAS_FUEL_AS_PROCESS_INPUT',
  'HYDROGEN_AND_SYNTHESIS_GAS_MASS_BALANCE_METHODOLOGY',
  'IRON_STEEL_CARBONATE_INPUT',
  'IRON_STEEL_FUEL_AS_PROCESS_INPUT',
  'IRON_STEEL_MASS_BALANCE',
  'LIME_DOLOMITE_MAGNESITE_ALKALI_EARTH_OXIDE_METHOD_B',
  'LIME_DOLOMITE_MAGNESITE_CARBONATES_METHOD_A',
  'LIME_DOLOMITE_MAGNESITE_KILN_DUST_METHOD_B',
  'METAL_ORE_CARBONATE_INPUT',
  'METAL_ORE_MASS_BALANCE',
  'OTHER',
  'PRIMARY_ALUMINIUM_MASS_BALANCE_METHODOLOGY',
  'PULP_PAPER_MAKE_UP_CHEMICALS',
  'REFINERIES_CATALYTIC_CRACKER_REGENERATION',
  'REFINERIES_HYDROGEN_PRODUCTION',
  'REFINERIES_MASS_BALANCE',
  'SODA_ASH_SODIUM_BICARBONATE_MASS_BALANCE_METHODOLOGY',
  'COMBUSTION_SCRUBBING_UREA',
  'PRIMARY_ALUMINIUM_PFC',
];
