<ng-container *ngIf="recommendedImprovements">
  <h2 class="govuk-heading-m" *ngIf="headingLarge">Recommended improvements</h2>

  <ng-container *ngIf="showGuardQuestion && recommendedImprovements?.areThereRecommendedImprovements !== undefined">
    <dl govuk-summary-list>
      <div govukSummaryListRow>
        <dt govukSummaryListRowKey>Are there any recommended improvements?</dt>
        <dd govukSummaryListRowValue>{{ recommendedImprovements.areThereRecommendedImprovements ? 'Yes' : 'No' }}</dd>
        <dd govukSummaryListRowActions *ngIf="isEditable">
          <a govukLink [routerLink]="[baseLink]">Change</a>
        </dd>
      </div>
    </dl>
  </ng-container>

  <ng-container *ngIf="recommendedImprovements?.recommendedImprovements?.length > 0">
    <govuk-table
      [columns]="columns"
      [data]="recommendedImprovements.recommendedImprovements"
      [class.no-bottom-border]="false"
      [caption]="showCaption ? 'Recommended improvements' : ''"
    >
      <ng-template let-column="column" let-index="index" let-row="row">
        <ng-container [ngSwitch]="column.field">
          <ng-container *ngSwitchCase="'reference'">{{ row.reference }}</ng-container>
          <ng-container *ngSwitchCase="'explanation'">
            <div class="pre-wrap">{{ row.explanation }}</div>
          </ng-container>
          <ng-container *ngIf="isEditable">
            <ng-container *ngSwitchCase="'change'">
              <a [routerLink]="[baseLink, index]" govukLink>Change</a>
            </ng-container>
            <ng-container *ngSwitchCase="'delete'">
              <a [routerLink]="[baseLink, 'delete', index]" govukLink>Remove</a>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-template>
    </govuk-table>
    <div class="govuk-button-group" *ngIf="isEditable">
      <button
        govukSecondaryButton
        [routerLink]="[baseLink, recommendedImprovements?.recommendedImprovements?.length ?? 0]"
        type="button"
      >
        Add another item
      </button>
    </div>
  </ng-container>
</ng-container>
