<div class="govuk-grid-row">
  <div class="govuk-grid-column-three-quarters">
    <app-page-heading size="xl">Batch variations</app-page-heading>
  </div>
  <ng-container *ngIf="canInitBatchReissue$ | async">
    <div class="govuk-grid-column-one-quarter">
      <button govukButton type="button" routerLink="submit" class="start-batch-variation">
        Start a batch variation
      </button>
    </div>
  </ng-container>
</div>
<div class="govuk-grid-row">
  <ng-container *ngIf="(batchReissues$ | async)?.length; else noResults">
    <govuk-table [columns]="columns" [data]="batchReissues$ | async">
      <ng-template let-column="column" let-row="row">
        <ng-container [ngSwitch]="column.field">
          <ng-container *ngSwitchCase="'requestId'">
            <a [routerLink]="[]" (click)="viewDetails(row[column.field])" govukLink>{{ row[column.field] }}</a>
          </ng-container>
          <ng-container *ngSwitchCase="'submitter'">
            {{ row[column.field] }}
          </ng-container>
          <ng-container *ngSwitchCase="'submissionDate'">{{ row[column.field] | govukDate }}</ng-container>
          <ng-container *ngSwitchCase="'numberOfAccounts'">{{ row[column.field] }}</ng-container>
        </ng-container>
      </ng-template>
    </govuk-table>
    <app-pagination
      (currentPageChange)="currentPage$.next($event)"
      [count]="totalPages$ | async"
      [pageSize]="pageSize"
    ></app-pagination>
  </ng-container>
  <ng-template #noResults>
    <div class="govuk-grid-row">
      <div class="govuk-grid-column-full">
        <p class="govuk-body" tabindex="-1">There are no results to show</p>
      </div>
    </div>
  </ng-template>
</div>
