<ng-container *ngIf="fallbackEmissions">
  <h2 class="govuk-heading-m">Details</h2>
  <dl govuk-summary-list>
    <div govukSummaryListRow>
      <dt govukSummaryListRowKey>Source streams</dt>
      <dd govukSummaryListRowValue class="pre-wrap">{{ sourceStreams }}</dd>
      <dd govukSummaryListRowActions *ngIf="isEditable">
        <a govukLink routerLink=".." [state]="{ changing: true }">Change</a>
      </dd>
    </div>
    <div govukSummaryListRow>
      <dt govukSummaryListRowKey>Does any of the selected source streams contain biomass?</dt>
      <dd govukSummaryListRowValue>{{ fallbackEmissions?.biomass?.contains ? 'Yes' : 'No' }}</dd>
      <dd govukSummaryListRowActions *ngIf="isEditable">
        <a govukLink routerLink=".." [state]="{ changing: true }">Change</a>
      </dd>
    </div>
    <div govukSummaryListRow>
      <dt govukSummaryListRowKey>Description of the fallback approach</dt>
      <dd govukSummaryListRowValue class="pre-wrap">{{ fallbackEmissions?.description }}</dd>
      <dd govukSummaryListRowActions *ngIf="isEditable">
        <a govukLink routerLink="../description" [state]="{ changing: true }">Change</a>
      </dd>
    </div>
    <div govukSummaryListRow>
      <dt govukSummaryListRowKey>Document upload</dt>
      <dd govukSummaryListRowValue>
        <app-summary-download-files [files]="documentFiles"></app-summary-download-files>
      </dd>
      <dd govukSummaryListRowActions *ngIf="isEditable">
        <a govukLink routerLink="../upload-documents" [state]="{ changing: true }">Change</a>
      </dd>
    </div>
  </dl>

  <h2 class="govuk-heading-m">Calculated emissions</h2>
  <dl govuk-summary-list>
    <div govukSummaryListRow>
      <dt govukSummaryListRowKey>Fossil emissions</dt>
      <dd govukSummaryListRowValue>{{ fallbackEmissions?.totalFossilEmissions }} tonnes CO2e</dd>
      <dd govukSummaryListRowActions *ngIf="isEditable">
        <a govukLink routerLink="../total-emissions" [state]="{ changing: true }">Change</a>
      </dd>
    </div>
    <div govukSummaryListRow>
      <dt govukSummaryListRowKey>Fossil energy content</dt>
      <dd govukSummaryListRowValue>{{ fallbackEmissions?.totalFossilEnergyContent }} terajoules</dd>
      <dd govukSummaryListRowActions *ngIf="isEditable">
        <a govukLink routerLink="../total-emissions" [state]="{ changing: true }">Change</a>
      </dd>
    </div>
    <div govukSummaryListRow *ngIf="fallbackEmissions?.biomass?.totalSustainableBiomassEmissions">
      <dt govukSummaryListRowKey>Sustainable biomass emissions</dt>
      <dd govukSummaryListRowValue>
        {{ fallbackEmissions?.biomass?.totalSustainableBiomassEmissions }}
        tonnes CO2e
      </dd>
      <dd govukSummaryListRowActions *ngIf="isEditable">
        <a govukLink routerLink="../total-emissions" [state]="{ changing: true }">Change</a>
      </dd>
    </div>
    <div govukSummaryListRow *ngIf="fallbackEmissions?.biomass?.totalEnergyContentFromBiomass">
      <dt govukSummaryListRowKey>Energy content from biomass</dt>
      <dd govukSummaryListRowValue>{{ fallbackEmissions?.biomass?.totalEnergyContentFromBiomass }} terajoules</dd>
      <dd govukSummaryListRowActions *ngIf="isEditable">
        <a govukLink routerLink="../total-emissions" [state]="{ changing: true }">Change</a>
      </dd>
    </div>
    <div govukSummaryListRow *ngIf="fallbackEmissions?.biomass?.totalNonSustainableBiomassEmissions">
      <dt govukSummaryListRowKey>Non sustainable biomass emissions</dt>
      <dd govukSummaryListRowValue>
        {{ fallbackEmissions?.biomass?.totalNonSustainableBiomassEmissions }} tonnes CO2e
      </dd>
      <dd govukSummaryListRowActions *ngIf="isEditable">
        <a govukLink routerLink="../total-emissions" [state]="{ changing: true }">Change</a>
      </dd>
    </div>
  </dl>
</ng-container>
