import { RequestActionDTO, RequestTaskDTO } from 'pmrv-api';

export function getVariationRequestTaskTypes(): RequestTaskDTO['type'][] {
  return [
    'PERMIT_VARIATION_APPLICATION_REVIEW',
    'PERMIT_VARIATION_APPLICATION_SUBMIT',
    'PERMIT_VARIATION_RDE_RESPONSE_SUBMIT',
    'PERMIT_VARIATION_REGULATOR_LED_APPLICATION_SUBMIT',
    'PERMIT_VARIATION_RFI_RESPONSE_SUBMIT',
    'PERMIT_VARIATION_WAIT_FOR_RDE_RESPONSE',
    'PERMIT_VARIATION_WAIT_FOR_REVIEW',
    'PERMIT_VARIATION_APPLICATION_PEER_REVIEW',
    'PERMIT_VARIATION_WAIT_FOR_PEER_REVIEW',
    'PERMIT_VARIATION_REGULATOR_LED_APPLICATION_PEER_REVIEW',
    'PERMIT_VARIATION_REGULATOR_LED_WAIT_FOR_PEER_REVIEW',
    'PERMIT_VARIATION_WAIT_FOR_RFI_RESPONSE',
    'PERMIT_VARIATION_WAIT_FOR_AMENDS',
    'PERMIT_VARIATION_APPLICATION_AMENDS_SUBMIT',
  ];
}

export function getVariationRequestActionTypes(): RequestActionDTO['type'][] {
  return [
    'PERMIT_VARIATION_APPLICATION_SUBMITTED',
    'PERMIT_VARIATION_APPLICATION_GRANTED',
    'PERMIT_VARIATION_APPLICATION_REJECTED',
    'PERMIT_VARIATION_APPLICATION_REGULATOR_LED_APPROVED',
    'PERMIT_VARIATION_APPLICATION_DEEMED_WITHDRAWN',
    'PERMIT_VARIATION_APPLICATION_PEER_REVIEWER_ACCEPTED',
    'PERMIT_VARIATION_APPLICATION_PEER_REVIEWER_REJECTED',
    'PERMIT_VARIATION_APPLICATION_RETURNED_FOR_AMENDS',
    'PERMIT_VARIATION_APPLICATION_AMENDS_SUBMITTED',
    'PERMIT_VARIATION_RECALLED_FROM_AMENDS',
  ];
}
