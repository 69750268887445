<dl govuk-summary-list>
  <div govukSummaryListRow>
    <dt govukSummaryListRowKey>Uploaded file</dt>
    <dd govukSummaryListRowValue>
      <app-summary-download-files [files]="documents"> </app-summary-download-files>
    </dd>
    <dd govukSummaryListRowActions *ngIf="editable">
      <a govukLink [routerLink]="['..']" [state]="{ changing: true }">Change</a>
    </dd>
  </div>
  <div govukSummaryListRow *ngIf="verificationActivityLevelReport.comment">
    <dt govukSummaryListRowKey>Comments</dt>
    <dd govukSummaryListRowValue class="pre-wrap">{{ verificationActivityLevelReport.comment }}</dd>
    <dd govukSummaryListRowActions *ngIf="editable">
      <a govukLink [routerLink]="['..']" [state]="{ changing: true }">Change</a>
    </dd>
  </div>
</dl>
