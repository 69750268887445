<nav *ngIf="currentPage | async as page" class="hmcts-pagination">
  <p aria-labelledby="id" class="govuk-visually-hidden">Pagination navigation</p>
  <ul class="hmcts-pagination__list" *ngIf="totalPages > 1">
    <li *ngIf="page > 1" class="hmcts-pagination__item hmcts-pagination__item--prev">
      <a
        [fragment]="route.fragment | async"
        [queryParams]="{ page: page > 2 ? page - 1 : 1 }"
        [state]="{ scrollSkip: true }"
        class="hmcts-pagination__link"
        queryParamsHandling="merge"
        [routerLink]="[]"
      >
        Previous<span class="govuk-visually-hidden"> set of pages</span>
      </a>
    </li>
    <ng-container *ngIf="!hideNumbers">
      <ng-container *ngFor="let pageNumber of pageNumbers; first as isFirst; last as isLast">
        <li
          *ngIf="isFirst || isLast || isDisplayed(pageNumber, page) || isDots(pageNumber, page)"
          [class.hmcts-pagination__item--active]="page === pageNumber"
          [class.hmcts-pagination__item--dots]="isDots(pageNumber, page)"
          class="hmcts-pagination__item"
        >
          <ng-container *ngIf="!isDots(pageNumber, page); else dots">
            <a
              *ngIf="pageNumber !== page; else active"
              [fragment]="route.fragment | async"
              [queryParams]="{ page: pageNumber }"
              [state]="{ scrollSkip: true }"
              class="hmcts-pagination__link"
              queryParamsHandling="merge"
              [routerLink]="[]"
            >
              {{ pageNumber }}
            </a>
            <ng-template #active>{{ pageNumber }}</ng-template>
          </ng-container>
          <ng-template #dots>...</ng-template>
        </li>
      </ng-container>
    </ng-container>
    <li *ngIf="page !== pageNumbers.length" class="hmcts-pagination__item hmcts-pagination__item--next">
      <a
        [fragment]="route.fragment | async"
        [queryParams]="{ page: page < totalPages ? page + 1 : totalPages }"
        [state]="{ scrollSkip: true }"
        class="hmcts-pagination__link"
        queryParamsHandling="merge"
        [routerLink]="[]"
      >
        Next<span class="govuk-visually-hidden"> set of pages</span>
      </a>
    </li>
  </ul>
  <p *ngIf="!hideResultCount && count" class="hmcts-pagination__results govuk-body" style="float: right">
    Showing <strong>{{ (page - 1) * pageSize + 1 }}</strong> to
    <strong>{{ page * pageSize < count ? page * pageSize : count }}</strong> of
    <strong>{{ count }}</strong>
    results
  </p>
</nav>
