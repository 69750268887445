<dl *ngIf="compliance" govuk-summary-list [hasBorders]="true">
  <div govukSummaryListRow>
    <dt govukSummaryListRowKey>Accuracy</dt>
    <dd govukSummaryListRowValue class="pre-wrap">
      <span *ngIf="compliance.accuracy; else accuracyReason">Yes</span>
      <ng-template #accuracyReason>
        No <br />
        {{ compliance.accuracyReason }}
      </ng-template>
    </dd>
    <dd govukSummaryListRowActions *ngIf="isEditable">
      <a govukLink routerLink="..">Change</a>
    </dd>
  </div>

  <div govukSummaryListRow>
    <dt govukSummaryListRowKey>Completeness</dt>
    <dd govukSummaryListRowValue class="pre-wrap">
      <span *ngIf="compliance.completeness; else completenessReason">Yes</span>
      <ng-template #completenessReason>
        No <br />
        {{ compliance.completenessReason }}
      </ng-template>
    </dd>
    <dd govukSummaryListRowActions *ngIf="isEditable">
      <a govukLink routerLink="..">Change</a>
    </dd>
  </div>

  <div govukSummaryListRow>
    <dt govukSummaryListRowKey>Consistency</dt>
    <dd govukSummaryListRowValue class="pre-wrap">
      <span *ngIf="compliance.consistency; else consistencyReason">Yes</span>
      <ng-template #consistencyReason>
        No <br />
        {{ compliance.consistencyReason }}
      </ng-template>
    </dd>
    <dd govukSummaryListRowActions *ngIf="isEditable">
      <a govukLink routerLink="..">Change</a>
    </dd>
  </div>

  <div govukSummaryListRow>
    <dt govukSummaryListRowKey>Comparability over time</dt>
    <dd govukSummaryListRowValue class="pre-wrap">
      <span *ngIf="compliance.comparability; else comparabilityReason">Yes</span>
      <ng-template #comparabilityReason>
        No <br />
        {{ compliance.comparabilityReason }}
      </ng-template>
    </dd>
    <dd govukSummaryListRowActions *ngIf="isEditable">
      <a govukLink routerLink="..">Change</a>
    </dd>
  </div>

  <div govukSummaryListRow>
    <dt govukSummaryListRowKey>Transparency</dt>
    <dd govukSummaryListRowValue class="pre-wrap">
      <span *ngIf="compliance.transparency; else transparencyReason">Yes</span>
      <ng-template #transparencyReason>
        No <br />
        {{ compliance.transparencyReason }}
      </ng-template>
    </dd>
    <dd govukSummaryListRowActions *ngIf="isEditable">
      <a govukLink routerLink="..">Change</a>
    </dd>
  </div>
  <div govukSummaryListRow>
    <dt govukSummaryListRowKey>Integrity of methodology</dt>
    <dd govukSummaryListRowValue class="pre-wrap">
      <span *ngIf="compliance.integrity; else integrityReason">Yes</span>
      <ng-template #integrityReason>
        No <br />
        {{ compliance.integrityReason }}
      </ng-template>
    </dd>
    <dd govukSummaryListRowActions *ngIf="isEditable">
      <a govukLink routerLink="..">Change</a>
    </dd>
  </div>
</dl>
