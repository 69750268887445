import { NaceCodes } from 'pmrv-api';

export type MainActivity =
  | 'AGRICULTURE_FORESTRY_AND_FISHING'
  | 'MINING_AND_QUARRYING'
  | 'MANUFACTURING'
  | 'ELECTRICITY_GAS_STEAM_AND_AIR_CONDITIONING_SUPPLY'
  | 'WATER_SUPPLY_SEWERAGE_WASTE_MANAGEMENT_AND_REMEDIATION_ACTIVITIES'
  | 'CONSTRUCTION'
  | 'WHOLESALE_AND_RETAIL_TRADE_REPAIR_OF_MOTOR_VEHICLES_AND_MOTORCYCLES'
  | 'TRANSPORTATION_AND_STORAGE'
  | 'ACCOMMODATION_AND_FOOD_SERVICE_ACTIVITIES'
  | 'INFORMATION_AND_COMMUNICATION'
  | 'FINANCIAL_AND_INSURANCE_ACTIVITIES'
  | 'REAL_ESTATE_ACTIVITIES'
  | 'PROFESSIONAL_SCIENTIFIC_AND_TECHNICAL_ACTIVITIES'
  | 'ADMINISTRATIVE_AND_SUPPORT_SERVICE_ACTIVITIES'
  | 'PUBLIC_ADMINISTRATION_AND_DEFENCE_COMPULSORY_SOCIAL_SECURITY'
  | 'EDUCATION'
  | 'HUMAN_HEALTH_AND_SOCIAL_WORK_ACTIVITIES'
  | 'ARTS_ENTERTAINMENT_AND_RECREATION'
  | 'OTHER_SERVICE_ACTIVITIES'
  | 'ACTIVITIES_OF_HOUSEHOLDS_AS_EMPLOYERS_UNDIFFERENTIATED_GOODS_AND_SERVICES_PRODUCING_ACTIVITIES_OF_HOUSEHOLDS_FOR_OWN_USE'
  | 'ACTIVITIES_OF_EXTRATERRITORIAL_ORGANISATIONS_AND_BODIES';

export const mainActivities: Record<MainActivity, string> = {
  AGRICULTURE_FORESTRY_AND_FISHING: 'Agriculture, forestry and fishing',
  MINING_AND_QUARRYING: 'Mining and quarrying',
  MANUFACTURING: 'Manufacturing',
  ELECTRICITY_GAS_STEAM_AND_AIR_CONDITIONING_SUPPLY: 'Electricity, gas, steam and air conditioning supply',
  WATER_SUPPLY_SEWERAGE_WASTE_MANAGEMENT_AND_REMEDIATION_ACTIVITIES:
    'Water supply; sewerage; waste management and remediation activities',
  CONSTRUCTION: 'Construction',
  WHOLESALE_AND_RETAIL_TRADE_REPAIR_OF_MOTOR_VEHICLES_AND_MOTORCYCLES:
    'Wholesale and retail trade; repair of motor vehicles and motorcycles',
  TRANSPORTATION_AND_STORAGE: 'Transportation and storage',
  ACCOMMODATION_AND_FOOD_SERVICE_ACTIVITIES: 'Accommodation and food service activities',
  INFORMATION_AND_COMMUNICATION: 'Information and communication',
  FINANCIAL_AND_INSURANCE_ACTIVITIES: 'Financial and insurance activities',
  REAL_ESTATE_ACTIVITIES: 'Real estate activities',
  PROFESSIONAL_SCIENTIFIC_AND_TECHNICAL_ACTIVITIES: 'Professional, scientific and technical activities',
  ADMINISTRATIVE_AND_SUPPORT_SERVICE_ACTIVITIES: 'Administrative and support service activities',
  PUBLIC_ADMINISTRATION_AND_DEFENCE_COMPULSORY_SOCIAL_SECURITY:
    'Public administration and defence; compulsory social security',
  EDUCATION: 'Education',
  HUMAN_HEALTH_AND_SOCIAL_WORK_ACTIVITIES: 'Human health and social work activities',
  ARTS_ENTERTAINMENT_AND_RECREATION: 'Arts, entertainment and recreation',
  OTHER_SERVICE_ACTIVITIES: 'Other service activities',
  ACTIVITIES_OF_HOUSEHOLDS_AS_EMPLOYERS_UNDIFFERENTIATED_GOODS_AND_SERVICES_PRODUCING_ACTIVITIES_OF_HOUSEHOLDS_FOR_OWN_USE:
    'Activities of households as employers; undifferentiated goods- and services-producing activities of households for own use',
  ACTIVITIES_OF_EXTRATERRITORIAL_ORGANISATIONS_AND_BODIES: 'Activities of extraterritorial organisations and bodies',
};

export type SubCategory =
  | 'CROP_AND_ANIMAL_PRODUCTION_HUNTING_AND_RELATED_SERVICE_ACTIVITIES'
  | 'FORESTRY_AND_LOGGING'
  | 'FISHING_AND_AQUACULTURE'
  | 'MINING_OF_COAL_AND_LIGNITE'
  | 'EXTRACTION_OF_CRUDE_PETROLEUM_AND_NATURAL_GAS'
  | 'MINING_OF_METAL_ORES'
  | 'OTHER_MINING_AND_QUARRYING'
  | 'MINING_SUPPORT_SERVICE_ACTIVITIES'
  | 'MANUFACTURE_OF_FOOD_PRODUCTS'
  | 'MANUFACTURE_OF_BEVERAGES'
  | 'MANUFACTURE_OF_TOBACCO_PRODUCTS'
  | 'MANUFACTURE_OF_TEXTILES'
  | 'MANUFACTURE_OF_WEARING_APPAREL'
  | 'MANUFACTURE_OF_LEATHER_AND_RELATED_PRODUCTS'
  | 'MANUFACTURE_OF_WOOD_AND_OF_PRODUCTS_OF_WOOD_AND_CORK_EXCEPT_FURNITURE_MANUFACTURE_OF_ARTICLES_OF_STRAW_AND_PLAITING_MATERIALS'
  | 'MANUFACTURE_OF_PAPER_AND_PAPER_PRODUCTS'
  | 'PRINTING_AND_REPRODUCTION_OF_RECORDED_MEDIA'
  | 'MANUFACTURE_OF_COKE_AND_REFINED_PETROLEUM_PRODUCTS'
  | 'MANUFACTURE_OF_CHEMICALS_AND_CHEMICAL_PRODUCTS'
  | 'MANUFACTURE_OF_BASIC_PHARMACEUTICAL_PRODUCTS_AND_PHARMACEUTICAL_PREPARATIONS'
  | 'MANUFACTURE_OF_RUBBER_AND_PLASTIC_PRODUCTS'
  | 'MANUFACTURE_OF_OTHER_NON_METALLIC_MINERAL_PRODUCTS'
  | 'MANUFACTURE_OF_BASIC_METALS'
  | 'MANUFACTURE_OF_FABRICATED_METAL_PRODUCTS_EXCEPT_MACHINERY_AND_EQUIPMENT'
  | 'MANUFACTURE_OF_COMPUTER_ELECTRONIC_AND_OPTICAL_PRODUCTS'
  | 'MANUFACTURE_OF_ELECTRICAL_EQUIPMENT'
  | 'MANUFACTURE_OF_MACHINERY_AND_EQUIPMENT_N_E_C'
  | 'MANUFACTURE_OF_MOTOR_VEHICLES_TRAILERS_AND_SEMI_TRAILERS'
  | 'MANUFACTURE_OF_OTHER_TRANSPORT_EQUIPMENT'
  | 'MANUFACTURE_OF_FURNITURE'
  | 'OTHER_MANUFACTURING'
  | 'REPAIR_AND_INSTALLATION_OF_MACHINERY_AND_EQUIPMENT'
  | 'ELECTRICITY_GAS_STEAM_AND_AIR_CONDITIONING_SUPPLY'
  | 'WATER_COLLECTION_TREATMENT_AND_SUPPLY'
  | 'SEWERAGE'
  | 'WASTE_COLLECTION_TREATMENT_AND_DISPOSAL_ACTIVITIES_MATERIALS_RECOVERY'
  | 'REMEDIATION_ACTIVITIES_AND_OTHER_WASTE_MANAGEMENT_SERVICES'
  | 'CONSTRUCTION_OF_BUILDINGS'
  | 'CIVIL_ENGINEERING'
  | 'SPECIALISED_CONSTRUCTION_ACTIVITIES'
  | 'WHOLESALE_AND_RETAIL_TRADE_AND_REPAIR_OF_MOTOR_VEHICLES_AND_MOTORCYCLES'
  | 'WHOLESALE_TRADE_EXCEPT_OF_MOTOR_VEHICLES_AND_MOTORCYCLES'
  | 'RETAIL_TRADE_EXCEPT_OF_MOTOR_VEHICLES_AND_MOTORCYCLES'
  | 'LAND_TRANSPORT_AND_TRANSPORT_VIA_PIPELINES'
  | 'WATER_TRANSPORT'
  | 'AIR_TRANSPORT'
  | 'WAREHOUSING_AND_SUPPORT_ACTIVITIES_FOR_TRANSPORTATION'
  | 'POSTAL_AND_COURIER_ACTIVITIES'
  | 'ACCOMMODATION'
  | 'FOOD_AND_BEVERAGE_SERVICE_ACTIVITIES'
  | 'PUBLISHING_ACTIVITIES'
  | 'MOTION_PICTURE_VIDEO_AND_TELEVISION_PROGRAMME_PRODUCTION_SOUND_RECORDING_AND_MUSIC_PUBLISHING_ACTIVITIES'
  | 'PROGRAMMING_AND_BROADCASTING_ACTIVITIES'
  | 'TELECOMMUNICATIONS'
  | 'COMPUTER_PROGRAMMING_CONSULTANCY_AND_RELATED_ACTIVITIES'
  | 'INFORMATION_SERVICE_ACTIVITIES'
  | 'FINANCIAL_SERVICE_ACTIVITIES_EXCEPT_INSURANCE_AND_PENSION_FUNDING'
  | 'INSURANCE_REINSURANCE_AND_PENSION_FUNDING_EXCEPT_COMPULSORY_SOCIAL_SECURITY'
  | 'ACTIVITIES_AUXILIARY_TO_FINANCIAL_SERVICES_AND_INSURANCE_ACTIVITIES'
  | 'REAL_ESTATE_ACTIVITIES'
  | 'LEGAL_AND_ACCOUNTING_ACTIVITIES'
  | 'ACTIVITIES_OF_HEAD_OFFICES_MANAGEMENT_CONSULTANCY_ACTIVITIES'
  | 'ARCHITECTURAL_AND_ENGINEERING_ACTIVITIES_TECHNICAL_TESTING_AND_ANALYSIS'
  | 'SCIENTIFIC_RESEARCH_AND_DEVELOPMENT'
  | 'ADVERTISING_AND_MARKET_RESEARCH'
  | 'OTHER_PROFESSIONAL_SCIENTIFIC_AND_TECHNICAL_ACTIVITIES'
  | 'VETERINARY_ACTIVITIES'
  | 'RENTAL_AND_LEASING_ACTIVITIES'
  | 'EMPLOYMENT_ACTIVITIES'
  | 'TRAVEL_AGENCY_TOUR_OPERATOR_RESERVATION_SERVICE_AND_RELATED_ACTIVITIES'
  | 'SECURITY_AND_INVESTIGATION_ACTIVITIES'
  | 'SERVICES_TO_BUILDINGS_AND_LANDSCAPE_ACTIVITIES'
  | 'OFFICE_ADMINISTRATIVE_OFFICE_SUPPORT_AND_OTHER_BUSINESS_SUPPORT_ACTIVITIES'
  | 'PUBLIC_ADMINISTRATION_AND_DEFENCE_COMPULSORY_SOCIAL_SECURITY'
  | 'EDUCATION'
  | 'HUMAN_HEALTH_ACTIVITIES'
  | 'RESIDENTIAL_CARE_ACTIVITIES'
  | 'SOCIAL_WORK_ACTIVITIES_WITHOUT_ACCOMMODATION'
  | 'CREATIVE_ARTS_AND_ENTERTAINMENT_ACTIVITIES'
  | 'LIBRARIES_ARCHIVES_MUSEUMS_AND_OTHER_CULTURAL_ACTIVITIES'
  | 'GAMBLING_AND_BETTING_ACTIVITIES'
  | 'SPORTS_ACTIVITIES_AND_AMUSEMENT_AND_RECREATION_ACTIVITIES'
  | 'ACTIVITIES_OF_MEMBERSHIP_ORGANISATIONS'
  | 'REPAIR_OF_COMPUTERS_AND_PERSONAL_AND_HOUSEHOLD_GOODS'
  | 'OTHER_PERSONAL_SERVICE_ACTIVITIES'
  | 'ACTIVITIES_OF_HOUSEHOLDS_AS_EMPLOYERS_OF_DOMESTIC_PERSONNEL'
  | 'UNDIFFERENTIATED_GOODS_AND_SERVICES_PRODUCING_ACTIVITIES_OF_PRIVATE_HOUSEHOLDS_FOR_OWN_USE'
  | 'ACTIVITIES_OF_EXTRATERRITORIAL_ORGANISATIONS_AND_BODIES';

export const subCategories: Record<SubCategory, string> = {
  CROP_AND_ANIMAL_PRODUCTION_HUNTING_AND_RELATED_SERVICE_ACTIVITIES:
    '1 Crop and animal production, hunting and related service activities',
  FORESTRY_AND_LOGGING: '2 Forestry and logging',
  FISHING_AND_AQUACULTURE: '3 Fishing and aquaculture',
  MINING_OF_COAL_AND_LIGNITE: '5 Mining of coal and lignite',
  EXTRACTION_OF_CRUDE_PETROLEUM_AND_NATURAL_GAS: '6 Extraction of crude petroleum and natural gas',
  MINING_OF_METAL_ORES: '7 Mining of metal ores',
  OTHER_MINING_AND_QUARRYING: '8 Other mining and quarrying',
  MINING_SUPPORT_SERVICE_ACTIVITIES: '9 Mining support service activities',
  MANUFACTURE_OF_FOOD_PRODUCTS: '10 Manufacture of food products',
  MANUFACTURE_OF_BEVERAGES: '11 Manufacture of beverages',
  MANUFACTURE_OF_TOBACCO_PRODUCTS: '12 Manufacture of tobacco products',
  MANUFACTURE_OF_TEXTILES: '13 Manufacture of textiles',
  MANUFACTURE_OF_WEARING_APPAREL: '14 Manufacture of wearing apparel',
  MANUFACTURE_OF_LEATHER_AND_RELATED_PRODUCTS: '15 Manufacture of leather and related products',
  MANUFACTURE_OF_WOOD_AND_OF_PRODUCTS_OF_WOOD_AND_CORK_EXCEPT_FURNITURE_MANUFACTURE_OF_ARTICLES_OF_STRAW_AND_PLAITING_MATERIALS:
    '16 Manufacture of wood and of products of wood and cork, except furniture; manufacture of articles of straw and plaiting materials',
  MANUFACTURE_OF_PAPER_AND_PAPER_PRODUCTS: '17 Manufacture of paper and paper products',
  PRINTING_AND_REPRODUCTION_OF_RECORDED_MEDIA: '18 Printing and reproduction of recorded media',
  MANUFACTURE_OF_COKE_AND_REFINED_PETROLEUM_PRODUCTS: '19 Manufacture of coke and refined petroleum products',
  MANUFACTURE_OF_CHEMICALS_AND_CHEMICAL_PRODUCTS: '20 Manufacture of chemicals and chemical products',
  MANUFACTURE_OF_BASIC_PHARMACEUTICAL_PRODUCTS_AND_PHARMACEUTICAL_PREPARATIONS:
    '21 Manufacture of basic pharmaceutical products and pharmaceutical preparations',
  MANUFACTURE_OF_RUBBER_AND_PLASTIC_PRODUCTS: '22 Manufacture of rubber and plastic products',
  MANUFACTURE_OF_OTHER_NON_METALLIC_MINERAL_PRODUCTS: '23 Manufacture of other non-metallic mineral products',
  MANUFACTURE_OF_BASIC_METALS: '24 Manufacture of basic metals',
  MANUFACTURE_OF_FABRICATED_METAL_PRODUCTS_EXCEPT_MACHINERY_AND_EQUIPMENT:
    '25 Manufacture of fabricated metal products, except machinery and equipment',
  MANUFACTURE_OF_COMPUTER_ELECTRONIC_AND_OPTICAL_PRODUCTS:
    '26 Manufacture of computer, electronic and optical products',
  MANUFACTURE_OF_ELECTRICAL_EQUIPMENT: '27 Manufacture of electrical equipment',
  MANUFACTURE_OF_MACHINERY_AND_EQUIPMENT_N_E_C: '28 Manufacture of machinery and equipment n.e.c.',
  MANUFACTURE_OF_MOTOR_VEHICLES_TRAILERS_AND_SEMI_TRAILERS:
    '29 Manufacture of motor vehicles, trailers and semi-trailers',
  MANUFACTURE_OF_OTHER_TRANSPORT_EQUIPMENT: '30 Manufacture of other transport equipment',
  MANUFACTURE_OF_FURNITURE: '31 Manufacture of furniture',
  OTHER_MANUFACTURING: '32 Other manufacturing',
  REPAIR_AND_INSTALLATION_OF_MACHINERY_AND_EQUIPMENT: '33 Repair and installation of machinery and equipment',
  ELECTRICITY_GAS_STEAM_AND_AIR_CONDITIONING_SUPPLY: '35 Electricity, gas, steam and air conditioning supply',
  WATER_COLLECTION_TREATMENT_AND_SUPPLY: '36 Water collection, treatment and supply',
  SEWERAGE: '37 Sewerage',
  WASTE_COLLECTION_TREATMENT_AND_DISPOSAL_ACTIVITIES_MATERIALS_RECOVERY:
    '38 Waste collection, treatment and disposal activities; materials recovery',
  REMEDIATION_ACTIVITIES_AND_OTHER_WASTE_MANAGEMENT_SERVICES:
    '39 Remediation activities and other waste management services',
  CONSTRUCTION_OF_BUILDINGS: '41 Construction of buildings',
  CIVIL_ENGINEERING: '42 Civil engineering',
  SPECIALISED_CONSTRUCTION_ACTIVITIES: '43 Specialised construction activities',
  WHOLESALE_AND_RETAIL_TRADE_AND_REPAIR_OF_MOTOR_VEHICLES_AND_MOTORCYCLES:
    '45 Wholesale and retail trade and repair of motor vehicles and motorcycles',
  WHOLESALE_TRADE_EXCEPT_OF_MOTOR_VEHICLES_AND_MOTORCYCLES:
    '46 Wholesale trade, except of motor vehicles and motorcycles',
  RETAIL_TRADE_EXCEPT_OF_MOTOR_VEHICLES_AND_MOTORCYCLES: '47 Retail trade, except of motor vehicles and motorcycles',
  LAND_TRANSPORT_AND_TRANSPORT_VIA_PIPELINES: '49 Land transport and transport via pipelines',
  WATER_TRANSPORT: '50 Water transport',
  AIR_TRANSPORT: '51 Air transport',
  WAREHOUSING_AND_SUPPORT_ACTIVITIES_FOR_TRANSPORTATION: '52 Warehousing and support activities for transportation',
  POSTAL_AND_COURIER_ACTIVITIES: '53 Postal and courier activities',
  ACCOMMODATION: '55 Accommodation',
  FOOD_AND_BEVERAGE_SERVICE_ACTIVITIES: '56 Food and beverage service activities',
  PUBLISHING_ACTIVITIES: '58 Publishing activities',
  MOTION_PICTURE_VIDEO_AND_TELEVISION_PROGRAMME_PRODUCTION_SOUND_RECORDING_AND_MUSIC_PUBLISHING_ACTIVITIES:
    '59 Motion picture, video and television programme production, sound recording and music publishing activities',
  PROGRAMMING_AND_BROADCASTING_ACTIVITIES: '60 Programming and broadcasting activities',
  TELECOMMUNICATIONS: '61 Telecommunications',
  COMPUTER_PROGRAMMING_CONSULTANCY_AND_RELATED_ACTIVITIES:
    '62 Computer programming, consultancy and related activities',
  INFORMATION_SERVICE_ACTIVITIES: '63 Information service activities',
  FINANCIAL_SERVICE_ACTIVITIES_EXCEPT_INSURANCE_AND_PENSION_FUNDING:
    '64 Financial service activities, except insurance and pension funding',
  INSURANCE_REINSURANCE_AND_PENSION_FUNDING_EXCEPT_COMPULSORY_SOCIAL_SECURITY:
    '65 Insurance, reinsurance and pension funding, except compulsory social security',
  ACTIVITIES_AUXILIARY_TO_FINANCIAL_SERVICES_AND_INSURANCE_ACTIVITIES:
    '66 Activities auxiliary to financial services and insurance activities',
  REAL_ESTATE_ACTIVITIES: '68 Real estate activities',
  LEGAL_AND_ACCOUNTING_ACTIVITIES: '69 Legal and accounting activities',
  ACTIVITIES_OF_HEAD_OFFICES_MANAGEMENT_CONSULTANCY_ACTIVITIES:
    '70 Activities of head offices; management consultancy activities',
  ARCHITECTURAL_AND_ENGINEERING_ACTIVITIES_TECHNICAL_TESTING_AND_ANALYSIS:
    '71 Architectural and engineering activities; technical testing and analysis',
  SCIENTIFIC_RESEARCH_AND_DEVELOPMENT: '72 Scientific research and development',
  ADVERTISING_AND_MARKET_RESEARCH: '73 Advertising and market research',
  OTHER_PROFESSIONAL_SCIENTIFIC_AND_TECHNICAL_ACTIVITIES: '74 Other professional, scientific and technical activities',
  VETERINARY_ACTIVITIES: '75 Veterinary activities',
  RENTAL_AND_LEASING_ACTIVITIES: '77 Rental and leasing activities',
  EMPLOYMENT_ACTIVITIES: '78 Employment activities',
  TRAVEL_AGENCY_TOUR_OPERATOR_RESERVATION_SERVICE_AND_RELATED_ACTIVITIES:
    '79 Travel agency, tour operator reservation service and related activities',
  SECURITY_AND_INVESTIGATION_ACTIVITIES: '80 Security and investigation activities',
  SERVICES_TO_BUILDINGS_AND_LANDSCAPE_ACTIVITIES: '81 Services to buildings and landscape activities',
  OFFICE_ADMINISTRATIVE_OFFICE_SUPPORT_AND_OTHER_BUSINESS_SUPPORT_ACTIVITIES:
    '82 Office administrative, office support and other business support activities',
  PUBLIC_ADMINISTRATION_AND_DEFENCE_COMPULSORY_SOCIAL_SECURITY:
    '84 Public administration and defence; compulsory social security',
  EDUCATION: '85 Education',
  HUMAN_HEALTH_ACTIVITIES: '86 Human health activities',
  RESIDENTIAL_CARE_ACTIVITIES: '87 Residential care activities',
  SOCIAL_WORK_ACTIVITIES_WITHOUT_ACCOMMODATION: '88 Social work activities without accommodation',
  CREATIVE_ARTS_AND_ENTERTAINMENT_ACTIVITIES: '90 Creative, arts and entertainment activities',
  LIBRARIES_ARCHIVES_MUSEUMS_AND_OTHER_CULTURAL_ACTIVITIES:
    '91 Libraries, archives, museums and other cultural activities',
  GAMBLING_AND_BETTING_ACTIVITIES: '92 Gambling and betting activities',
  SPORTS_ACTIVITIES_AND_AMUSEMENT_AND_RECREATION_ACTIVITIES:
    '93 Sports activities and amusement and recreation activities',
  ACTIVITIES_OF_MEMBERSHIP_ORGANISATIONS: '94 Activities of membership organisations',
  REPAIR_OF_COMPUTERS_AND_PERSONAL_AND_HOUSEHOLD_GOODS: '95 Repair of computers and personal and household goods',
  OTHER_PERSONAL_SERVICE_ACTIVITIES: '96 Other personal service activities',
  ACTIVITIES_OF_HOUSEHOLDS_AS_EMPLOYERS_OF_DOMESTIC_PERSONNEL:
    '97 Activities of households as employers of domestic personnel',
  UNDIFFERENTIATED_GOODS_AND_SERVICES_PRODUCING_ACTIVITIES_OF_PRIVATE_HOUSEHOLDS_FOR_OWN_USE:
    '98 Undifferentiated goods- and services-producing activities of private households for own use',
  ACTIVITIES_OF_EXTRATERRITORIAL_ORGANISATIONS_AND_BODIES: '99 Activities of extraterritorial organisations and bodies',
};

export const MainActivitySubCategoryMap: Record<MainActivity, SubCategory[]> = {
  AGRICULTURE_FORESTRY_AND_FISHING: [
    'CROP_AND_ANIMAL_PRODUCTION_HUNTING_AND_RELATED_SERVICE_ACTIVITIES',
    'FORESTRY_AND_LOGGING',
    'FISHING_AND_AQUACULTURE',
  ],
  MINING_AND_QUARRYING: [
    'MINING_OF_COAL_AND_LIGNITE',
    'EXTRACTION_OF_CRUDE_PETROLEUM_AND_NATURAL_GAS',
    'MINING_OF_METAL_ORES',
    'OTHER_MINING_AND_QUARRYING',
    'MINING_SUPPORT_SERVICE_ACTIVITIES',
  ],
  MANUFACTURING: [
    'MANUFACTURE_OF_FOOD_PRODUCTS',
    'MANUFACTURE_OF_BEVERAGES',
    'MANUFACTURE_OF_TOBACCO_PRODUCTS',
    'MANUFACTURE_OF_TEXTILES',
    'MANUFACTURE_OF_WEARING_APPAREL',
    'MANUFACTURE_OF_LEATHER_AND_RELATED_PRODUCTS',
    'MANUFACTURE_OF_WOOD_AND_OF_PRODUCTS_OF_WOOD_AND_CORK_EXCEPT_FURNITURE_MANUFACTURE_OF_ARTICLES_OF_STRAW_AND_PLAITING_MATERIALS',
    'MANUFACTURE_OF_PAPER_AND_PAPER_PRODUCTS',
    'PRINTING_AND_REPRODUCTION_OF_RECORDED_MEDIA',
    'MANUFACTURE_OF_COKE_AND_REFINED_PETROLEUM_PRODUCTS',
    'MANUFACTURE_OF_CHEMICALS_AND_CHEMICAL_PRODUCTS',
    'MANUFACTURE_OF_BASIC_PHARMACEUTICAL_PRODUCTS_AND_PHARMACEUTICAL_PREPARATIONS',
    'MANUFACTURE_OF_RUBBER_AND_PLASTIC_PRODUCTS',
    'MANUFACTURE_OF_OTHER_NON_METALLIC_MINERAL_PRODUCTS',
    'MANUFACTURE_OF_BASIC_METALS',
    'MANUFACTURE_OF_FABRICATED_METAL_PRODUCTS_EXCEPT_MACHINERY_AND_EQUIPMENT',
    'MANUFACTURE_OF_COMPUTER_ELECTRONIC_AND_OPTICAL_PRODUCTS',
    'MANUFACTURE_OF_ELECTRICAL_EQUIPMENT',
    'MANUFACTURE_OF_MACHINERY_AND_EQUIPMENT_N_E_C',
    'MANUFACTURE_OF_MOTOR_VEHICLES_TRAILERS_AND_SEMI_TRAILERS',
    'MANUFACTURE_OF_OTHER_TRANSPORT_EQUIPMENT',
    'MANUFACTURE_OF_FURNITURE',
    'OTHER_MANUFACTURING',
    'REPAIR_AND_INSTALLATION_OF_MACHINERY_AND_EQUIPMENT',
  ],
  ELECTRICITY_GAS_STEAM_AND_AIR_CONDITIONING_SUPPLY: ['ELECTRICITY_GAS_STEAM_AND_AIR_CONDITIONING_SUPPLY'],
  WATER_SUPPLY_SEWERAGE_WASTE_MANAGEMENT_AND_REMEDIATION_ACTIVITIES: [
    'WATER_COLLECTION_TREATMENT_AND_SUPPLY',
    'SEWERAGE',
    'WASTE_COLLECTION_TREATMENT_AND_DISPOSAL_ACTIVITIES_MATERIALS_RECOVERY',
    'REMEDIATION_ACTIVITIES_AND_OTHER_WASTE_MANAGEMENT_SERVICES',
  ],
  CONSTRUCTION: ['CONSTRUCTION_OF_BUILDINGS', 'CIVIL_ENGINEERING', 'SPECIALISED_CONSTRUCTION_ACTIVITIES'],
  WHOLESALE_AND_RETAIL_TRADE_REPAIR_OF_MOTOR_VEHICLES_AND_MOTORCYCLES: [
    'WHOLESALE_AND_RETAIL_TRADE_AND_REPAIR_OF_MOTOR_VEHICLES_AND_MOTORCYCLES',
    'WHOLESALE_TRADE_EXCEPT_OF_MOTOR_VEHICLES_AND_MOTORCYCLES',
    'RETAIL_TRADE_EXCEPT_OF_MOTOR_VEHICLES_AND_MOTORCYCLES',
  ],
  TRANSPORTATION_AND_STORAGE: [
    'LAND_TRANSPORT_AND_TRANSPORT_VIA_PIPELINES',
    'WATER_TRANSPORT',
    'AIR_TRANSPORT',
    'WAREHOUSING_AND_SUPPORT_ACTIVITIES_FOR_TRANSPORTATION',
    'POSTAL_AND_COURIER_ACTIVITIES',
  ],
  ACCOMMODATION_AND_FOOD_SERVICE_ACTIVITIES: ['ACCOMMODATION', 'FOOD_AND_BEVERAGE_SERVICE_ACTIVITIES'],
  INFORMATION_AND_COMMUNICATION: [
    'PUBLISHING_ACTIVITIES',
    'MOTION_PICTURE_VIDEO_AND_TELEVISION_PROGRAMME_PRODUCTION_SOUND_RECORDING_AND_MUSIC_PUBLISHING_ACTIVITIES',
    'PROGRAMMING_AND_BROADCASTING_ACTIVITIES',
    'TELECOMMUNICATIONS',
    'COMPUTER_PROGRAMMING_CONSULTANCY_AND_RELATED_ACTIVITIES',
    'INFORMATION_SERVICE_ACTIVITIES',
  ],
  FINANCIAL_AND_INSURANCE_ACTIVITIES: [
    'FINANCIAL_SERVICE_ACTIVITIES_EXCEPT_INSURANCE_AND_PENSION_FUNDING',
    'INSURANCE_REINSURANCE_AND_PENSION_FUNDING_EXCEPT_COMPULSORY_SOCIAL_SECURITY',
    'ACTIVITIES_AUXILIARY_TO_FINANCIAL_SERVICES_AND_INSURANCE_ACTIVITIES',
  ],
  REAL_ESTATE_ACTIVITIES: ['REAL_ESTATE_ACTIVITIES'],
  PROFESSIONAL_SCIENTIFIC_AND_TECHNICAL_ACTIVITIES: [
    'LEGAL_AND_ACCOUNTING_ACTIVITIES',
    'ACTIVITIES_OF_HEAD_OFFICES_MANAGEMENT_CONSULTANCY_ACTIVITIES',
    'ARCHITECTURAL_AND_ENGINEERING_ACTIVITIES_TECHNICAL_TESTING_AND_ANALYSIS',
    'SCIENTIFIC_RESEARCH_AND_DEVELOPMENT',
    'ADVERTISING_AND_MARKET_RESEARCH',
    'OTHER_PROFESSIONAL_SCIENTIFIC_AND_TECHNICAL_ACTIVITIES',
    'VETERINARY_ACTIVITIES',
  ],
  ADMINISTRATIVE_AND_SUPPORT_SERVICE_ACTIVITIES: [
    'RENTAL_AND_LEASING_ACTIVITIES',
    'EMPLOYMENT_ACTIVITIES',
    'TRAVEL_AGENCY_TOUR_OPERATOR_RESERVATION_SERVICE_AND_RELATED_ACTIVITIES',
    'SECURITY_AND_INVESTIGATION_ACTIVITIES',
    'SERVICES_TO_BUILDINGS_AND_LANDSCAPE_ACTIVITIES',
    'OFFICE_ADMINISTRATIVE_OFFICE_SUPPORT_AND_OTHER_BUSINESS_SUPPORT_ACTIVITIES',
  ],
  PUBLIC_ADMINISTRATION_AND_DEFENCE_COMPULSORY_SOCIAL_SECURITY: [
    'PUBLIC_ADMINISTRATION_AND_DEFENCE_COMPULSORY_SOCIAL_SECURITY',
  ],
  EDUCATION: ['EDUCATION'],
  HUMAN_HEALTH_AND_SOCIAL_WORK_ACTIVITIES: [
    'HUMAN_HEALTH_ACTIVITIES',
    'RESIDENTIAL_CARE_ACTIVITIES',
    'SOCIAL_WORK_ACTIVITIES_WITHOUT_ACCOMMODATION',
  ],
  ARTS_ENTERTAINMENT_AND_RECREATION: [
    'CREATIVE_ARTS_AND_ENTERTAINMENT_ACTIVITIES',
    'LIBRARIES_ARCHIVES_MUSEUMS_AND_OTHER_CULTURAL_ACTIVITIES',
    'GAMBLING_AND_BETTING_ACTIVITIES',
    'SPORTS_ACTIVITIES_AND_AMUSEMENT_AND_RECREATION_ACTIVITIES',
  ],
  OTHER_SERVICE_ACTIVITIES: [
    'ACTIVITIES_OF_MEMBERSHIP_ORGANISATIONS',
    'REPAIR_OF_COMPUTERS_AND_PERSONAL_AND_HOUSEHOLD_GOODS',
    'OTHER_PERSONAL_SERVICE_ACTIVITIES',
  ],
  ACTIVITIES_OF_HOUSEHOLDS_AS_EMPLOYERS_UNDIFFERENTIATED_GOODS_AND_SERVICES_PRODUCING_ACTIVITIES_OF_HOUSEHOLDS_FOR_OWN_USE:
    [
      'ACTIVITIES_OF_HOUSEHOLDS_AS_EMPLOYERS_OF_DOMESTIC_PERSONNEL',
      'UNDIFFERENTIATED_GOODS_AND_SERVICES_PRODUCING_ACTIVITIES_OF_PRIVATE_HOUSEHOLDS_FOR_OWN_USE',
    ],
  ACTIVITIES_OF_EXTRATERRITORIAL_ORGANISATIONS_AND_BODIES: ['ACTIVITIES_OF_EXTRATERRITORIAL_ORGANISATIONS_AND_BODIES'],
};

export type InstallationActivity =
  | 'GROWING_OF_NON_PERENNIAL_CROPS'
  | 'GROWING_OF_PERENNIAL_CROPS'
  | 'PLANT_PROPAGATION'
  | 'ANIMAL_PRODUCTION'
  | 'MIXED_FARMING'
  | 'SUPPORT_ACTIVITIES_TO_AGRICULTURE_AND_POST_HARVEST_CROP_ACTIVITIES'
  | 'HUNTING_TRAPPING_AND_RELATED_SERVICE_ACTIVITIES'
  | 'SILVICULTURE_AND_OTHER_FORESTRY_ACTIVITIES'
  | 'LOGGING'
  | 'GATHERING_OF_WILD_GROWING_NON_WOOD_PRODUCTS'
  | 'SUPPORT_SERVICES_TO_FORESTRY'
  | 'FISHING'
  | 'AQUACULTURE'
  | 'MINING_OF_HARD_COAL'
  | 'MINING_OF_LIGNITE'
  | 'EXTRACTION_OF_CRUDE_PETROLEUM'
  | 'EXTRACTION_OF_NATURAL_GAS'
  | 'MINING_OF_IRON_ORES'
  | 'MINING_OF_NON_FERROUS_METAL_ORES'
  | 'QUARRYING_OF_STONE_SAND_AND_CLAY'
  | 'MINING_AND_QUARRYING_NEC'
  | 'SUPPORT_ACTIVITIES_FOR_PETROLEUM_AND_NATURAL_GAS_EXTRACTION'
  | 'SUPPORT_ACTIVITIES_FOR_OTHER_MINING_AND_QUARRYING'
  | 'PROCESSING_AND_PRESERVING_OF_MEAT_AND_PRODUCTION_OF_MEAT_PRODUCTS'
  | 'PROCESSING_AND_PRESERVING_OF_FISH_CRUSTACEANS_AND_MOLLUSCS'
  | 'PROCESSING_AND_PRESERVING_OF_FRUIT_AND_VEGETABLES'
  | 'MANUFACTURE_OF_VEGETABLE_AND_ANIMAL_OILS_AND_FATS'
  | 'MANUFACTURE_OF_DAIRY_PRODUCTS'
  | 'MANUFACTURE_OF_GRAIN_MILL_PRODUCTS_STARCHES_AND_STARCH_PRODUCTS'
  | 'MANUFACTURE_OF_BAKERY_AND_FARINACEOUS_PRODUCTS'
  | 'MANUFACTURE_OF_OTHER_FOOD_PRODUCTS'
  | 'MANUFACTURE_OF_PREPARED_ANIMAL_FEEDS'
  | 'MANUFACTURE_OF_BEVERAGES'
  | 'MANUFACTURE_OF_TOBACCO_PRODUCTS'
  | 'PREPARATION_AND_SPINNING_OF_TEXTILE_FIBRES'
  | 'WEAVING_OF_TEXTILES'
  | 'FINISHING_OF_TEXTILES'
  | 'MANUFACTURE_OF_OTHER_TEXTILES'
  | 'MANUFACTURE_OF_WEARING_APPAREL_EXCEPT_FUR_APPAREL'
  | 'MANUFACTURE_OF_ARTICLES_OF_FUR'
  | 'MANUFACTURE_OF_KNITTED_AND_CROCHETED_APPAREL'
  | 'TANNING_AND_DRESSING_OF_LEATHER_MANUFACTURE_OF_LUGGAGE_HANDBAGS_SADDLERY_AND_HARNESS_DRESSING_AND_DYEING_OF_FUR'
  | 'MANUFACTURE_OF_FOOTWEAR'
  | 'SAWMILLING_AND_PLANING_OF_WOOD'
  | 'MANUFACTURE_OF_PRODUCTS_OF_WOOD_CORK_STRAW_AND_PLAITING_MATERIALS'
  | 'MANUFACTURE_OF_PULP_PAPER_AND_PAPERBOARD'
  | 'MANUFACTURE_OF_ARTICLES_OF_PAPER_AND_PAPERBOARD'
  | 'PRINTING_AND_SERVICE_ACTIVITIES_RELATED_TO_PRINTING'
  | 'REPRODUCTION_OF_RECORDED_MEDIA'
  | 'MANUFACTURE_OF_COKE_OVEN_PRODUCTS'
  | 'MANUFACTURE_OF_REFINED_PETROLEUM_PRODUCTS'
  | 'MANUFACTURE_OF_BASIC_CHEMICALS_FERTILISERS_AND_NITROGEN_COMPOUNDS_PLASTICS_AND_SYNTHETIC_RUBBER_IN_PRIMARY_FORMS'
  | 'MANUFACTURE_OF_PESTICIDES_AND_OTHER_AGROCHEMICAL_PRODUCTS'
  | 'MANUFACTURE_OF_PAINTS_VARNISHES_AND_SIMILAR_COATINGS_PRINTING_INK_AND_MASTICS'
  | 'MANUFACTURE_OF_SOAP_AND_DETERGENTS_CLEANING_AND_POLISHING_PREPARATIONS_PERFUMES_AND_TOILET_PREPARATIONS'
  | 'MANUFACTURE_OF_OTHER_CHEMICAL_PRODUCTS'
  | 'MANUFACTURE_OF_MAN_MADE_FIBRES'
  | 'MANUFACTURE_OF_BASIC_PHARMACEUTICAL_PRODUCTS'
  | 'MANUFACTURE_OF_PHARMACEUTICAL_PREPARATIONS'
  | 'MANUFACTURE_OF_RUBBER_PRODUCTS'
  | 'MANUFACTURE_OF_PLASTICS_PRODUCTS'
  | 'MANUFACTURE_OF_GLASS_AND_GLASS_PRODUCTS'
  | 'MANUFACTURE_OF_REFRACTORY_PRODUCTS'
  | 'MANUFACTURE_OF_CLAY_BUILDING_MATERIALS'
  | 'MANUFACTURE_OF_OTHER_PORCELAIN_AND_CERAMIC_PRODUCTS'
  | 'MANUFACTURE_OF_CEMENT_LIME_AND_PLASTER'
  | 'MANUFACTURE_OF_ARTICLES_OF_CONCRETE_CEMENT_AND_PLASTER'
  | 'CUTTING_SHAPING_AND_FINISHING_OF_STONE'
  | 'MANUFACTURE_OF_ABRASIVE_PRODUCTS_AND_NON_METALLIC_MINERAL_PRODUCTS_NEC'
  | 'MANUFACTURE_OF_BASIC_IRON_AND_STEEL_AND_OF_FERRO_ALLOYS'
  | 'MANUFACTURE_OF_TUBES_PIPES_HOLLOW_PROFILES_AND_RELATED_FITTINGS_OF_STEEL'
  | 'MANUFACTURE_OF_OTHER_PRODUCTS_OF_FIRST_PROCESSING_OF_STEEL'
  | 'MANUFACTURE_OF_BASIC_PRECIOUS_AND_OTHER_NON_FERROUS_METALS'
  | 'CASTING_OF_METALS'
  | 'MANUFACTURE_OF_STRUCTURAL_METAL_PRODUCTS'
  | 'MANUFACTURE_OF_TANKS_RESERVOIRS_AND_CONTAINERS_OF_METAL'
  | 'MANUFACTURE_OF_STEAM_GENERATORS_EXCEPT_CENTRAL_HEATING_HOT_WATER_BOILERS'
  | 'MANUFACTURE_OF_WEAPONS_AND_AMMUNITION'
  | 'FORGING_PRESSING_STAMPING_AND_ROLL_FORMING_OF_METAL_POWDER_METALLURGY'
  | 'TREATMENT_AND_COATING_OF_METALS_MACHINING'
  | 'MANUFACTURE_OF_CUTLERY_TOOLS_AND_GENERAL_HARDWARE'
  | 'MANUFACTURE_OF_OTHER_FABRICATED_METAL_PRODUCTS'
  | 'MANUFACTURE_OF_ELECTRONIC_COMPONENTS_AND_BOARDS'
  | 'MANUFACTURE_OF_COMPUTERS_AND_PERIPHERAL_EQUIPMENT'
  | 'MANUFACTURE_OF_COMMUNICATION_EQUIPMENT'
  | 'MANUFACTURE_OF_CONSUMER_ELECTRONICS'
  | 'MANUFACTURE_OF_INSTRUMENTS_AND_APPLIANCES_FOR_MEASURING_TESTING_AND_NAVIGATION_WATCHES_AND_CLOCKS'
  | 'MANUFACTURE_OF_IRRADIATION_ELECTROMEDICAL_AND_ELECTROTHERAPEUTIC_EQUIPMENT'
  | 'MANUFACTURE_OF_OPTICAL_INSTRUMENTS_AND_PHOTOGRAPHIC_EQUIPMENT'
  | 'MANUFACTURE_OF_MAGNETIC_AND_OPTICAL_MEDIA'
  | 'MANUFACTURE_OF_ELECTRIC_MOTORS_GENERATORS_TRANSFORMERS_AND_ELECTRICITY_DISTRIBUTION_AND_CONTROL_APPARATUS'
  | 'MANUFACTURE_OF_BATTERIES_AND_ACCUMULATORS'
  | 'MANUFACTURE_OF_WIRING_AND_WIRING_DEVICES'
  | 'MANUFACTURE_OF_ELECTRIC_LIGHTING_EQUIPMENT'
  | 'MANUFACTURE_OF_DOMESTIC_APPLIANCES'
  | 'MANUFACTURE_OF_OTHER_ELECTRICAL_EQUIPMENT'
  | 'MANUFACTURE_OF_GENERAL_PURPOSE_MACHINERY'
  | 'MANUFACTURE_OF_OTHER_GENERAL_PURPOSE_MACHINERY'
  | 'MANUFACTURE_OF_AGRICULTURAL_AND_FORESTRY_MACHINERY'
  | 'MANUFACTURE_OF_METAL_FORMING_MACHINERY_AND_MACHINE_TOOLS'
  | 'MANUFACTURE_OF_OTHER_SPECIAL_PURPOSE_MACHINERY'
  | 'MANUFACTURE_OF_MOTOR_VEHICLES'
  | 'MANUFACTURE_OF_BODIES (COACHWORK) FOR_MOTOR_VEHICLES_MANUFACTURE_OF_TRAILERS_AND_SEMI_TRAILERS'
  | 'MANUFACTURE_OF_PARTS_AND_ACCESSORIES_FOR_MOTOR_VEHICLES'
  | 'BUILDING_OF_SHIPS_AND_BOATS'
  | 'MANUFACTURE_OF_RAILWAY_LOCOMOTIVES_AND_ROLLING_STOCK'
  | 'MANUFACTURE_OF_AIR_AND_SPACECRAFT_AND_RELATED_MACHINERY'
  | 'MANUFACTURE_OF_MILITARY_FIGHTING_VEHICLES'
  | 'MANUFACTURE_OF_TRANSPORT_EQUIPMENT_NEC'
  | 'MANUFACTURE_OF_FURNITURE'
  | 'MANUFACTURE_OF_JEWELLERY_BIJOUTERIE_AND_RELATED_ARTICLES'
  | 'MANUFACTURE_OF_MUSICAL_INSTRUMENTS'
  | 'MANUFACTURE_OF_SPORTS_GOODS'
  | 'MANUFACTURE_OF_GAMES_AND_TOYS'
  | 'MANUFACTURE_OF_MEDICAL_AND_DENTAL_INSTRUMENTS_AND_SUPPLIES'
  | 'MANUFACTURING_NEC'
  | 'REPAIR_OF_FABRICATED_METAL_PRODUCTS_MACHINERY_AND_EQUIPMENT'
  | 'INSTALLATION_OF_INDUSTRIAL_MACHINERY_AND_EQUIPMENT'
  | 'ELECTRIC_POWER_GENERATION_TRANSMISSION_AND_DISTRIBUTION'
  | 'MANUFACTURE_OF_GAS_DISTRIBUTION_OF_GASEOUS_FUELS_THROUGH_MAINS'
  | 'STEAM_AND_AIR_CONDITIONING_SUPPLY'
  | 'WATER_COLLECTION_TREATMENT_AND_SUPPLY'
  | 'SEWERAGE'
  | 'WASTE_COLLECTION'
  | 'WASTE_TREATMENT_AND_DISPOSAL'
  | 'MATERIALS_RECOVERY'
  | 'REMEDIATION_ACTIVITIES_AND_OTHER_WASTE_MANAGEMENT_SERVICES'
  | 'DEVELOPMENT_OF_BUILDING_PROJECTS'
  | 'CONSTRUCTION_OF_RESIDENTIAL_AND_NON_RESIDENTIAL_BUILDINGS'
  | 'CONSTRUCTION_OF_ROADS_AND_RAILWAYS'
  | 'CONSTRUCTION_OF_UTILITY_PROJECTS'
  | 'CONSTRUCTION_OF_OTHER_CIVIL_ENGINEERING_PROJECTS'
  | 'DEMOLITION_AND_SITE_PREPARATION'
  | 'ELECTRICAL_PLUMBING_AND_OTHER_CONSTRUCTION_INSTALLATION_ACTIVITIES'
  | 'BUILDING_COMPLETION_AND_FINISHING'
  | 'OTHER_SPECIALISED_CONSTRUCTION_ACTIVITIES'
  | 'SALE_OF_MOTOR_VEHICLES'
  | 'MAINTENANCE_AND_REPAIR_OF_MOTOR_VEHICLES'
  | 'SALE_OF_MOTOR_VEHICLE_PARTS_AND_ACCESSORIES'
  | 'SALE_MAINTENANCE_AND_REPAIR_OF_MOTORCYCLES_AND_RELATED_PARTS_AND_ACCESSORIES'
  | 'WHOLESALE_ON_AFEE_OR_CONTRACT_BASIS'
  | 'WHOLESALE_OF_AGRICULTURAL_RAW_MATERIALS_AND_LIVE_ANIMALS'
  | 'WHOLESALE_OF_FOOD_BEVERAGES_AND_TOBACCO'
  | 'WHOLESALE_OF_HOUSEHOLD_GOODS'
  | 'WHOLESALE_OF_INFORMATION_AND_COMMUNICATION_EQUIPMENT'
  | 'WHOLESALE_OF_OTHER_MACHINERY_EQUIPMENT_AND_SUPPLIES'
  | 'OTHER_SPECIALISED_WHOLESALE'
  | 'NON_SPECIALISED_WHOLESALE_TRADE'
  | 'RETAIL_SALE_IN_NON_SPECIALISED_STORES'
  | 'RETAIL_SALE_OF_FOOD_BEVERAGES_AND_TOBACCO_IN_SPECIALISED_STORES'
  | 'RETAIL_SALE_OF_AUTOMOTIVE_FUEL_IN_SPECIALISED_STORES'
  | 'RETAIL_SALE_OF_INFORMATION_AND_COMMUNICATION_EQUIPMENT_IN_SPECIALISED_STORES'
  | 'RETAIL_SALE_OF_OTHER_HOUSEHOLD_EQUIPMENT_IN_SPECIALISED_STORES'
  | 'RETAIL_SALE_OF_CULTURAL_AND_RECREATION_GOODS_IN_SPECIALISED_STORES'
  | 'RETAIL_SALE_OF_OTHER_GOODS_IN_SPECIALISED_STORES'
  | 'RETAIL_SALE_VIA_STALLS_AND_MARKETS'
  | 'RETAIL_TRADE_NOT_IN_STORES_STALLS_OR_MARKETS'
  | 'PASSENGER_RAIL_TRANSPORT_INTERURBAN'
  | 'FREIGHT_RAIL_TRANSPORT'
  | 'OTHER_PASSENGER_LAND_TRANSPORT'
  | 'FREIGHT_TRANSPORT_BY_ROAD_AND_REMOVAL_SERVICES'
  | 'TRANSPORT_VIA_PIPELINE'
  | 'SEA_AND_COASTAL_PASSENGER_WATER_TRANSPORT'
  | 'SEA_AND_COASTAL_FREIGHT_WATER_TRANSPORT'
  | 'INLAND_PASSENGER_WATER_TRANSPORT'
  | 'INLAND_FREIGHT_WATER_TRANSPORT'
  | 'PASSENGER_AIR_TRANSPORT'
  | 'FREIGHT_AIR_TRANSPORT_AND_SPACE_TRANSPORT'
  | 'WAREHOUSING_AND_STORAGE'
  | 'SUPPORT_ACTIVITIES_FOR_TRANSPORTATION'
  | 'POSTAL_ACTIVITIES_UNDER_UNIVERSAL_SERVICE_OBLIGATION'
  | 'OTHER_POSTAL_AND_COURIER_ACTIVITIES'
  | 'HOTELS_AND_SIMILAR_ACCOMMODATION'
  | 'HOLIDAY_AND_OTHER_SHORT_STAY_ACCOMMODATION'
  | 'CAMPING_GROUNDS_RECREATIONAL_VEHICLE_PARKS_AND_TRAILER_PARKS'
  | 'OTHER_ACCOMMODATION'
  | 'RESTAURANTS_AND_MOBILE_FOOD_SERVICE_ACTIVITIES'
  | 'EVENT_CATERING_AND_OTHER_FOOD_SERVICE_ACTIVITIES'
  | 'BEVERAGE_SERVING_ACTIVITIES'
  | 'PUBLISHING_OF_BOOKS_PERIODICALS_AND_OTHER_PUBLISHING_ACTIVITIES'
  | 'SOFTWARE_PUBLISHING'
  | 'MOTION_PICTURE_VIDEO_AND_TELEVISION_PROGRAMME_ACTIVITIES'
  | 'SOUND_RECORDING_AND_MUSIC_PUBLISHING_ACTIVITIES'
  | 'RADIO_BROADCASTING'
  | 'TELEVISION_PROGRAMMING_AND_BROADCASTING_ACTIVITIES'
  | 'WIRED_TELECOMMUNICATIONS_ACTIVITIES'
  | 'WIRELESS_TELECOMMUNICATIONS_ACTIVITIES'
  | 'SATELLITE_TELECOMMUNICATIONS_ACTIVITIES'
  | 'OTHER_TELECOMMUNICATIONS_ACTIVITIES'
  | 'COMPUTER_PROGRAMMING_CONSULTANCY_AND_RELATED_ACTIVITIES'
  | 'DATA_PROCESSING_HOSTING_AND_RELATED_ACTIVITIES_WEB_PORTALS'
  | 'OTHER_INFORMATION_SERVICE_ACTIVITIES'
  | 'MONETARY_INTERMEDIATION'
  | 'ACTIVITIES_OF_HOLDING_COMPANIES'
  | 'TRUSTS_FUNDS_AND_SIMILAR_FINANCIAL_ENTITIES'
  | 'OTHER_FINANCIAL_SERVICE_ACTIVITIES_EXCEPT_INSURANCE_AND_PENSION_FUNDING'
  | 'INSURANCE'
  | 'REINSURANCE'
  | 'PENSION_FUNDING'
  | 'ACTIVITIES_AUXILIARY_TO_FINANCIAL_SERVICES_EXCEPT_INSURANCE_AND_PENSION_FUNDING'
  | 'ACTIVITIES_AUXILIARY_TO_INSURANCE_AND_PENSION_FUNDING'
  | 'FUND_MANAGEMENT_ACTIVITIES'
  | 'BUYING_AND_SELLING_OF_OWN_REAL_ESTATE'
  | 'RENTING_AND_OPERATING_OF_OWN_OR_LEASED_REAL_ESTATE'
  | 'REAL_ESTATE_ACTIVITIES_ON_AFEE_OR_CONTRACT_BASIS'
  | 'LEGAL_ACTIVITIES'
  | 'ACCOUNTING_BOOKKEEPING_AND_AUDITING_ACTIVITIES_TAX_CONSULTANCY'
  | 'ACTIVITIES_OF_HEAD_OFFICES'
  | 'MANAGEMENT_CONSULTANCY_ACTIVITIES'
  | 'ARCHITECTURAL_AND_ENGINEERING_ACTIVITIES_AND_RELATED_TECHNICAL_CONSULTANCY'
  | 'TECHNICAL_TESTING_AND_ANALYSIS'
  | 'RESEARCH_AND_EXPERIMENTAL_DEVELOPMENT_ON_NATURAL_SCIENCES_AND_ENGINEERING'
  | 'RESEARCH_AND_EXPERIMENTAL_DEVELOPMENT_ON_SOCIAL_SCIENCES_AND_HUMANITIES'
  | 'ADVERTISING'
  | 'MARKET_RESEARCH_AND_PUBLIC_OPINION_POLLING'
  | 'SPECIALISED_DESIGN_ACTIVITIES'
  | 'PHOTOGRAPHIC_ACTIVITIES'
  | 'TRANSLATION_AND_INTERPRETATION_ACTIVITIES'
  | 'OTHER_PROFESSIONAL_SCIENTIFIC_AND_TECHNICAL_ACTIVITIES_NEC'
  | 'VETERINARY_ACTIVITIES'
  | 'RENTING_AND_LEASING_OF_MOTOR_VEHICLES'
  | 'RENTING_AND_LEASING_OF_PERSONAL_AND_HOUSEHOLD_GOODS'
  | 'RENTING_AND_LEASING_OF_OTHER_MACHINERY_EQUIPMENT_AND_TANGIBLE_GOODS'
  | 'LEASING_OF_INTELLECTUAL_PROPERTY_AND_SIMILAR_PRODUCTS_EXCEPT_COPYRIGHTED_WORKS'
  | 'ACTIVITIES_OF_EMPLOYMENT_PLACEMENT_AGENCIES'
  | 'TEMPORARY_EMPLOYMENT_AGENCY_ACTIVITIES'
  | 'OTHER_HUMAN_RESOURCES_PROVISION'
  | 'TRAVEL_AGENCY_AND_TOUR_OPERATOR_ACTIVITIES'
  | 'OTHER_RESERVATION_SERVICE_AND_RELATED_ACTIVITIES'
  | 'PRIVATE_SECURITY_ACTIVITIES'
  | 'SECURITY_SYSTEMS_SERVICE_ACTIVITIES'
  | 'INVESTIGATION_ACTIVITIES'
  | 'COMBINED_FACILITIES_SUPPORT_ACTIVITIES'
  | 'CLEANING_ACTIVITIES'
  | 'LANDSCAPE_SERVICE_ACTIVITIES'
  | 'OFFICE_ADMINISTRATIVE_AND_SUPPORT_ACTIVITIES'
  | 'ACTIVITIES_OF_CALL_CENTRES'
  | 'ORGANISATION_OF_CONVENTIONS_AND_TRADE_SHOWS'
  | 'BUSINESS_SUPPORT_SERVICE_ACTIVITIES_NEC'
  | 'ADMINISTRATION_OF_THE_STATE_AND_THE_ECONOMIC_AND_SOCIAL_POLICY_OF_THE_COMMUNITY'
  | 'PROVISION_OF_SERVICES_TO_THE_COMMUNITY_AS_AWHOLE'
  | 'COMPULSORY_SOCIAL_SECURITY_ACTIVITIES'
  | 'PRE_PRIMARY_EDUCATION'
  | 'PRIMARY_EDUCATION'
  | 'SECONDARY_EDUCATION'
  | 'HIGHER_EDUCATION'
  | 'OTHER_EDUCATION'
  | 'EDUCATIONAL_SUPPORT_ACTIVITIES'
  | 'HOSPITAL_ACTIVITIES'
  | 'MEDICAL_AND_DENTAL_PRACTICE_ACTIVITIES'
  | 'OTHER_HUMAN_HEALTH_ACTIVITIES'
  | 'RESIDENTIAL_NURSING_CARE_ACTIVITIES'
  | 'RESIDENTIAL_CARE_ACTIVITIES_FOR_MENTAL_RETARDATION_MENTAL_HEALTH_AND_SUBSTANCE_ABUSE'
  | 'RESIDENTIAL_CARE_ACTIVITIES_FOR_THE_ELDERLY_AND_DISABLED'
  | 'OTHER_RESIDENTIAL_CARE_ACTIVITIES'
  | 'SOCIAL_WORK_ACTIVITIES_WITHOUT_ACCOMMODATION_FOR_THE_ELDERLY_AND_DISABLED'
  | 'OTHER_SOCIAL_WORK_ACTIVITIES_WITHOUT_ACCOMMODATION'
  | 'CREATIVE_ARTS_AND_ENTERTAINMENT_ACTIVITIES'
  | 'LIBRARIES_ARCHIVES_MUSEUMS_AND_OTHER_CULTURAL_ACTIVITIES'
  | 'GAMBLING_AND_BETTING_ACTIVITIES'
  | 'SPORTS_ACTIVITIES'
  | 'AMUSEMENT_AND_RECREATION_ACTIVITIES'
  | 'ACTIVITIES_OF_BUSINESS_EMPLOYERS_AND_PROFESSIONAL_MEMBERSHIP_ORGANISATIONS'
  | 'ACTIVITIES_OF_TRADE_UNIONS'
  | 'ACTIVITIES_OF_OTHER_MEMBERSHIP_ORGANISATIONS'
  | 'REPAIR_OF_COMPUTERS_AND_COMMUNICATION_EQUIPMENT'
  | 'REPAIR_OF_PERSONAL_AND_HOUSEHOLD_GOODS'
  | 'OTHER_PERSONAL_SERVICE_ACTIVITIES'
  | 'ACTIVITIES_OF_HOUSEHOLDS_AS_EMPLOYERS_OF_DOMESTIC_PERSONNEL'
  | 'UNDIFFERENTIATED_GOODS_PRODUCING_ACTIVITIES_OF_PRIVATE_HOUSEHOLDS_FOR_OWN_USE'
  | 'UNDIFFERENTIATED_SERVICE_PRODUCING_ACTIVITIES_OF_PRIVATE_HOUSEHOLDS_FOR_OWN_USE'
  | 'ACTIVITIES_OF_EXTRATERRITORIAL_ORGANISATIONS_AND_BODIES';

export type NaceCode = NaceCodes['codes'][0];

export interface InstallationActivityDetails {
  label: string;
  value?: NaceCode;
  children?: { label: string; value: NaceCode }[];
}

export const installationActivities: Record<InstallationActivity, InstallationActivityDetails> = {
  GROWING_OF_NON_PERENNIAL_CROPS: {
    label: '11 Growing of non-perennial crops',
    children: [
      {
        value: '_111_GROWING_OF_CEREAL_EXCEPT_RICE_LEGUMINOUS_CROPS_AND_OIL_SEEDS',
        label: '111 Growing of cereals (except rice), leguminous crops and oil seeds',
      },
      {
        value: '_112_GROWING_OF_RICE',
        label: '112 Growing of rice',
      },
      {
        value: '_113_GROWING_OF_VEGETABLES_AND_MELONS_ROOTS_AND_TUBERS',
        label: '113 Growing of vegetables and melons, roots and tubers',
      },
      {
        value: '_114_GROWING_OF_SUGAR_CANE',
        label: '114 Growing of sugar cane',
      },
      {
        value: '_115_GROWING_OF_TOBACCO',
        label: '115 Growing of tobacco',
      },
      {
        value: '_116_GROWING_OF_FIBRE_CROPS',
        label: '116 Growing of fibre crops',
      },
      {
        value: '_119_GROWING_OF_OTHER_NON_PERENNIAL_CROPS',
        label: '119 Growing of other non-perennial crops',
      },
    ],
  },
  GROWING_OF_PERENNIAL_CROPS: {
    label: '12 Growing of perennial crops',
    children: [
      {
        value: '_121_GROWING_OF_GRAPES',
        label: '121 Growing of grapes',
      },
      {
        value: '_122_GROWING_OF_TROPICAL_AND_SUBTROPICAL_FRUITS',
        label: '122 Growing of tropical and subtropical fruits',
      },
      {
        value: '_123_GROWING_OF_CITRUS_FRUITS',
        label: '123 Growing of citrus fruits',
      },
      {
        value: '_124_GROWING_OF_POME_FRUITS_AND_STONE_FRUITS',
        label: '124 Growing of pome fruits and stone fruits',
      },
      {
        value: '_125_GROWING_OF_OTHER_TREE_AND_BUSH_FRUITS_AND_NUTS',
        label: '125 Growing of other tree and bush fruits and nuts',
      },
      {
        value: '_126_GROWING_OF_OLEAGINOUS_FRUITS',
        label: '126 Growing of oleaginous fruits',
      },
      {
        value: '_127_GROWING_OF_BEVERAGE_CROPS',
        label: '127 Growing of beverage crops',
      },
      {
        value: '_128_GROWING_OF_SPICES_AROMATIC_DRUG_AND_PHARMACEUTICAL_CROPS',
        label: '128 Growing of spices, aromatic, drug and pharmaceutical crops',
      },
      {
        value: '_129_GROWING_OF_OTHER_PERENNIAL_CROPS',
        label: '129 Growing of other perennial crops',
      },
    ],
  },
  PLANT_PROPAGATION: {
    value: '_13_PLANT_PROPAGATION',
    label: '13 Plant propagation',
  },
  ANIMAL_PRODUCTION: {
    label: '14 Animal production',
    children: [
      {
        value: '_141_RAISING_OF_DAIRY_CATTLE',
        label: '141 Raising of dairy cattle',
      },
      {
        value: '_142_RAISING_OF_OTHER_CATTLE_AND_BUFFALOES',
        label: '142 Raising of other cattle and buffaloes',
      },
      {
        value: '_143_RAISING_OF_HORSES_AND_OTHER_EQUINES',
        label: '143 Raising of horses and other equines',
      },
      {
        value: '_144_RAISING_OF_CAMELS_AND_CAMELIDS',
        label: '144 Raising of camels and camelids',
      },
      {
        value: '_145_RAISING_OF_SHEEP_AND_GOATS',
        label: '145 Raising of sheep and goats',
      },
      {
        value: '_146_RAISING_OF_SWINE_PIGS',
        label: '146 Raising of swine/pigs',
      },
      {
        value: '_147_RAISING_OF_POULTRY',
        label: '147 Raising of poultry',
      },
      {
        value: '_149_RAISING_OF_OTHER_ANIMALS',
        label: '149 Raising of other animals',
      },
    ],
  },
  MIXED_FARMING: {
    value: '_15_MIXED_FARMING',
    label: '15 Mixed farming',
  },
  SUPPORT_ACTIVITIES_TO_AGRICULTURE_AND_POST_HARVEST_CROP_ACTIVITIES: {
    label: '16 Support activities to agriculture and post-harvest crop activities',
    children: [
      {
        value: '_161_SUPPORT_ACTIVITIES_FOR_CROP_PRODUCTION',
        label: '161 Support activities for crop production',
      },
      {
        value: '_162_SUPPORT_ACTIVITIES_FOR_ANIMAL_PRODUCTION',
        label: '162 Support activities for animal production',
      },
      {
        value: '_163_POST_HARVEST_CROP_ACTIVITIES',
        label: '163 Post-harvest crop activities',
      },
      {
        value: '_164_SEED_PROCESSING_FOR_PROPAGATION',
        label: '164 Seed processing for propagation',
      },
    ],
  },
  HUNTING_TRAPPING_AND_RELATED_SERVICE_ACTIVITIES: {
    value: '_17_HUNTING_TRAPPING_AND_RELATED_SERVICE_ACTIVITIES',
    label: '17 Hunting, trapping and related service activities',
  },
  SILVICULTURE_AND_OTHER_FORESTRY_ACTIVITIES: {
    value: '_21_SILVICULTURE_AND_OTHER_FORESTRY_ACTIVITIES',
    label: '21 Silviculture and other forestry activities',
  },
  LOGGING: {
    value: '_22_LOGGING',
    label: '22 Logging',
  },
  GATHERING_OF_WILD_GROWING_NON_WOOD_PRODUCTS: {
    value: '_23_GATHERING_OF_WILD_GROWING_NON_WOOD_PRODUCTS',
    label: '23 Gathering of wild growing non-wood products',
  },
  SUPPORT_SERVICES_TO_FORESTRY: {
    value: '_24_SUPPORT_SERVICES_TO_FORESTRY',
    label: '24 Support services to forestry',
  },
  FISHING: {
    label: '31 Fishing',
    children: [
      {
        value: '_311_MARINE_FISHING',
        label: '311 Marine fishing',
      },
      {
        value: '_312_FRESHWATER_FISHING',
        label: '312 Freshwater fishing',
      },
    ],
  },
  AQUACULTURE: {
    label: '32 Aquaculture',
    children: [
      {
        value: '_321_MARINE_AQUACULTURE',
        label: '321 Marine aquaculture',
      },
      {
        value: '_322_FRESHWATER_AQUACULTURE',
        label: '322 Freshwater aquaculture',
      },
    ],
  },
  MINING_OF_HARD_COAL: {
    value: '_51_MINING_OF_HARD_COAL',
    label: '51 Mining of hard coal',
  },
  MINING_OF_LIGNITE: {
    value: '_52_MINING_OF_LIGNITE',
    label: '52 Mining of lignite',
  },
  EXTRACTION_OF_CRUDE_PETROLEUM: {
    value: '_61_EXTRACTION_OF_CRUDE_PETROLEUM',
    label: '61 Extraction of crude petroleum',
  },
  EXTRACTION_OF_NATURAL_GAS: {
    value: '_62_EXTRACTION_OF_NATURAL_GAS',
    label: '62 Extraction of natural gas',
  },
  MINING_OF_IRON_ORES: {
    value: '_71_MINING_OF_IRON_ORES',
    label: '71 Mining of iron ores',
  },
  MINING_OF_NON_FERROUS_METAL_ORES: {
    label: '72 Mining of non-ferrous metal ores',
    children: [
      {
        value: '_721_MINING_OF_URANIUM_AND_THORIUM_ORES',
        label: '721 Mining of uranium and thorium ores',
      },
      {
        value: '_729_MINING_OF_OTHER_NON_FERROUS_METAL_ORES',
        label: '729 Mining of other non-ferrous metal ores',
      },
    ],
  },
  QUARRYING_OF_STONE_SAND_AND_CLAY: {
    label: '81 Quarrying of stone, sand and clay',
    children: [
      {
        value: '_811_QUARRYING_OF_ORNAMENTAL_AND_BUILDING_STONE_LIMESTONE_GYPSUM_CHALK_AND_SLATE',
        label: '811 Quarrying of ornamental and building stone, limestone, gypsum, chalk and slate',
      },
      {
        value: '_812_OPERATION_OF_GRAVEL_AND_SAND_PITS_MINING_OF_CLAYS_AND_KAOLIN',
        label: '812 Operation of gravel and sand pits; mining of clays and kaolin',
      },
    ],
  },
  MINING_AND_QUARRYING_NEC: {
    label: '89 Mining and quarrying n.e.c.',
    children: [
      {
        value: '_891_MINING_OF_CHEMICAL_AND_FERTILISER_MINERALS',
        label: '891 Mining of chemical and fertiliser minerals',
      },
      {
        value: '_892_EXTRACTION_OF_PEAT',
        label: '892 Extraction of peat',
      },
      {
        value: '_893_EXTRACTION_OF_SALT',
        label: '893 Extraction of salt',
      },
      {
        value: '_899_OTHER_MINING_AND_QUARRYING_N_E_C',
        label: '899 Other mining and quarrying n.e.c.',
      },
    ],
  },
  SUPPORT_ACTIVITIES_FOR_PETROLEUM_AND_NATURAL_GAS_EXTRACTION: {
    value: '_91_SUPPORT_ACTIVITIES_FOR_PETROLEUM_AND_NATURAL_GAS_EXTRACTION',
    label: '91 Support activities for petroleum and natural gas extraction',
  },
  SUPPORT_ACTIVITIES_FOR_OTHER_MINING_AND_QUARRYING: {
    value: '_99_SUPPORT_ACTIVITIES_FOR_OTHER_MINING_AND_QUARRYING',
    label: '99 Support activities for other mining and quarrying',
  },
  PROCESSING_AND_PRESERVING_OF_MEAT_AND_PRODUCTION_OF_MEAT_PRODUCTS: {
    label: '101 Processing and preserving of meat and production of meat products',
    children: [
      {
        value: '_1011_PROCESSING_AND_PRESERVING_OF_MEAT',
        label: '1011 Processing and preserving of meat',
      },
      {
        value: '_1012_PROCESSING_AND_PRESERVING_OF_POULTRY_MEAT',
        label: '1012 Processing and preserving of poultry meat',
      },
      {
        value: '_1013_PRODUCTION_OF_MEAT_AND_POULTRY_MEAT_PRODUCTS',
        label: '1013 Production of meat and poultry meat products',
      },
    ],
  },
  PROCESSING_AND_PRESERVING_OF_FISH_CRUSTACEANS_AND_MOLLUSCS: {
    value: '_102_PROCESSING_AND_PRESERVING_OF_FISH_CRUSTACEANS_AND_MOLLUSCS',
    label: '102 Processing and preserving of fish, crustaceans and molluscs',
  },
  PROCESSING_AND_PRESERVING_OF_FRUIT_AND_VEGETABLES: {
    label: '103 Processing and preserving of fruit and vegetables',
    children: [
      {
        value: '_1031_PROCESSING_AND_PRESERVING_OF_POTATOES',
        label: '1031 Processing and preserving of potatoes',
      },
      {
        value: '_1032_MANUFACTURE_OF_FRUIT_AND_VEGETABLE_JUICE',
        label: '1032 Manufacture of fruit and vegetable juice',
      },
      {
        value: '_1039_OTHER_PROCESSING_AND_PRESERVING_OF_FRUIT_AND_VEGETABLES',
        label: '1039 Other processing and preserving of fruit and vegetables',
      },
    ],
  },
  MANUFACTURE_OF_VEGETABLE_AND_ANIMAL_OILS_AND_FATS: {
    label: '104 Manufacture of vegetable and animal oils and fats',
    children: [
      {
        value: '_1041_MANUFACTURE_OF_OILS_AND_FATS',
        label: '1041 Manufacture of oils and fats',
      },
      {
        value: '_1042_MANUFACTURE_OF_MARGARINE_AND_SIMILAR_EDIBLE_FATS',
        label: '1042 Manufacture of margarine and similar edible fats',
      },
    ],
  },
  MANUFACTURE_OF_DAIRY_PRODUCTS: {
    label: '105 Manufacture of dairy products',
    children: [
      {
        value: '_1051_OPERATION_OF_DAIRIES_AND_CHEESE_MAKING',
        label: '1051 Operation of dairies and cheese making',
      },
      {
        value: '_1052_MANUFACTURE_OF_ICE_CREAM',
        label: '1052 Manufacture of ice cream',
      },
    ],
  },
  MANUFACTURE_OF_GRAIN_MILL_PRODUCTS_STARCHES_AND_STARCH_PRODUCTS: {
    label: '106 Manufacture of grain mill products, starches and starch products',
    children: [
      {
        value: '_1061_MANUFACTURE_OF_GRAIN_MILL_PRODUCTS',
        label: '1061 Manufacture of grain mill products',
      },
      {
        value: '_1062_MANUFACTURE_OF_STARCHES_AND_STARCH_PRODUCTS',
        label: '1062 Manufacture of starches and starch products',
      },
    ],
  },
  MANUFACTURE_OF_BAKERY_AND_FARINACEOUS_PRODUCTS: {
    label: '107 Manufacture of bakery and farinaceous products',
    children: [
      {
        value: '_1071_MANUFACTURE_OF_BREAD_MANUFACTURE_OF_FRESH_PASTRY_GOODS_AND_CAKES',
        label: '1071 Manufacture of bread; manufacture of fresh pastry goods and cakes',
      },
      {
        value: '_1072_MANUFACTURE_OF_RUSKS_AND_BISCUITS_MANUFACTURE_OF_PRESERVED_PASTRY_GOODS_AND_CAKES',
        label: '1072 Manufacture of rusks and biscuits; manufacture of preserved pastry goods and cakes',
      },
      {
        value: '_1073_MANUFACTURE_OF_MACARONI_NOODLES_COUSCOUS_AND_SIMILAR_FARINACEOUS_PRODUCTS',
        label: '1073 Manufacture of macaroni, noodles, couscous and similar farinaceous products',
      },
    ],
  },
  MANUFACTURE_OF_OTHER_FOOD_PRODUCTS: {
    label: '108 Manufacture of other food products',
    children: [
      {
        value: '_1081_MANUFACTURE_OF_SUGAR',
        label: '1081 Manufacture of sugar',
      },
      {
        value: '_1082_MANUFACTURE_OF_COCOA_CHOCOLATE_AND_SUGAR_CONFECTIONERY',
        label: '1082 Manufacture of cocoa, chocolate and sugar confectionery',
      },
      {
        value: '_1083_PROCESSING_OF_TEA_AND_COFFEE',
        label: '1083 Processing of tea and coffee',
      },
      {
        value: '_1084_MANUFACTURE_OF_CONDIMENTS_AND_SEASONINGS',
        label: '1084 Manufacture of condiments and seasonings',
      },
      {
        value: '_1085_MANUFACTURE_OF_PREPARED_MEALS_AND_DISHES',
        label: '1085 Manufacture of prepared meals and dishes',
      },
      {
        value: '_1086_MANUFACTURE_OF_HOMOGENISED_FOOD_PREPARATIONS_AND_DIETETIC_FOOD',
        label: '1086 Manufacture of homogenised food preparations and dietetic food',
      },
      {
        value: '_1089_MANUFACTURE_OF_OTHER_FOOD_PRODUCTS_N_E_C',
        label: '1089 Manufacture of other food products n.e.c.',
      },
    ],
  },
  MANUFACTURE_OF_PREPARED_ANIMAL_FEEDS: {
    label: '109 Manufacture of prepared animal feeds',
    children: [
      {
        value: '_1091_MANUFACTURE_OF_PREPARED_FEEDS_FOR_FARM_ANIMALS',
        label: '1091 Manufacture of prepared feeds for farm animals',
      },
      {
        value: '_1092_MANUFACTURE_OF_PREPARED_PET_FOODS',
        label: '1092 Manufacture of prepared pet foods',
      },
    ],
  },
  MANUFACTURE_OF_BEVERAGES: {
    label: '110 Manufacture of beverages',
    children: [
      {
        value: '_1101_DISTILLING_RECTIFYING_AND_BLENDING_OF_SPIRITS',
        label: '1101 Distilling, rectifying and blending of spirits',
      },
      {
        value: '_1102_MANUFACTURE_OF_WINE_FROM_GRAPE',
        label: '1102 Manufacture of wine from grape',
      },
      {
        value: '_1103_MANUFACTURE_OF_CIDER_AND_OTHER_FRUIT_WINES',
        label: '1103 Manufacture of cider and other fruit wines',
      },
      {
        value: '_1104_MANUFACTURE_OF_OTHER_NON_DISTILLED_FERMENTED_BEVERAGES',
        label: '1104 Manufacture of other non-distilled fermented beverages',
      },
      {
        value: '_1105_MANUFACTURE_OF_BEER',
        label: '1105 Manufacture of beer',
      },
      {
        value: '_1106_MANUFACTURE_OF_MALT',
        label: '1106 Manufacture of malt',
      },
      {
        value: '_1107_MANUFACTURE_OF_SOFT_DRINKS_PRODUCTION_OF_MINERAL_WATERS_AND_OTHER_BOTTLED_WATERS',
        label: '1107 Manufacture of soft drinks; production of mineral waters and other bottled waters',
      },
    ],
  },
  MANUFACTURE_OF_TOBACCO_PRODUCTS: {
    value: '_120_MANUFACTURE_OF_TOBACCO_PRODUCTS',
    label: '120 Manufacture of tobacco products',
  },
  PREPARATION_AND_SPINNING_OF_TEXTILE_FIBRES: {
    value: '_131_PREPARATION_AND_SPINNING_OF_TEXTILE_FIBRES',
    label: '131 Preparation and spinning of textile fibres',
  },
  WEAVING_OF_TEXTILES: {
    value: '_132_WEAVING_OF_TEXTILES',
    label: '132 Weaving of textiles',
  },
  FINISHING_OF_TEXTILES: {
    value: '_133_FINISHING_OF_TEXTILES',
    label: '133 Finishing of textiles',
  },
  MANUFACTURE_OF_OTHER_TEXTILES: {
    label: '139 Manufacture of other textiles',
    children: [
      {
        value: '_1391_MANUFACTURE_OF_KNITTED_AND_CROCHETED_FABRICS',
        label: '1391 Manufacture of knitted and crocheted fabrics',
      },
      {
        value: '_1392_MANUFACTURE_OF_MADE_UP_TEXTILE_ARTICLES_EXCEPT_APPAREL',
        label: '1392 Manufacture of made-up textile articles, except apparel',
      },
      {
        value: '_1393_MANUFACTURE_OF_CARPETS_AND_RUGS',
        label: '1393 Manufacture of carpets and rugs',
      },
      {
        value: '_1394_MANUFACTURE_OF_CORDAGE_ROPE_TWINE_AND_NETTING',
        label: '1394 Manufacture of cordage, rope, twine and netting',
      },
      {
        value: '_1395_MANUFACTURE_OF_NON_WOVENS_AND_ARTICLES_MADE_FROM_NON_WOVENS_EXCEPT_APPAREL',
        label: '1395 Manufacture of non-wovens and articles made from non-wovens, except apparel',
      },
      {
        value: '_1396_MANUFACTURE_OF_OTHER_TECHNICAL_AND_INDUSTRIAL_TEXTILES',
        label: '1396 Manufacture of other technical and industrial textiles',
      },
      {
        value: '_1399_MANUFACTURE_OF_OTHER_TEXTILES_N_E_C_',
        label: '1399 Manufacture of other textiles n.e.c.',
      },
    ],
  },
  MANUFACTURE_OF_WEARING_APPAREL_EXCEPT_FUR_APPAREL: {
    label: '141 Manufacture of wearing apparel, except fur apparel',
    children: [
      {
        value: '_1411_MANUFACTURE_OF_LEATHER_CLOTHES',
        label: '1411 Manufacture of leather clothes',
      },
      {
        value: '_1412_MANUFACTURE_OF_WORKWEAR',
        label: '1412 Manufacture of workwear',
      },
      {
        value: '_1413_MANUFACTURE_OF_OTHER_OUTERWEAR',
        label: '1413 Manufacture of other outerwear',
      },
      {
        value: '_1414_MANUFACTURE_OF_UNDERWEAR',
        label: '1414 Manufacture of underwear',
      },
      {
        value: '_1419_MANUFACTURE_OF_OTHER_WEARING_APPAREL_AND_ACCESSORIES',
        label: '1419 Manufacture of other wearing apparel and accessories',
      },
    ],
  },
  MANUFACTURE_OF_ARTICLES_OF_FUR: {
    value: '_142_MANUFACTURE_OF_ARTICLES_OF_FUR',
    label: '142 Manufacture of articles of fur',
  },
  MANUFACTURE_OF_KNITTED_AND_CROCHETED_APPAREL: {
    label: '143 Manufacture of knitted and crocheted apparel',
    children: [
      {
        value: '_1431_MANUFACTURE_OF_KNITTED_AND_CROCHETED_HOSIERY',
        label: '1431 Manufacture of knitted and crocheted hosiery',
      },
      {
        value: '_1439_MANUFACTURE_OF_OTHER_KNITTED_AND_CROCHETED_APPAREL',
        label: '1439 Manufacture of other knitted and crocheted apparel',
      },
    ],
  },
  TANNING_AND_DRESSING_OF_LEATHER_MANUFACTURE_OF_LUGGAGE_HANDBAGS_SADDLERY_AND_HARNESS_DRESSING_AND_DYEING_OF_FUR: {
    label:
      '151 Tanning and dressing of leather; manufacture of luggage, handbags, saddlery and harness; dressing and dyeing of fur',
    children: [
      {
        value: '_1511_TANNING_AND_DRESSING_OF_LEATHER_DRESSING_AND_DYEING_OF_FUR',
        label: '1511 Tanning and dressing of leather; dressing and dyeing of fur',
      },
      {
        value: '_1512_MANUFACTURE_OF_LUGGAGE_HANDBAGS_AND_THE_LIKE_SADDLERY_AND_HARNESS',
        label: '1512 Manufacture of luggage, handbags and the like, saddlery and harness',
      },
    ],
  },
  MANUFACTURE_OF_FOOTWEAR: {
    value: '_152_MANUFACTURE_OF_FOOTWEAR',
    label: '152 Manufacture of footwear',
  },
  SAWMILLING_AND_PLANING_OF_WOOD: {
    value: '_161_SAWMILLING_AND_PLANING_OF_WOOD',
    label: '161 Sawmilling and planing of wood',
  },
  MANUFACTURE_OF_PRODUCTS_OF_WOOD_CORK_STRAW_AND_PLAITING_MATERIALS: {
    label: '162 Manufacture of products of wood, cork, straw and plaiting materials',
    children: [
      {
        value: '_1621_MANUFACTURE_OF_VENEER_SHEETS_AND_WOOD_BASED_PANELS',
        label: '1621 Manufacture of veneer sheets and wood-based panels',
      },
      {
        value: '_1622_MANUFACTURE_OF_ASSEMBLED_PARQUET_FLOORS',
        label: '1622 Manufacture of assembled parquet floors',
      },
      {
        value: '_1623_MANUFACTURE_OF_OTHER_BUILDERS_CARPENTRY_AND_JOINERY',
        label: "1623 Manufacture of other builders' carpentry and joinery",
      },
      {
        value: '_1624_MANUFACTURE_OF_WOODEN_CONTAINERS',
        label: '1624 Manufacture of wooden containers',
      },
      {
        value:
          '_1629_MANUFACTURE_OF_OTHER_PRODUCTS_OF_WOOD_MANUFACTURE_OF_ARTICLES_OF_CORK_STRAW_AND_PLAINTING_MATERIALS',
        label:
          '1629 Manufacture of other products of wood; manufacture of articles of cork, straw and plaiting materials',
      },
    ],
  },
  MANUFACTURE_OF_PULP_PAPER_AND_PAPERBOARD: {
    label: '171 Manufacture of products of wood, cork, straw and plaiting materials',
    children: [
      {
        value: '_1711_MANUFACTURE_OF_PULP',
        label: '1711 Manufacture of pulp',
      },
      {
        value: '_1712_MANUFACTURE_OF_PAPER_AND_PAPERBOARD',
        label: '1712 Manufacture of paper and paperboard',
      },
    ],
  },
  MANUFACTURE_OF_ARTICLES_OF_PAPER_AND_PAPERBOARD: {
    label: '172 Manufacture of articles of paper and paperboard',
    children: [
      {
        value: '_1721_MANUFACTURE_OF_CORRUGATED_PAPER_AND_PAPERBOARD_AND_OF_CONTAINERS_OF_PAPER_AND_PAPERBOA',
        label: '1721 Manufacture of corrugated paper and paperboard and of containers of paper and paperboard',
      },
      {
        value: '_1722_MANUFACTURE_OF_HOUSEHOLD_AND_SANITARY_GOODS_AND_OF_TOILET_REQUISITES',
        label: '1722 Manufacture of household and sanitary goods and of toilet requisites',
      },
      {
        value: '_1723_MANUFACTURE_OF_PAPER_STATIONERY',
        label: '1723 Manufacture of paper stationery',
      },
      {
        value: '_1724_MANUFACTURE_OF_WALLPAPER',
        label: '1724 Manufacture of wallpaper',
      },
      {
        value: '_1729_MANUFACTURE_OF_OTHER_ARTICLES_OF_PAPER_AND_PAPERBOARD',
        label: '1729 Manufacture of other articles of paper and paperboard',
      },
    ],
  },
  PRINTING_AND_SERVICE_ACTIVITIES_RELATED_TO_PRINTING: {
    label: '181 Printing and service activities related to printing',
    children: [
      {
        value: '_1811_PRINTING_OF_NEWSPAPERS',
        label: '1811 Printing of newspapers',
      },
      {
        value: '_1812_OTHER_PRINTING',
        label: '1812 Other printing',
      },
      {
        value: '_1813_PRE_PRESS_AND_PRE_MEDIA_SERVICES',
        label: '1813 Pre-press and pre-media services',
      },
      {
        value: '_1814_BINDING_AND_RELATED_SERVICES',
        label: '1814 Binding and related services',
      },
    ],
  },
  REPRODUCTION_OF_RECORDED_MEDIA: {
    value: '_182_REPRODUCTION_OF_RECORDED_MEDIA',
    label: '182 Reproduction of recorded media',
  },
  MANUFACTURE_OF_COKE_OVEN_PRODUCTS: {
    value: '_191_MANUFACTURE_OF_COKE_OVEN_PRODUCTS',
    label: '191 Manufacture of coke oven products',
  },
  MANUFACTURE_OF_REFINED_PETROLEUM_PRODUCTS: {
    value: '_192_MANUFACTURE_OF_REFINED_PETROLEUM_PRODUCTS',
    label: '192 Manufacture of refined petroleum products',
  },
  MANUFACTURE_OF_BASIC_CHEMICALS_FERTILISERS_AND_NITROGEN_COMPOUNDS_PLASTICS_AND_SYNTHETIC_RUBBER_IN_PRIMARY_FORMS: {
    label:
      '201 Manufacture of basic chemicals, fertilisers and nitrogen compounds, plastics and synthetic rubber in primary forms',
    children: [
      {
        value: '_2011_MANUFACTURE_OF_INDUSTRIAL_GASES',
        label: '2011 Manufacture of industrial gases',
      },
      {
        value: '_2012_MANUFACTURE_OF_DYES_AND_PIGMENTS',
        label: '2012 Manufacture of dyes and pigments',
      },
      {
        value: '_2013_MANUFACTURE_OF_OTHER_INORGANIC_BASIC_CHEMICALS',
        label: '2013 Manufacture of other inorganic basic chemicals',
      },
      {
        value: '_2014_MANUFACTURE_OF_OTHER_ORGANIC_BASIC_CHEMICALS',
        label: '2014 Manufacture of other organic basic chemicals',
      },
      {
        value: '_2015_MANUFACTURE_OF_FERTILISERS_AND_NITROGEN_COMPOUNDS',
        label: '2015 Manufacture of fertilisers and nitrogen compounds',
      },
      {
        value: '_2016_MANUFACTURE_OF_PLASTICS_IN_PRIMARY_FORMS',
        label: '2016 Manufacture of plastics in primary forms',
      },
      {
        value: '_2017_MANUFACTURE_OF_SYNTHETIC_RUBBER_IN_PRIMARY_FORMS',
        label: '2017 Manufacture of synthetic rubber in primary forms',
      },
    ],
  },
  MANUFACTURE_OF_PESTICIDES_AND_OTHER_AGROCHEMICAL_PRODUCTS: {
    value: '_202_MANUFACTURE_OF_PESTICIDES_AND_OTHER_AGROCHEMICAL_PRODUCTS',
    label: '202 Manufacture of pesticides and other agrochemical products',
  },
  MANUFACTURE_OF_PAINTS_VARNISHES_AND_SIMILAR_COATINGS_PRINTING_INK_AND_MASTICS: {
    value: '_203_MANUFACTURE_OF_PAINTS_VARNISHES_AND_SIMILAR_COATINGS_PRINTING_INK_AND_MASTICS',
    label: '203 Manufacture of paints, varnishes and similar coatings, printing ink and mastics',
  },
  MANUFACTURE_OF_SOAP_AND_DETERGENTS_CLEANING_AND_POLISHING_PREPARATIONS_PERFUMES_AND_TOILET_PREPARATIONS: {
    label:
      '204 Manufacture of soap and detergents, cleaning and polishing preparations, perfumes and toilet preparations',
    children: [
      {
        value: '_2041_MANUFACTURE_OF_SOAP_AND_DETERGENTS_CLEANING_AND_POLISHING_PREPARATIONS',
        label: '2041 Manufacture of soap and detergents, cleaning and polishing preparations',
      },
      {
        value: '_2042_MANUFACTURE_OF_PERFUMES_AND_TOILET_PREPARATIONS',
        label: '2042 Manufacture of perfumes and toilet preparations',
      },
    ],
  },
  MANUFACTURE_OF_OTHER_CHEMICAL_PRODUCTS: {
    label: '205 Manufacture of other chemical products',
    children: [
      {
        value: '_2051_MANUFACTURE_OF_EXPLOSIVES',
        label: '2051 Manufacture of explosives',
      },
      {
        value: '_2052_MANUFACTURE_OF_GLUES',
        label: '2052 Manufacture of glues',
      },
      {
        value: '_2053_MANUFACTURE_OF_ESSENTIAL_OILS',
        label: '2053 Manufacture of essential oils',
      },
      {
        value: '_2059_MANUFACTURE_OF_OTHER_CHEMICAL_PRODUCTS_N_E_C_',
        label: '2059 Manufacture of other chemical products n.e.c.',
      },
    ],
  },
  MANUFACTURE_OF_MAN_MADE_FIBRES: {
    value: '_206_MANUFACTURE_OF_MAN_MADE_FIBRES',
    label: '206 Manufacture of man-made fibres',
  },
  MANUFACTURE_OF_BASIC_PHARMACEUTICAL_PRODUCTS: {
    value: '_211_MANUFACTURE_OF_BASIC_PHARMACEUTICAL_PRODUCTS',
    label: '211 Manufacture of basic pharmaceutical products',
  },
  MANUFACTURE_OF_PHARMACEUTICAL_PREPARATIONS: {
    value: '_212_MANUFACTURE_OF_PHARMACEUTICAL_PREPARATIONS',
    label: '212 Manufacture of pharmaceutical preparations',
  },
  MANUFACTURE_OF_RUBBER_PRODUCTS: {
    label: '221 Manufacture of rubber products',
    children: [
      {
        value: '_2211_MANUFACTURE_OF_RUBBER_TYRES_AND_TUBES_RETREADING_AND_REBUILDING_OF_RUBBER_TYRES',
        label: '2211 Manufacture of rubber tyres and tubes; retreading and rebuilding of rubber tyres',
      },
      {
        value: '_2219_MANUFACTURE_OF_OTHER_RUBBER_PRODUCTS',
        label: '2219 Manufacture of other rubber products',
      },
    ],
  },
  MANUFACTURE_OF_PLASTICS_PRODUCTS: {
    label: '222 Manufacture of plastic products',
    children: [
      {
        value: '_2221_MANUFACTURE_OF_PLASTIC_PLATES_SHEETS_TUBES_AND_PROFILES',
        label: '2221 Manufacture of plastic plates, sheets, tubes and profiles',
      },
      {
        value: '_2222_MANUFACTURE_OF_PLASTIC_PACKING_GOODS',
        label: '2222 Manufacture of plastic packing goods',
      },
      {
        value: '_2223_MANUFACTURE_OF_BUILDERS_WARE_OF_PLASTIC',
        label: "2223 Manufacture of builders' ware of plastic",
      },
      {
        value: '_2229_MANUFACTURE_OF_OTHER_PLASTIC_PRODUCTS',
        label: '2229 Manufacture of other plastic products',
      },
    ],
  },
  MANUFACTURE_OF_GLASS_AND_GLASS_PRODUCTS: {
    label: '231 Manufacture of glass and glass products',
    children: [
      {
        value: '_2311_MANUFACTURE_OF_FLAT_GLASS',
        label: '2311 Manufacture of flat glass',
      },
      {
        value: '_2312_SHAPING_AND_PROCESSING_OF_FLAT_GLASS',
        label: '2312 Shaping and processing of flat glass',
      },
      {
        value: '_2313_MANUFACTURE_OF_HOLLOW_GLASS',
        label: '2313 Manufacture of hollow glass',
      },
      {
        value: '_2314_MANUFACTURE_OF_GLASS_FIBRES',
        label: '2314 Manufacture of glass fibres',
      },
      {
        value: '_2319_MANUFACTURE_AND_PROCESSING_OF_OTHER_GLASS_INCLUDING_TECHNICAL_GLASSWARE',
        label: '2319 Manufacture and processing of other glass, including technical glassware',
      },
    ],
  },
  MANUFACTURE_OF_REFRACTORY_PRODUCTS: {
    value: '_232_MANUFACTURE_OF_REFRACTORY_PRODUCTS',
    label: '232 Manufacture of refractory products',
  },
  MANUFACTURE_OF_CLAY_BUILDING_MATERIALS: {
    label: '233 Manufacture of clay building materials',
    children: [
      {
        value: '_2331_MANUFACTURE_OF_CERAMIC_TILES_AND_FLAGS',
        label: '2331 Manufacture of ceramic tiles and flags',
      },
      {
        value: '_2332_MANUFACTURE_OF_BRICKS_TILES_AND_CONSTRUCTION_PRODUCTS_IN_BAKED_CLAY',
        label: '2332 Manufacture of bricks, tiles and construction products, in baked clay',
      },
    ],
  },
  MANUFACTURE_OF_OTHER_PORCELAIN_AND_CERAMIC_PRODUCTS: {
    label: '234 Manufacture of other porcelain and ceramic products',
    children: [
      {
        value: '_2341_MANUFACTURE_OF_CERAMIC_HOUSEHOLD_AND_ORNAMENTAL_ARTICLES',
        label: '2341 Manufacture of ceramic household and ornamental articles',
      },
      {
        value: '_2342_MANUFACTURE_OF_CERAMIC_SANITARY_FIXTURES',
        label: '2342 Manufacture of ceramic sanitary fixtures',
      },
      {
        value: '_2343_MANUFACTURE_OF_CERAMIC_INSULATORS_AND_INSULATING_FITTINGS',
        label: '2343 Manufacture of ceramic insulators and insulating fittings',
      },
      {
        value: '_2344_MANUFACTURE_OF_OTHER_TECHNICAL_CERAMIC_PRODUCTS',
        label: '2344 Manufacture of other technical ceramic products',
      },
      {
        value: '_2349_MANUFACTURE_OF_OTHER_CERAMIC_PRODUCTS',
        label: '2349 Manufacture of other ceramic products',
      },
    ],
  },
  MANUFACTURE_OF_CEMENT_LIME_AND_PLASTER: {
    label: '235 Manufacture of cement, lime and plaster',
    children: [
      {
        value: '_2351_MANUFACTURE_OF_CEMENT',
        label: '2351 Manufacture of cement',
      },
      {
        value: '_2352_MANUFACTURE_OF_LIME_AND_PLASTER',
        label: '2352 Manufacture of lime and plaster',
      },
    ],
  },
  MANUFACTURE_OF_ARTICLES_OF_CONCRETE_CEMENT_AND_PLASTER: {
    label: '236 Manufacture of articles of concrete, cement and plaster',
    children: [
      {
        value: '_2361_MANUFACTURE_OF_CONCRETE_PRODUCTS_FOR_CONSTRUCTION_PURPOSES',
        label: '2361 Manufacture of concrete products for construction purposes',
      },
      {
        value: '_2362_MANUFACTURE_OF_PLASTER_PRODUCTS_FOR_CONSTRUCTION_PURPOSES',
        label: '2362 Manufacture of plaster products for construction purposes',
      },
      {
        value: '_2363_MANUFACTURE_OF_READY_MIXED_CONCRETE',
        label: '2363 Manufacture of ready-mixed concrete',
      },
      {
        value: '_2464_MANUFACTURE_OF_MORTARS',
        label: '2464 Manufacture of mortars',
      },
      {
        value: '_2465_MANUFACTURE_OF_FIBRE_CEMENT',
        label: '2465 Manufacture of fibre cement',
      },
      {
        value: '_2469_MANUFACTURE_OF_OTHER_ARTICLES_OF_CONCRETE_PLASTER_AND_CEMENT',
        label: '2469 Manufacture of other articles of concrete, plaster and cement',
      },
    ],
  },
  CUTTING_SHAPING_AND_FINISHING_OF_STONE: {
    value: '_237_CUTTING_SHAPING_AND_FINISHING_OF_STONE',
    label: '237 Cutting, shaping and finishing of stone',
  },
  MANUFACTURE_OF_ABRASIVE_PRODUCTS_AND_NON_METALLIC_MINERAL_PRODUCTS_NEC: {
    label: '239 Manufacture of abrasive products and non-metallic mineral products n.e.c.',
    children: [
      {
        value: '_2391_PRODUCTION_OF_ABRASIVE_PRODUCTS',
        label: '2391 Production of abrasive products',
      },
      {
        value: '_2399_MANUFACTURE_OF_OTHER_NON_METALLIC_MINERAL_PRODUCTS_N_E_C_',
        label: '2399 Manufacture of other non-metallic mineral products n.e.c.',
      },
    ],
  },
  MANUFACTURE_OF_BASIC_IRON_AND_STEEL_AND_OF_FERRO_ALLOYS: {
    value: '_241_MANUFACTURE_OF_BASIC_IRON_AND_STEEL_AND_OF_FERRO_ALLOYS',
    label: '241 Manufacture of basic iron and steel and of ferro-alloys',
  },
  MANUFACTURE_OF_TUBES_PIPES_HOLLOW_PROFILES_AND_RELATED_FITTINGS_OF_STEEL: {
    value: '_242_MANUFACTURE_OF_TUBES_PIPES_HOLLOW_PROFILES_AND_RELATED_FITTINGS_OF_STEEL',
    label: '242 Manufacture of tubes, pipes, hollow profiles and related fittings, of steel',
  },
  MANUFACTURE_OF_OTHER_PRODUCTS_OF_FIRST_PROCESSING_OF_STEEL: {
    label: '243 Manufacture of other products of first processing of steel',
    children: [
      {
        value: '_2431_COLD_DRAWING_OF_BARS',
        label: '2431 Cold drawing of bars',
      },
      {
        value: '_2432_COLD_ROLLING_OF_NARROW_STRIP',
        label: '2432 Cold rolling of narrow strip',
      },
      {
        value: '_2433_COLD_FORMING_OR_FOLDING',
        label: '2433 Cold forming or folding',
      },
      {
        value: '_2434_COLD_DRAWING_OF_WIRE',
        label: '2434 Cold drawing of wire',
      },
    ],
  },
  MANUFACTURE_OF_BASIC_PRECIOUS_AND_OTHER_NON_FERROUS_METALS: {
    label: '244 Manufacture of basic precious and other non-ferrous metals',
    children: [
      {
        value: '_2441_PRECIOUS_METALS_PRODUCTION',
        label: '2441 Precious metals production',
      },
      {
        value: '_2442_ALUMINIUM_PRODUCTION',
        label: '2442 Aluminium production',
      },
      {
        value: '_2443_LEAD_ZINC_AND_TIN_PRODUCTION',
        label: '2443 Lead, zinc and tin production',
      },
      {
        value: '_2444_COPPER_PRODUCTION',
        label: '2444 Copper production',
      },
      {
        value: '_2445_OTHER_NON_FERROUS_METAL_PRODUCTION',
        label: '2445 Other non-ferrous metal production',
      },
      {
        value: '_2446_PROCESSING_OF_NUCLEAR_FUEL',
        label: '2446 Processing of nuclear fuel',
      },
    ],
  },
  CASTING_OF_METALS: {
    label: '245 Casting of metals',
    children: [
      {
        value: '_2451_CASTING_OF_IRON',
        label: '2451 Casting of iron',
      },
      {
        value: '_2452_CASTING_OF_STEEL',
        label: '2452 Casting of steel',
      },
      {
        value: '_2453_CASTING_OF_LIGHT_METALS',
        label: '2453 Casting of light metals',
      },
      {
        value: '_2454_CASTING_OF_OTHER_NON_FERROUS_METALS',
        label: '2454 Casting of other non-ferrous metals',
      },
    ],
  },
  MANUFACTURE_OF_STRUCTURAL_METAL_PRODUCTS: {
    label: '251 Manufacture of structural metal products',
    children: [
      {
        value: '_2511_MANUFACTURE_OF_METAL_STRUCTURES_AND_PARTS_OF_STRUCTURES',
        label: '2511 Manufacture of metal structures and parts of structures',
      },
      {
        value: '_2512_MANUFACTURE_OF_DOORS_AND_WINDOWS_OF_METAL',
        label: '2512 Manufacture of doors and windows of metal',
      },
    ],
  },
  MANUFACTURE_OF_TANKS_RESERVOIRS_AND_CONTAINERS_OF_METAL: {
    label: '252 Manufacture of tanks, reservoirs and containers of metal',
    children: [
      {
        value: '_2521_MANUFACTURE_OF_CENTRAL_HEATING_RADIATORS_AND_BOILERS',
        label: '2521 Manufacture of central heating radiators and boilers',
      },
      {
        value: '_2529_MANUFACTURE_OF_OTHER_TANKS_RESERVOIRS_AND_CONTAINERS_OF_METAL',
        label: '2529 Manufacture of other tanks, reservoirs and containers of metal',
      },
    ],
  },
  MANUFACTURE_OF_STEAM_GENERATORS_EXCEPT_CENTRAL_HEATING_HOT_WATER_BOILERS: {
    value: '_253_MANUFACTURE_OF_STEAM_GENERATORS_EXCEPT_CENTRAL_HEATING_HOT_WATER_BOILERS',
    label: '253 Manufacture of steam generators, except central heating hot water boilers',
  },
  MANUFACTURE_OF_WEAPONS_AND_AMMUNITION: {
    value: '_254_MANUFACTURE_OF_WEAPONS_AND_AMMUNITION',
    label: '254 Manufacture of weapons and ammunition',
  },
  FORGING_PRESSING_STAMPING_AND_ROLL_FORMING_OF_METAL_POWDER_METALLURGY: {
    value: '_255_FORGING_PRESSING_STAMPING_AND_ROLL_FORMING_OF_METAL_POWDER_METALLURGY',
    label: '255 Forging, pressing, stamping and roll-forming of metal; powder metallurgy',
  },
  TREATMENT_AND_COATING_OF_METALS_MACHINING: {
    label: '256 Treatment and coating of metals; machining',
    children: [
      {
        value: '_2561_TREATMENT_AND_COATING_OF_METALS',
        label: '2561 Treatment and coating of metals',
      },
      {
        value: '_2562_MACHINING',
        label: '2562 Machining',
      },
    ],
  },
  MANUFACTURE_OF_CUTLERY_TOOLS_AND_GENERAL_HARDWARE: {
    label: '257 Manufacture of cutlery, tools and general hardware',
    children: [
      {
        value: '_2571_MANUFACTURE_OF_CUTLERY',
        label: '2571 Manufacture of cutlery',
      },
      {
        value: '_2572_MANUFACTURE_OF_LOCKS_AND_HINGES',
        label: '2572 Manufacture of locks and hinges',
      },
      {
        value: '_2573_MANUFACTURE_OF_TOOLS',
        label: '2573 Manufacture of tools',
      },
    ],
  },
  MANUFACTURE_OF_OTHER_FABRICATED_METAL_PRODUCTS: {
    label: '259 Manufacture of other fabricated metal products',
    children: [
      {
        value: '_2591_MANUFACTURE_OF_STEEL_DRUMS_AND_SIMILAR_CONTAINERS',
        label: '2591 Manufacture of steel drums and similar containers',
      },
      {
        value: '_2592_MANUFACTURE_OF_LIGHT_METAL_PACKAGING',
        label: '2592 Manufacture of light metal packaging',
      },
      {
        value: '_2593_MANUFACTURE_OF_WIRE_PRODUCTS_CHAIN_AND_SPRINGS',
        label: '2593 Manufacture of wire products, chain and springs',
      },
      {
        value: '_2594_MANUFACTURE_OF_FASTENERS_AND_SCREW_MACHINE_PRODUCTS',
        label: '2594 Manufacture of fasteners and screw machine products',
      },
      {
        value: '_2599_MANUFACTURE_OF_OTHER_FABRICATED_METAL_PRODUCTS_N_E_C_',
        label: '2599 Manufacture of other fabricated metal products n.e.c.',
      },
    ],
  },
  MANUFACTURE_OF_ELECTRONIC_COMPONENTS_AND_BOARDS: {
    label: '261 Manufacture of electronic components and boards',
    children: [
      {
        value: '_2611_MANUFACTURE_OF_ELECTRONIC_COMPONENTS',
        label: '2611 Manufacture of electronic components',
      },
      {
        value: '_2612_MANUFACTURE_OF_LOADED_ELECTRONIC_BOARDS',
        label: '2612 Manufacture of loaded electronic boards',
      },
    ],
  },
  MANUFACTURE_OF_COMPUTERS_AND_PERIPHERAL_EQUIPMENT: {
    value: '_262_MANUFACTURE_OF_COMPUTERS_AND_PERIPHERAL_EQUIPMENT',
    label: '262 Manufacture of computers and peripheral equipment',
  },
  MANUFACTURE_OF_COMMUNICATION_EQUIPMENT: {
    value: '_263_MANUFACTURE_OF_COMMUNICATION_EQUIPMENT',
    label: '263 Manufacture of communication equipment',
  },
  MANUFACTURE_OF_CONSUMER_ELECTRONICS: {
    value: '_264_MANUFACTURE_OF_CONSUMER_ELECTRONICS',
    label: '264 Manufacture of consumer electronics',
  },
  MANUFACTURE_OF_INSTRUMENTS_AND_APPLIANCES_FOR_MEASURING_TESTING_AND_NAVIGATION_WATCHES_AND_CLOCKS: {
    label: '265 Manufacture of instruments and appliances for measuring, testing and navigation; watches and clocks',
    children: [
      {
        value: '_2651_MANUFACTURE_OF_INSTRUMENTS_AND_APPLIANCES_FOR_MEASURING_TESTING_AND_NAVIGATION',
        label: '2651 Manufacture of instruments and appliances for measuring, testing and navigation',
      },
      {
        value: '_2652_MANUFACTURE_OF_WATCHES_AND_CLOCKS',
        label: '2652 Manufacture of watches and clocks',
      },
    ],
  },
  MANUFACTURE_OF_IRRADIATION_ELECTROMEDICAL_AND_ELECTROTHERAPEUTIC_EQUIPMENT: {
    value: '_266_MANUFACTURE_OF_IRRADIATION_ELECTROMEDICAL_AND_ELECTROTHERAPEUTIC_EQUIPMENT',
    label: '266 Manufacture of irradiation, electromedical and electrotherapeutic equipment',
  },
  MANUFACTURE_OF_OPTICAL_INSTRUMENTS_AND_PHOTOGRAPHIC_EQUIPMENT: {
    value: '_267_MANUFACTURE_OF_OPTICAL_INSTRUMENTS_AND_PHOTOGRAPHIC_EQUIPMENT',
    label: '267 Manufacture of optical instruments and photographic equipment',
  },
  MANUFACTURE_OF_MAGNETIC_AND_OPTICAL_MEDIA: {
    value: '_268_MANUFACTURE_OF_MAGNETIC_AND_OPTICAL_MEDIA',
    label: '268 Manufacture of magnetic and optical media',
  },
  MANUFACTURE_OF_ELECTRIC_MOTORS_GENERATORS_TRANSFORMERS_AND_ELECTRICITY_DISTRIBUTION_AND_CONTROL_APPARATUS: {
    label:
      '271 Manufacture of electric motors, generators, transformers and electricity distribution and control apparatus',
    children: [
      {
        value: '_2711_MANUFACTURE_OF_ELECTRIC_MOTORS_GENERATORS_AND_TRANSFORMERS',
        label: '2711 Manufacture of electric motors, generators and transformers',
      },
      {
        value: '_2712_MANUFACTURE_OF_ELECTRICITY_DISTRIBUTION_AND_CONTROL_APPARATUS',
        label: '2712 Manufacture of electricity distribution and control apparatus',
      },
    ],
  },
  MANUFACTURE_OF_BATTERIES_AND_ACCUMULATORS: {
    value: '_272_MANUFACTURE_OF_BATTERIES_AND_ACCUMULATORS',
    label: '272 Manufacture of batteries and accumulators',
  },
  MANUFACTURE_OF_WIRING_AND_WIRING_DEVICES: {
    label: '273 Manufacture of wiring and wiring devices',
    children: [
      {
        value: '_2731_MANUFACTURE_OF_FIBRE_OPTIC_CABLES',
        label: '2731 Manufacture of fibre optic cables',
      },
      {
        value: '_2732_MANUFACTURE_OF_OTHER_ELECTRONIC_AND_ELECTRIC_WIRES_AND_CABLES',
        label: '2732 Manufacture of other electronic and electric wires and cables',
      },
      {
        value: '_2733_MANUFACTURE_OF_WIRING_DEVICES',
        label: '2733 Manufacture of wiring devices',
      },
    ],
  },
  MANUFACTURE_OF_ELECTRIC_LIGHTING_EQUIPMENT: {
    value: '_274_MANUFACTURE_OF_ELECTRIC_LIGHTING_EQUIPMENT',
    label: '274 Manufacture of electric lighting equipment',
  },
  MANUFACTURE_OF_DOMESTIC_APPLIANCES: {
    label: '275 Manufacture of domestic appliances',
    children: [
      {
        value: '_2751_MANUFACTURE_OF_ELECTRIC_DOMESTIC_APPLIANCES',
        label: '2751 Manufacture of electric domestic appliances',
      },
      {
        value: '_2752_MANUFACTURE_OF_NON_ELECTRIC_DOMESTIC_APPLIANCES',
        label: '2752 Manufacture of non-electric domestic appliances',
      },
    ],
  },
  MANUFACTURE_OF_OTHER_ELECTRICAL_EQUIPMENT: {
    value: '_279_MANUFACTURE_OF_OTHER_ELECTRICAL_EQUIPMENT',
    label: '279 Manufacture of other electrical equipment',
  },
  MANUFACTURE_OF_GENERAL_PURPOSE_MACHINERY: {
    label: '281 Manufacture of general-purpose machinery',
    children: [
      {
        value: '_2811_MANUFACTURE_OF_ENGINES_AND_TURBINES_EXCEPT_AIRCRAFT_VEHICLE_AND_CYCLE_ENGINES',
        label: '2811 Manufacture of engines and turbines, except aircraft, vehicle and cycle engines',
      },
      {
        value: '_2812_MANUFACTURE_OF_FLUID_POWER_EQUIPMENT',
        label: '2812 Manufacture of fluid power equipment',
      },
      {
        value: '_2813_MANUFACTURE_OF_OTHER_PUMPS_AND_COMPRESSORS',
        label: '2813 Manufacture of other pumps and compressors',
      },
      {
        value: '_2814_MANUFACTURE_OF_OTHER_TAPS_AND_VALVES',
        label: '2814 Manufacture of other taps and valves',
      },
      {
        value: '_2815_MANUFACTURE_OF_BEARINGS_GEARS_GEARING_AND_DRIVING_ELEMENTS',
        label: '2815 Manufacture of bearings, gears, gearing and driving elements',
      },
    ],
  },
  MANUFACTURE_OF_OTHER_GENERAL_PURPOSE_MACHINERY: {
    label: '282 Manufacture of general-purpose machinery',
    children: [
      {
        value: '_2821_MANUFACTURE_OF_OVENS_FURNACES_AND_FURNACE_BURNERS',
        label: '2821 Manufacture of ovens, furnaces and furnace burners',
      },
      {
        value: '_2822_MANUFACTURE_OF_LIFTING_AND_HANDLING_EQUIPMENT',
        label: '2822 Manufacture of lifting and handling equipment',
      },
      {
        value: '_2823_MANUFACTURE_OF_OFFICE_MACHINERY_AND_EQUIPMENT_EXCEPT_COMPUTERS_AND_PERIPHERAL_EQUIPMENT',
        label: '2823 Manufacture of office machinery and equipment (except computers and peripheral equipment)',
      },
      {
        value: '_2824_MANUFACTURE_OF_POWER_DRIVEN_HAND_TOOLS',
        label: '2824 Manufacture of power-driven hand tools',
      },
      {
        value: '_2825_MANUFACTURE_OF_NON_COOLING_AND_VENTILATION_EQUIPMENT',
        label: '2825 Manufacture of non-domestic cooling and ventilation equipment',
      },
      {
        value: '_2829_MANUFACTURE_OF_OTHER_GENERAL_PURPOSE_MACHINERY_N_E_C_',
        label: '2829 Manufacture of other general-purpose machinery n.e.c.',
      },
    ],
  },
  MANUFACTURE_OF_AGRICULTURAL_AND_FORESTRY_MACHINERY: {
    value: '_283_MANUFACTURE_OF_AGRICULTURAL_AND_FORESTRY_MACHINERY',
    label: '283 Manufacture of agricultural and forestry machinery',
  },
  MANUFACTURE_OF_METAL_FORMING_MACHINERY_AND_MACHINE_TOOLS: {
    label: '284 Manufacture of metal forming machinery and machine tools',
    children: [
      {
        value: '_2841_MANUFACTURE_OF_METAL_FORMING_MACHINERY',
        label: '2841 Manufacture of metal forming machinery',
      },
      {
        value: '_2849_MANUFACTURE_OF_OTHER_MACHINE_TOOLS',
        label: '2849 Manufacture of other machine tools',
      },
    ],
  },
  MANUFACTURE_OF_OTHER_SPECIAL_PURPOSE_MACHINERY: {
    label: '289 Manufacture of other special-purpose machinery',
    children: [
      {
        value: '_2891_MANUFACTURE_OF_MACHINERY_FOR_METALLURGY',
        label: '2891 Manufacture of machinery for metallurgy',
      },
      {
        value: '_2892_MANUFACTURE_OF_MACHINERY_FOR_MINING_QUARRYING_AND_CONSTRUCTION',
        label: '2892 Manufacture of machinery for mining, quarrying and construction',
      },
      {
        value: '_2893_MANUFACTURE_OF_MACHINERY_FOR_FOOD_BEVERAGE_AND_TOBACCO_PROCESSING',
        label: '2893 Manufacture of machinery for food, beverage and tobacco processing',
      },
      {
        value: '_2894_MANUFACTURE_OF_MACHINERY_FOR_TEXTILE_APPAREL_AND_LEATHER_PRODUCTION',
        label: '2894 Manufacture of machinery for textile, apparel and leather production',
      },
      {
        value: '_2895_MANUFACTURE_OF_MACHINERY_FOR_PAPER_AND_PAPERBOARD_PRODUCTION',
        label: '2895 Manufacture of machinery for paper and paperboard production',
      },
      {
        value: '_2896_MANUFACTURE_OF_PLASTIC_AND_RUBBER_MACHINERY',
        label: '2896 Manufacture of plastic and rubber machinery',
      },
      {
        value: '_2899_MANUFACTURE_OF_OTHER_SPECIAL_PURPOSE_MACHINERY_N_E_C_',
        label: '2899 Manufacture of other special-purpose machinery n.e.c.',
      },
    ],
  },
  MANUFACTURE_OF_MOTOR_VEHICLES: {
    value: '_291_MANUFACTURE_OF_MOTOR_VEHICLES',
    label: '291 Manufacture of motor vehicles',
  },
  'MANUFACTURE_OF_BODIES (COACHWORK) FOR_MOTOR_VEHICLES_MANUFACTURE_OF_TRAILERS_AND_SEMI_TRAILERS': {
    value: '_292_MANUFACTURE_OF_BODIES_COACHWORK_FOR_MOTOR_VEHICLES_MANUFACTURE_TRAILERS_AND_SEMI_TRAILERS',
    label: '292 Manufacture of bodies (coachwork) for motor vehicles; manufacture of trailers and semi-trailers',
  },
  MANUFACTURE_OF_PARTS_AND_ACCESSORIES_FOR_MOTOR_VEHICLES: {
    label: '293 Manufacture of parts and accessories for motor vehicles',
    children: [
      {
        value: '_2931_MANUFACTURE_OF_ELECTRICAL_EQUIPMENT_FOR_MOTOR_VEHICLES',
        label: '2931 Manufacture of electrical and electronic equipment for motor vehicles',
      },
      {
        value: '_2932_MANUFACTURE_OF_OTHER_PARTS_AND_ACCESSORIES_FOR_MOTOR_VEHICLES',
        label: '2932 Manufacture of other parts and accessories for motor vehicles',
      },
    ],
  },
  BUILDING_OF_SHIPS_AND_BOATS: {
    label: '301 Building of ships and boats',
    children: [
      {
        value: '_3011_BUILDING_OF_SHIPS_AND_FLOATING_STRUCTURES',
        label: '3011 Building of ships and floating structures',
      },
      {
        value: '_3012_BUILDING_OF_PLEASURE_AND_SPORTING_BOATS',
        label: '3012 Building of pleasure and sporting boats',
      },
    ],
  },
  MANUFACTURE_OF_RAILWAY_LOCOMOTIVES_AND_ROLLING_STOCK: {
    value: '_302_MANUFACTURE_OF_RAILWAY_LOCOMOTIVES_AND_ROLLING_STOCK',
    label: '302 Manufacture of railway locomotives and rolling stock',
  },
  MANUFACTURE_OF_AIR_AND_SPACECRAFT_AND_RELATED_MACHINERY: {
    value: '_303_MANUFACTURE_OF_AIR_AND_SPACECRAFT_AND_RELATED_MACHINERY',
    label: '303 Manufacture of air and spacecraft and related machinery',
  },
  MANUFACTURE_OF_MILITARY_FIGHTING_VEHICLES: {
    value: '_304_MANUFACTURE_OF_MILITARY_FIGHTING_VEHICLES',
    label: '304 Manufacture of military fighting vehicles',
  },
  MANUFACTURE_OF_TRANSPORT_EQUIPMENT_NEC: {
    label: '309 Manufacture of transport equipment n.e.c.',
    children: [
      {
        value: '_3091_MANUFACTURE_OF_MOTORCYCLES',
        label: '3091 Manufacture of motorcycles',
      },
      {
        value: '_3092_MANUFACTURE_OF_BICYCLES_AND_INVALID_CARRIAGES',
        label: '3092 Manufacture of bicycles and invalid carriages',
      },
      {
        value: '_3099_MANUFACTURE_OF_OTHER_TRANSPORT_EQUIPMENT_N_E_C_',
        label: '3099 Manufacture of other transport equipment n.e.c.',
      },
    ],
  },
  MANUFACTURE_OF_FURNITURE: {
    label: '310 Manufacture of furniture',
    children: [
      {
        value: '_3101_MANUFACTURE_OF_OFFICE_AND_SHOP_FURNITURE',
        label: '3101 Manufacture of office and shop furniture',
      },
      {
        value: '_3102_MANUFACTURE_OF_KITCHEN_FURNITURE',
        label: '3102 Manufacture of kitchen furniture',
      },
      {
        value: '_3103_MANUFACTURE_OF_MATTRESSES',
        label: '3103 Manufacture of mattresses',
      },
      {
        value: '_3109_MANUFACTURE_OF_OTHER_FURNITURE',
        label: '3109 Manufacture of other furniture',
      },
    ],
  },
  MANUFACTURE_OF_JEWELLERY_BIJOUTERIE_AND_RELATED_ARTICLES: {
    label: '321 Manufacture of jewellery, bijouterie and related articles',
    children: [
      {
        value: '_3211_STRIKING_OF_COINS',
        label: '3211 Striking of coins',
      },
      {
        value: '_3212_MANUFACTURE_OF_JEWELLERY_AND_RELATED_ARTICLES',
        label: '3212 Manufacture of jewellery and related articles',
      },
      {
        value: '_3213_MANUFACTURE_OF_IMITATION_JEWELLERY_AND_RELATED_ACTIVITIES',
        label: '3213 Manufacture of imitation jewellery and related articles',
      },
    ],
  },
  MANUFACTURE_OF_MUSICAL_INSTRUMENTS: {
    value: '_322_MANUFACTURE_OF_MUSICAL_INSTRUMENTS',
    label: '322 Manufacture of musical instruments',
  },
  MANUFACTURE_OF_SPORTS_GOODS: {
    value: '_323_MANUFACTURE_OF_SPORTS_GOODS',
    label: '323 Manufacture of sports goods',
  },
  MANUFACTURE_OF_GAMES_AND_TOYS: {
    value: '_324_MANUFACTURE_OF_GAMES_AND_TOYS',
    label: '324 Manufacture of games and toys',
  },
  MANUFACTURE_OF_MEDICAL_AND_DENTAL_INSTRUMENTS_AND_SUPPLIES: {
    value: '_325_MANUFACTURE_OF_MEDICAL_AND_DENTAL_INSTRUMENTS_AND_SUPPLIES',
    label: '325 Manufacture of medical and dental instruments and supplies',
  },
  MANUFACTURING_NEC: {
    label: '329 Manufacturing n.e.c.',
    children: [
      {
        value: '_3291_MANUFACTURE_OF_BROOMS_AND_BRUSHES',
        label: '3291 Manufacture of brooms and brushes',
      },
      {
        value: '_3299_OTHER_MANUFACTURING_N_E_C_',
        label: '3299 Other manufacturing n.e.c.',
      },
    ],
  },
  REPAIR_OF_FABRICATED_METAL_PRODUCTS_MACHINERY_AND_EQUIPMENT: {
    label: '331 Repair of fabricated metal products, machinery and equipment',
    children: [
      {
        value: '_3311_REPAIR_OF_FABRICATED_METAL_PRODUCTS',
        label: '3311 Repair of fabricated metal products',
      },
      {
        value: '_3312_REPAIR_OF_MACHINERY',
        label: '3312 Repair of machinery',
      },
      {
        value: '_3313_REPAIR_OF_ELECTRONIC_AND_OPTICAL_EQUIPMENT',
        label: '3313 Repair of electronic and optical equipment',
      },
      {
        value: '_3314_REPAIR_OF_ELECTRICAL_EQUIPMENT',
        label: '3314 Repair of electrical equipment',
      },
      {
        value: '_3315_REPAIR_AND_MAINTENANCE_OF_SHIPS_AND_BOATS',
        label: '3315 Repair and maintenance of ships and boats',
      },
      {
        value: '_3316_REPAIR_AND_MAINTENANCE_OF_AIRCRAFT_AND_SPACECRAFT',
        label: '3316 Repair and maintenance of aircraft and spacecraft',
      },
      {
        value: '_3317_REPAIR_AND_MAINTENANCE_OF_OTHER_TRANSPORT_EQUIPMENT',
        label: '3317 Repair and maintenance of other transport equipment',
      },
      {
        value: '_3319_REPAIR_OF_OTHER_EQUIPMENT',
        label: '3319 Repair of other equipment',
      },
    ],
  },
  INSTALLATION_OF_INDUSTRIAL_MACHINERY_AND_EQUIPMENT: {
    value: '_332_INSTALLATION_OF_INDUSTRIAL_MACHINERY_AND_EQUIPMENT',
    label: '332 Installation of industrial machinery and equipment',
  },
  ELECTRIC_POWER_GENERATION_TRANSMISSION_AND_DISTRIBUTION: {
    label: '351 Electric power generation, transmission and distribution',
    children: [
      {
        value: '_3511_PRODUCTION_OF_ELECTRICITY',
        label: '3511 Production of electricity',
      },
      {
        value: '_3512_TRANSMISSION_OF_ELECTRICITY',
        label: '3512 Transmission of electricity',
      },
      {
        value: '_3513_DISTRIBUTION_OF_ELECTRICITY',
        label: '3513 Distribution of electricity',
      },
      {
        value: '_3514_TRADE_OF_ELECTRICITY',
        label: '3514 Trade of electricity',
      },
    ],
  },
  MANUFACTURE_OF_GAS_DISTRIBUTION_OF_GASEOUS_FUELS_THROUGH_MAINS: {
    label: '352 Manufacture of gas; distribution of gaseous fuels through mains',
    children: [
      {
        value: '_3521_MANUFACTURE_OF_GAS',
        label: '3521 Manufacture of gas',
      },
      {
        value: '_3522_DISTRIBUTION_OF_GASEOUS_FUELS_THROUGH_MAINS',
        label: '3522 Distribution of gaseous fuels through mains',
      },
      {
        value: '_3523_TRADE_OF_GAS_THROUGH_MAINS',
        label: '3523 Trade of gas through mains',
      },
    ],
  },
  STEAM_AND_AIR_CONDITIONING_SUPPLY: {
    value: '_353_TEAM_AND_AIR_CONDITIONING_SUPPLY',
    label: '353 Steam and air conditioning supply',
  },
  WATER_COLLECTION_TREATMENT_AND_SUPPLY: {
    value: '_360_WATER_COLLECTION_TREATMENT_AND_SUPPLY',
    label: '360 Water collection, treatment and supply',
  },
  SEWERAGE: {
    value: '_370_SEWERAGE',
    label: '370 Sewerage',
  },
  WASTE_COLLECTION: {
    label: '381 Waste collection',
    children: [
      {
        value: '_3811_COLLECTION_OF_NON_HAZARDOUS_WASTE',
        label: '3811 Collection of non-hazardous waste',
      },
      {
        value: '_3812_COLLECTION_OF_HAZARDOUS_WASTE',
        label: '3812 Collection of hazardous waste',
      },
    ],
  },
  WASTE_TREATMENT_AND_DISPOSAL: {
    label: '382 Waste treatment and disposal',
    children: [
      {
        value: '_3821_TREATMENT_AND_DISPOSAL_OF_NON_HAZARDOUS_WASTE',
        label: '3821 Treatment and disposal of non-hazardous waste',
      },
      {
        value: '_3822_TREATMENT_AND_DISPOSAL_OF_HAZARDOUS_WASTE',
        label: '3822 Treatment and disposal of hazardous waste',
      },
    ],
  },
  MATERIALS_RECOVERY: {
    label: '383 Materials recovery',
    children: [
      {
        value: '_3831_DISMANTLING_OF_WRECKS',
        label: '3831 Dismantling of wrecks',
      },
      {
        value: '_3832_RECOVERY_OF_SORTED_MATERIALS',
        label: '3832 Recovery of sorted materials',
      },
    ],
  },
  REMEDIATION_ACTIVITIES_AND_OTHER_WASTE_MANAGEMENT_SERVICES: {
    value: '_390_REMEDIATION_ACTIVITIES_AND_OTHER_WASTE_MANAGEMENT_SERVICES',
    label: '390 Remediation activities and other waste management services',
  },
  DEVELOPMENT_OF_BUILDING_PROJECTS: {
    value: '_411_DEVELOPMENT_OF_BUILDING_PROJECTS',
    label: '411 Development of building projects',
  },
  CONSTRUCTION_OF_RESIDENTIAL_AND_NON_RESIDENTIAL_BUILDINGS: {
    value: '_412_CONSTRUCTION_OF_RESIDENTIAL_AND_NON_RESIDENTIAL_BUILDINGS',
    label: '412 Construction of residential and non-residential buildings',
  },
  CONSTRUCTION_OF_ROADS_AND_RAILWAYS: {
    label: '421 Construction of roads and railways',
    children: [
      {
        value: '_4211_CONSTRUCTION_OF_ROADS_AND_MOTORWAYS',
        label: '4211 Construction of roads and motorways',
      },
      {
        value: '_4212_CONSTRUCTION_OF_RAILWAYS_AND_UNDERGROUND_RAILWAYS',
        label: '4212 Construction of railways and underground railways',
      },
      {
        value: '_4213_CONSTRUCTION_OF_BRIDGES_AND_TUNNELS',
        label: '4213 Construction of bridges and tunnels',
      },
    ],
  },
  CONSTRUCTION_OF_UTILITY_PROJECTS: {
    label: '422 Construction of utility projects',
    children: [
      {
        value: '_4221_CONSTRUCTION_OF_UTILITY_PROJECTS_FOR_FLUIDS',
        label: '4221 Construction of utility projects for fluids',
      },
      {
        value: '_4222_CONSTRUCTION_OF_UTILITY_PROJECTS_FOR_ELECTRICITY_AND_TELECOMMUNICATIONS',
        label: '4222 Construction of utility projects for electricity and telecommunications',
      },
    ],
  },
  CONSTRUCTION_OF_OTHER_CIVIL_ENGINEERING_PROJECTS: {
    label: '429 Construction of other civil engineering projects',
    children: [
      {
        value: '_4291_CONSTRUCTION_OF_WATER_PROJECTS',
        label: '4291 Construction of water projects',
      },
      {
        value: '_4299_CONSTRUCTION_OF_OTHER_CIVIL_ENGINEERING_PROJECTS_N_E_C_',
        label: '4299 Construction of other civil engineering projects n.e.c.',
      },
    ],
  },
  DEMOLITION_AND_SITE_PREPARATION: {
    label: '431 Demolition and site preparation',
    children: [
      {
        value: '_4311_DEMOLITION',
        label: '4311 Demolition',
      },
      {
        value: '_4312_SITE_PREPARATION',
        label: '4312 Site preparation',
      },
      {
        value: '_4313_TEST_DRILLING_AND_BORING',
        label: '4313 Test drilling and boring',
      },
    ],
  },
  ELECTRICAL_PLUMBING_AND_OTHER_CONSTRUCTION_INSTALLATION_ACTIVITIES: {
    label: '432 Electrical, plumbing and other construction installation activities',
    children: [
      {
        value: '_4321_ELECTRICAL_INSTALLATION',
        label: '4321 Electrical installation',
      },
      {
        value: '_4322_PLUMBING_HEAT_AND_AIR_CONDITIONING_INSTALLATION',
        label: '4322 Plumbing, heat and air conditioning installation',
      },
      {
        value: '_4329_OTHER_CONSTRUCTION_INSTALLATION',
        label: '4329 Other construction installation',
      },
    ],
  },
  BUILDING_COMPLETION_AND_FINISHING: {
    label: '433 Building completion and finishing',
    children: [
      {
        value: '_4331_PLASTERING',
        label: '4331 Plastering',
      },
      {
        value: '_4332_JOINERY_INSTALLATION',
        label: '4332 Joinery installation',
      },
      {
        value: '_4333_FLOOR_AND_WALL_COVERING',
        label: '4333 Floor and wall covering',
      },
      {
        value: '_4334_PAINTING_AND_GLAZING',
        label: '4334 Painting and glazing',
      },
      {
        value: '_4339_OTHER_BUILDING_COMPLETION_AND_FINISHING',
        label: '4339 Other building completion and finishing',
      },
    ],
  },
  OTHER_SPECIALISED_CONSTRUCTION_ACTIVITIES: {
    label: '439 Other specialised construction activities',
    children: [
      {
        value: '_4391_ROOFING_ACTIVITIES',
        label: '4391 Roofing activities',
      },
      {
        value: '_4399_OTHER_SPECIALIZED_CONSTRUCTION_ACTIVITIES_N_E_C_',
        label: '4399 Other specialised construction activities n.e.c.',
      },
    ],
  },
  SALE_OF_MOTOR_VEHICLES: {
    label: '451 Sale of motor vehicles',
    children: [
      {
        value: '_4511_SALE_OF_CARS_AND_LIGHT_MOTOR_VEHICLES',
        label: '4511 Sale of cars and light motor vehicles',
      },
      {
        value: '_4519_SALE_OF_OTHER_MOTOR_VEHICLES',
        label: '4519 Sale of other motor vehicles',
      },
    ],
  },
  MAINTENANCE_AND_REPAIR_OF_MOTOR_VEHICLES: {
    value: '_452_MAINTENANCE_AND_REPAIR_OF_MOTOR_VEHICLES',
    label: '452 Maintenance and repair of motor vehicles',
  },
  SALE_OF_MOTOR_VEHICLE_PARTS_AND_ACCESSORIES: {
    label: '453 Sale of motor vehicle parts and accessories',
    children: [
      {
        value: '_4531_WHOLESOME_TRADE_OF_MOTOR_VEHICLE_PARTS_AND_ACCESSORIES',
        label: '4531 Wholesale trade of motor vehicle parts and accessories',
      },
      {
        value: '_4532_RETAIL_TRADE_OF_MOTOR_VEHICLE_PARTS_AND_ACCESSORIES',
        label: '4532 Retail trade of motor vehicle parts and accessories',
      },
    ],
  },
  SALE_MAINTENANCE_AND_REPAIR_OF_MOTORCYCLES_AND_RELATED_PARTS_AND_ACCESSORIES: {
    value: '_454_SALE_MAINTENANCE_AND_REPAIR_OF_MOTORCYCLES_AND_RELATED_PARTS_AND_ACCESSORIES',
    label: '454 Sale, maintenance and repair of motorcycles and related parts and accessories',
  },
  WHOLESALE_ON_AFEE_OR_CONTRACT_BASIS: {
    label: '461 Wholesale on a fee or contract basis',
    children: [
      {
        value:
          '_4611_AGENTS_INVOLVED_IN_THE_SALE_OF_AGRICULTURAL_RAW_MATERIALS_LIVE_ANIMALS_TEXTILE_RAW_MATERIALS_AND_SEMI_FINISHED_GOODS',
        label:
          '4611 Agents involved in the sale of agricultural raw materials, live animals, textile raw materials and semi-finished goods',
      },
      {
        value: '_4612_AGENTS_INVOLVED_IN_THE_SALE_OF_FUELS_ORES_METALS_AND_INDUSTRIAL_CHEMICALS',
        label: '4612 Agents involved in the sale of fuels, ores, metals and industrial chemicals',
      },
      {
        value: '_4613_AGENTS_INVOLVED_IN_THE_SALE_OF_TIMBER_AND_BUILDING_MATERIALS',
        label: '4613 Agents involved in the sale of timber and building materials',
      },
      {
        value: '_4614_AGENTS_INVOLVED_IN_THE_SALE_OF_MACHINERY_INDUSTRIAL_EQUIPMENT_SHIPS_AND_AIRCRAFT',
        label: '4614 Agents involved in the sale of machinery, industrial equipment, ships and aircraft',
      },
      {
        value: '_4615_AGENTS_INVOLVED_IN_THE_SALE_OF_FURNITURE_HOUSEHOLD_GOODS_HARDWARE_AND_IRONMONGERY',
        label: '4615 Agents involved in the sale of furniture, household goods, hardware and ironmongery',
      },
      {
        value: '_4616_AGENTS_INVOLVED_IN_THE_SALE_OF_TEXTILES_CLOTHING_FUR_FOOTWEAR_AND_LEATHER_GOODS',
        label: '4616 Agents involved in the sale of textiles, clothing, fur, footwear and leather goods',
      },
      {
        value: '_4617_AGENTS_INVOLVED_IN_THE_SALE_OF_FOOD_BEVERAGES_AND_TOBACCO',
        label: '4617 Agents involved in the sale of food, beverages and tobacco',
      },
      {
        value: '_4618_AGENTS_SPECIALISED_IN_THE_SALE_OF_OTHER_PARTICULAR_PRODUCTS',
        label: '4618 Agents specialised in the sale of other particular products',
      },
      {
        value: '_4619_AGENTS_INVOLVED_IN_THE_SALE_OF_A_VARIETY_OF_GOODS',
        label: '4619 Agents involved in the sale of a variety of goods',
      },
    ],
  },
  WHOLESALE_OF_AGRICULTURAL_RAW_MATERIALS_AND_LIVE_ANIMALS: {
    label: '462 Wholesale of agricultural raw materials and live animals',
    children: [
      {
        value: '_4621_WHOLESALE_OF_GRAIN_UNMANUFACTURED_TOBACCO_SEEDS_AND_ANIMAL_FEEDS',
        label: '4621 Wholesale of grain, unmanufactured tobacco, seeds and animal feeds',
      },
      {
        value: '_4622_WHOLESALE_OF_FLOWERS_AND_PLANTS',
        label: '4622 Wholesale of flowers and plants',
      },
      {
        value: '_4623_WHOLESALE_OF_LIVE_ANIMALS',
        label: '4623 Wholesale of live animals',
      },
      {
        value: '_4624_WHOLESALE_OF_HIDES_SKINS_AND_LEATHER',
        label: '4624 Wholesale of hides, skins and leather',
      },
    ],
  },
  WHOLESALE_OF_FOOD_BEVERAGES_AND_TOBACCO: {
    label: '463 Wholesale of food, beverages and tobacco',
    children: [
      {
        value: '_4631_WHOLESALE_OF_FRUIT_AND_VEGETABLES',
        label: '4631 Wholesale of fruit and vegetables',
      },
      {
        value: '_4632_WHOLESALE_OF_MEAT_AND_MEAT_PRODUCTS',
        label: '4632 Wholesale of meat and meat products',
      },
      {
        value: '_4633_WHOLESALE_OF_DAIRY_PRODUCTS_EGGS_AND_EDIBLE_OILS_AND_FATS',
        label: '4633 Wholesale of dairy products, eggs and edible oils and fats',
      },
      {
        value: '_4634_WHOLESALE_OF_BEVERAGES',
        label: '4634 Wholesale of beverages',
      },
      {
        value: '_4635_WHOLESALE_OF_TOBACCO_PRODUCTS',
        label: '4635 Wholesale of tobacco products',
      },
      {
        value: '_4636_WHOLESALE_OF_SUGAR_AND_CHOCOLATE_AND_SUGAR_CONFECTIONERY',
        label: '4636 Wholesale of sugar and chocolate and sugar confectionery',
      },
      {
        value: '_4637_WHOLESALE_OF_COFFEE_TEA_COCOA_AND_SPICES',
        label: '4637 Wholesale of coffee, tea, cocoa and spices',
      },
      {
        value: '_4638_WHOLESALE_OF_OTHER_FOOD_INCLUDING_FISH_CRUSTACEANS_AND_MOLLUSCS',
        label: '4638 Wholesale of other food, including fish, crustaceans and molluscs',
      },
      {
        value: '_4639_NON_SPECIALISED_WHOLESALE_OF_FOOD_BEVERAGES_AND_TOBACCO',
        label: '4639 Non-specialised wholesale of food, beverages and tobacco',
      },
    ],
  },
  WHOLESALE_OF_HOUSEHOLD_GOODS: {
    label: '464 Wholesale of household goods',
    children: [
      {
        value: '_4641_WHOLESALE_OF_TEXTILES',
        label: '4641 Wholesale of textiles',
      },
      {
        value: '_4642_WHOLESALE_OF_CLOTHING_AND_FOOTWEAR',
        label: '4642 Wholesale of clothing and footwear',
      },
      {
        value: '_4643_WHOLESALE_OF_ELECTRICAL_HOUSEHOLD_APPLIANCES',
        label: '4643 Wholesale of electrical household appliances',
      },
      {
        value: '_4644_WHOLESALE_OF_CHINA_AND_GLASSWARE_AND_CLEANING_MATERIALS',
        label: '4644 Wholesale of china and glassware and cleaning materials',
      },
      {
        value: '_4645_WHOLESALE_OF_PERFUME_AND_COSMETICS',
        label: '4645 Wholesale of perfume and cosmetics',
      },
      {
        value: '_4646_WHOLESALE_OF_PHARMACEUTICAL_GOODS',
        label: '4646 Wholesale of pharmaceutical goods',
      },
      {
        value: '_4647_WHOLESALE_OF_FURNITURE_CARPETS_AND_LIGHTING_EQUIPMENT',
        label: '4647 Wholesale of furniture, carpets and lighting equipment',
      },
      {
        value: '_4648_WHOLESALE_OF_WATCHES_AND_JEWELLERY',
        label: '4648 Wholesale of watches and jewellery',
      },
      {
        value: '_4649_WHOLESALE_OF_OTHER_HOUSEHOLD_GOODS',
        label: '4649 Wholesale of other household goods',
      },
    ],
  },
  WHOLESALE_OF_INFORMATION_AND_COMMUNICATION_EQUIPMENT: {
    label: '465 Wholesale of information and communication equipment',
    children: [
      {
        value: '_4651_WHOLESALE_OF_COMPUTERS_COMPUTER_PERIPHERAL_EQUIPMENT_AND_SOFTWARE',
        label: '4651 Wholesale of computers, computer peripheral equipment and software',
      },
      {
        value: '_4652_WHOLESALE_OF_ELECTRONIC_AND_TELECOMMUNICATIONS_EQUIPMENT_AND_PARTS',
        label: '4652 Wholesale of electronic and telecommunications equipment and parts',
      },
    ],
  },
  WHOLESALE_OF_OTHER_MACHINERY_EQUIPMENT_AND_SUPPLIES: {
    label: '466 Wholesale of other machinery, equipment and supplies',
    children: [
      {
        value: '_4661_WHOLESALE_OF_AGRICULTURAL_MACHINERY_EQUIPMENT_AND_SUPPLIES',
        label: '4661 Wholesale of agricultural machinery, equipment and supplies',
      },
      {
        value: '_4662_WHOLESALE_OF_MACHINE_TOOLS',
        label: '4662 Wholesale of machine tools',
      },
      {
        value: '_4663_WHOLESALE_OF_MINING_CONSTRUCTION_AND_CIVIL_ENGINEERING_MACHINERY',
        label: '4663 Wholesale of mining, construction and civil engineering machinery',
      },
      {
        value: '_4664_WHOLESALE_OF_MACHINERY_FOR_THE_TEXTILE_INDUSTRY_AND_OF_SEWING_AND_KNITTING_MACHINES',
        label: '4664 Wholesale of machinery for the textile industry and of sewing and knitting machines',
      },
      {
        value: '_4665_WHOLESALE_OF_OFFICE_FURNITURE',
        label: '4665 Wholesale of office furniture',
      },
      {
        value: '_4666_WHOLESALE_OF_OTHER_OFFICE_MACHINERY_AND_EQUIPMENT',
        label: '4666 Wholesale of other office machinery and equipment',
      },
      {
        value: '_4669_WHOLESALE_OF_OTHER_MACHINERY_AND_EQUIPMENT',
        label: '4669 Wholesale of other machinery and equipment',
      },
    ],
  },
  OTHER_SPECIALISED_WHOLESALE: {
    label: '467 Other specialised wholesale',
    children: [
      {
        value: '_4671_WHOLESALE_OF_SOLID_LIQUID_AND_GASEOUS_FUELS_AND_RELATED_PRODUCTS',
        label: '4671 Wholesale of solid, liquid and gaseous fuels and related products',
      },
      {
        value: '_4672_WHOLESALE_OF_METALS_AND_METAL_ORES',
        label: '4672 Wholesale of metals and metal ores',
      },
      {
        value: '_4673_WHOLESALE_OF_WOOD_CONSTRUCTION_MATERIALS_AND_SANITARY_EQUIPMENT',
        label: '4673 Wholesale of wood, construction materials and sanitary equipment',
      },
      {
        value: '_4674_WHOLESALE_OF_HARDWARE_PLUMBING_AND_HEATING_EQUIPMENT_AND_SUPPLIES',
        label: '4674 Wholesale of hardware, plumbing and heating equipment and supplies',
      },
      {
        value: '_4675_WHOLESALE_OF_CHEMICAL_PRODUCTS',
        label: '4675 Wholesale of chemical products',
      },
      {
        value: '_4676_WHOLESALE_OF_OTHER_INTERMEDIATE_PRODUCTS',
        label: '4676 Wholesale of other intermediate products',
      },
      {
        value: '_4677_WHOLESALE_OF_WASTE_AND_SCRAP',
        label: '4677 Wholesale of waste and scrap',
      },
    ],
  },
  NON_SPECIALISED_WHOLESALE_TRADE: {
    value: '_469_NON_SPECIALISED_WHOLESALE_TRADE',
    label: '469 Non-specialised wholesale trade',
  },
  RETAIL_SALE_IN_NON_SPECIALISED_STORES: {
    label: '471 Retail sale in non-specialised stores',
    children: [
      {
        value: '_4711_RETAIL_SALE_IN_NON_SPECIALISED_STORES_WITH_FOOD_BEVERAGES_OR_TOBACCO_PREDOMINATING',
        label: '4711 Retail sale in non-specialised stores with food, beverages or tobacco predominating',
      },
      {
        value: '_4719_OTHER_RETAIL_SALE_IN_NON_SPECIALISED_STORES',
        label: '4719 Other retail sale in non-specialised stores',
      },
    ],
  },
  RETAIL_SALE_OF_FOOD_BEVERAGES_AND_TOBACCO_IN_SPECIALISED_STORES: {
    label: '472 Retail sale of food, beverages and tobacco in specialised stores',
    children: [
      {
        value: '_4721_RETAIL_SALE_OF_FRUIT_AND_VEGETABLES_IN_SPECIALISED_STORES',
        label: '4721 Retail sale of fruit and vegetables in specialised stores',
      },
      {
        value: '_4722_RETAIL_SALE_OF_MEAT_AND_MEAT_PRODUCTS_IN_SPECIALISED_STORES',
        label: '4722 Retail sale of meat and meat products in specialised stores',
      },
      {
        value: '_4723_RETAIL_SALE_OF_FISH_CRUSTACEANS_AND_MOLLUSCS_IN_SPECIALISED_STORES',
        label: '4723 Retail sale of fish, crustaceans and molluscs in specialised stores',
      },
      {
        value: '_4724_RETAIL_SALE_OF_BREAD_CAKES_FLOUR_CONFECTIONERY_AND_SUGAR_CONFECTIONERY_IN_SPECIALISED_STORES',
        label: '4724 Retail sale of bread, cakes, flour confectionery and sugar confectionery in specialised stores',
      },
      {
        value: '_4725_RETAIL_SALE_OF_BEVERAGES_IN_SPECIALISED_STORES',
        label: '4725 Retail sale of beverages in specialised stores',
      },
      {
        value: '_4726_RETAIL_SALE_OF_TOBACCO_PRODUCTS_IN_SPECIALISED_STORES',
        label: '4726 Retail sale of tobacco products in specialised stores',
      },
      {
        value: '_4729_OTHER_RETAIL_SALE_OF_FOOD_IN_SPECIALISED_STORES',
        label: '4729 Other retail sale of food in specialised stores',
      },
    ],
  },
  RETAIL_SALE_OF_AUTOMOTIVE_FUEL_IN_SPECIALISED_STORES: {
    value: '_473_RETAIL_SALE_OF_AUTOMOTIVE_FUEL_IN_SPECIALISED_STORES',
    label: '473 Retail sale of automotive fuel in specialised stores',
  },
  RETAIL_SALE_OF_INFORMATION_AND_COMMUNICATION_EQUIPMENT_IN_SPECIALISED_STORES: {
    label: '474 Retail sale of information and communication equipment in specialised stores',
    children: [
      {
        value: '_4741_RETAIL_SALE_OF_COMPUTERS_PERIPHERAL_UNITS_AND_SOFTWARE_IN_SPECIALISED_STORES',
        label: '4741 Retail sale of computers, peripheral units and software in specialised stores',
      },
      {
        value: '_4742_RETAIL_SALE_OF_TELECOMMUNICATIONS_EQUIPMENT_IN_SPECIALISED_STORES',
        label: '4742 Retail sale of telecommunications equipment in specialised stores',
      },
      {
        value: '_4743_RETAIL_SALE_OF_AUDIO_AND_VIDEO_EQUIPMENT_IN_SPECIALISED_STORES',
        label: '4743 Retail sale of audio and video equipment in specialised stores',
      },
    ],
  },
  RETAIL_SALE_OF_OTHER_HOUSEHOLD_EQUIPMENT_IN_SPECIALISED_STORES: {
    label: '475 Retail sale of other household equipment in specialised stores',
    children: [
      {
        value: '_4751_RETAIL_SALE_OF_TEXTILES_IN_SPECIALISED_STORES',
        label: '4751 Retail sale of textiles in specialised stores',
      },
      {
        value: '_4752_RETAIL_SALE_OF_HARDWARE_PAINTS_AND_GLASS_IN_SPECIALISED_STORES',
        label: '4752 Retail sale of hardware, paints and glass in specialised stores',
      },
      {
        value: '_4753_RETAIL_SALE_OF_CARPETS_RUGS_WALL_AND_FLOOR_COVERINGS_IN_SPECIALISED_STORES',
        label: '4753 Retail sale of carpets, rugs, wall and floor coverings in specialised stores',
      },
      {
        value: '_4754_RETAIL_SALE_OF_ELECTRICAL_HOUSEHOLD_APPLIANCES_IN_SPECIALISED_STORES',
        label: '4754 Retail sale of electrical household appliances in specialised stores',
      },
      {
        value: '_4759_RETAIL_SALE_OF_FURNITURE_LIGHTING_EQUIPMENT_AND_OTHER_HOUSEHOLD_ARTICLES_IN_SPECIALISED_STORES',
        label: '4759 Retail sale of furniture, lighting equipment and other household articles in specialised stores',
      },
    ],
  },
  RETAIL_SALE_OF_CULTURAL_AND_RECREATION_GOODS_IN_SPECIALISED_STORES: {
    label: '476 Retail sale of cultural and recreation goods in specialised stores',
    children: [
      {
        value: '_4761_RETAIL_SALE_OF_BOOKS_IN_SPECIALISED_STORES',
        label: '4761 Retail sale of books in specialised stores',
      },
      {
        value: '_4762_RETAIL_SALE_OF_NEWSPAPERS_AND_STATIONERY_IN_SPECIALISED_STORES',
        label: '4762 Retail sale of newspapers and stationery in specialised stores',
      },
      {
        value: '_4763_RETAIL_SALE_OF_MUSIC_AND_VIDEO_RECORDINGS_IN_SPECIALISED_STORES',
        label: '4763 Retail sale of music and video recordings in specialised stores',
      },
      {
        value: '_4764_RETAIL_SALE_OF_SPORTING_EQUIPMENT_IN_SPECIALISED_STORES',
        label: '4764 Retail sale of sporting equipment in specialised stores',
      },
      {
        value: '_4765_RETAIL_SALE_OF_GAMES_AND_TOYS_IN_SPECIALISED_STORES',
        label: '4765 Retail sale of games and toys in specialised stores',
      },
    ],
  },
  RETAIL_SALE_OF_OTHER_GOODS_IN_SPECIALISED_STORES: {
    label: '477 Retail sale of other goods in specialised stores',
    children: [
      {
        value: '_4771_RETAIL_SALE_OF_CLOTHING_IN_SPECIALISED_STORES',
        label: '4771 Retail sale of clothing in specialised stores',
      },
      {
        value: '_4772_RETAIL_SALE_OF_FOOTWEAR_AND_LEATHER_GOODS_IN_SPECIALISED_STORES',
        label: '4772 Retail sale of footwear and leather goods in specialised stores',
      },
      {
        value: '_4773_DISPENSING_CHEMIST_IN_SPECIALISED_STORES',
        label: '4773 Dispensing chemist in specialised stores',
      },
      {
        value: '_4774_RETAIL_SALE_OF_MEDICAL_AND_ORTHOPAEDIC_GOODS_IN_SPECIALISED_STORES',
        label: '4774 Retail sale of medical and orthopaedic goods in specialised stores',
      },
      {
        value: '_4775_RETAIL_SALE_OF_COSMETIC_AND_TOILET_ARTICLES_IN_SPECIALISED_STORES',
        label: '4775 Retail sale of cosmetic and toilet articles in specialised stores',
      },
      {
        value: '_4776_RETAIL_SALE_OF_FLOWERS_PLANTS_SEEDS_FERTILISERS_PET_ANIMALS_AND_PET_FOOD_IN_SPECIALISED_STORES',
        label:
          '4776 Retail sale of flowers, plants, seeds, fertilisers, pet animals and pet food in specialised stores',
      },
      {
        value: '_4777_RETAIL_SALE_OF_WATCHES_AND_JEWELLERY_IN_SPECIALISED_STORES',
        label: '4777 Retail sale of watches and jewellery in specialised stores',
      },
      {
        value: '_4778_OTHER_RETAIL_SALE_OF_NEW_GOODS_IN_SPECIALISED_STORES',
        label: '4778 Other retail sale of new goods in specialised stores',
      },
      {
        value: '_4779_RETAIL_SALE_OF_SECOND_HAND_GOODS_IN_STORES',
        label: '4779 Retail sale of second-hand goods in stores',
      },
    ],
  },
  RETAIL_SALE_VIA_STALLS_AND_MARKETS: {
    label: '478 Retail sale via stalls and markets',
    children: [
      {
        value: '_4781_RETAIL_SALE_VIA_STALLS_AND_MARKETS_OF_FOOD_BEVERAGES_AND_TOBACCO_PRODUCTS',
        label: '4781 Retail sale via stalls and markets of food, beverages and tobacco products',
      },
      {
        value: '_4782_RETAIL_SALE_VIA_STALLS_AND_MARKETS_OF_TEXTILES_CLOTHING_AND_FOOTWEAR',
        label: '4782 Retail sale via stalls and markets of textiles, clothing and footwear',
      },
      {
        value: '_4789_RETAIL_SALE_VIA_STALLS_AND_MARKETS_OF_OTHER_GOODS',
        label: '4789 Retail sale via stalls and markets of other goods',
      },
    ],
  },
  RETAIL_TRADE_NOT_IN_STORES_STALLS_OR_MARKETS: {
    label: '479 Retail trade not in stores, stalls or markets',
    children: [
      {
        value: '_4791_RETAIL_SALE_VIA_MAIL_ORDER_HOUSES_OR_VIA_INTERNET',
        label: '4791 Retail sale via mail order houses or via Internet',
      },
      {
        value: '_4799_OTHER_RETAIL_SALE_NOT_IN_STORES_STALLS_OR_MARKETS',
        label: '4799 Other retail sale not in stores, stalls or markets',
      },
    ],
  },
  PASSENGER_RAIL_TRANSPORT_INTERURBAN: {
    value: '_491_PASSENGER_RAIL_TRANSPORT_INTERURBAN',
    label: '491 Passenger rail transport, interurban',
  },
  FREIGHT_RAIL_TRANSPORT: {
    value: '_492_FREIGHT_RAIL_TRANSPORT',
    label: '492 Freight rail transport',
  },
  OTHER_PASSENGER_LAND_TRANSPORT: {
    label: '493 Other passenger land transport',
    children: [
      {
        value: '_4931_URBAN_AND_SUBURBAN_PASSENGER_LAND_TRANSPORT',
        label: '4931 Urban and suburban passenger land transport',
      },
      {
        value: '_4932_TAXI_OPERATION',
        label: '4932 Taxi operation',
      },
      {
        value: '_4939_OTHER_PASSENGER_LAND_TRANSPORT_N_E_C_',
        label: '4939 Other passenger land transport n.e.c.',
      },
    ],
  },
  FREIGHT_TRANSPORT_BY_ROAD_AND_REMOVAL_SERVICES: {
    label: '494 Freight transport by road and removal services',
    children: [
      {
        value: '_4941_FREIGHT_TRANSPORT_BY_ROAD',
        label: '4941 Freight transport by road',
      },
      {
        value: '_4942_REMOVAL_SERVICES',
        label: '4942 Removal services',
      },
    ],
  },
  TRANSPORT_VIA_PIPELINE: {
    value: '_495_TRANSPORT_VIA_PIPELINE',
    label: '495 Transport via pipeline',
  },
  SEA_AND_COASTAL_PASSENGER_WATER_TRANSPORT: {
    value: '_501_SEA_AND_COASTAL_PASSENGER_WATER_TRANSPORT',
    label: '501 Sea and coastal passenger water transport',
  },
  SEA_AND_COASTAL_FREIGHT_WATER_TRANSPORT: {
    value: '_502_SEA_AND_COASTAL_FREIGHT_WATER_TRANSPORT',
    label: '502 Sea and coastal freight water transport',
  },
  INLAND_PASSENGER_WATER_TRANSPORT: {
    value: '_503_INLAND_PASSENGER_WATER_TRANSPORT',
    label: '503 Inland passenger water transport',
  },
  INLAND_FREIGHT_WATER_TRANSPORT: {
    value: '_504_INLAND_FREIGHT_WATER_TRANSPORT',
    label: '504 Inland freight water transport',
  },
  PASSENGER_AIR_TRANSPORT: {
    value: '_511_PASSENGER_AIR_TRANSPORT',
    label: '511 Passenger air transport',
  },
  FREIGHT_AIR_TRANSPORT_AND_SPACE_TRANSPORT: {
    label: '512 Freight air transport and space transport',
    children: [
      {
        value: '_5121_FREIGHT_AIR_TRANSPORT',
        label: '5121 Freight air transport',
      },
      {
        value: '_5122_SPACE_TRANSPORT',
        label: '5122 Space transport',
      },
    ],
  },
  WAREHOUSING_AND_STORAGE: {
    value: '_521_WAREHOUSING_AND_STORAGE',
    label: '521 Warehousing and storage',
  },
  SUPPORT_ACTIVITIES_FOR_TRANSPORTATION: {
    label: '522 Support activities for transportation',
    children: [
      {
        value: '_5221_SERVICE_ACTIVITIES_INCIDENTAL_TO_LAND_TRANSPORTATION',
        label: '5221 Service activities incidental to land transportation',
      },
      {
        value: '_5222_SERVICE_ACTIVITIES_INCIDENTAL_TO_WATER_TRANSPORTATION',
        label: '5222 Service activities incidental to water transportation',
      },
      {
        value: '_5223_SERVICE_ACTIVITIES_INCIDENTAL_TO_AIR_TRANSPORTATION',
        label: '5223 Service activities incidental to air transportation',
      },
      {
        value: '_5224_CARGO_HANDLING',
        label: '5224 Cargo handling',
      },
      {
        value: '_5229_OTHER_TRANSPORTATION_SUPPORT_ACTIVITIES',
        label: '5229 Other transportation support activities',
      },
    ],
  },
  POSTAL_ACTIVITIES_UNDER_UNIVERSAL_SERVICE_OBLIGATION: {
    value: '_531_POSTAL_ACTIVITIES_UNDER_UNIVERSAL_SERVICE_OBLIGATION',
    label: '531 Postal activities under universal service obligation',
  },
  OTHER_POSTAL_AND_COURIER_ACTIVITIES: {
    value: '_532_OTHER_POSTAL_AND_COURIER_ACTIVITIES',
    label: '532 Other postal and courier activities',
  },
  HOTELS_AND_SIMILAR_ACCOMMODATION: {
    value: '_551_HOTELS_AND_SIMILAR_ACCOMMODATION',
    label: '551 Hotels and similar accommodation',
  },
  HOLIDAY_AND_OTHER_SHORT_STAY_ACCOMMODATION: {
    value: '_552_HOLIDAY_AND_OTHER_SHORT_STAY_ACCOMMODATION',
    label: '552 Holiday and other short-stay accommodation',
  },
  CAMPING_GROUNDS_RECREATIONAL_VEHICLE_PARKS_AND_TRAILER_PARKS: {
    value: '_553_CAMPING_GROUNDS_RECREATIONAL_VEHICLE_PARKS_AND_TRAILER_PARKS',
    label: '553 Camping grounds, recreational vehicle parks and trailer parks',
  },
  OTHER_ACCOMMODATION: {
    value: '_559_OTHER_ACCOMMODATION',
    label: '559 Other accommodation',
  },
  RESTAURANTS_AND_MOBILE_FOOD_SERVICE_ACTIVITIES: {
    value: '_561_RESTAURANTS_AND_MOBILE_FOOD_SERVICE_ACTIVITIES',
    label: '561 Restaurants and mobile food service activities',
  },
  EVENT_CATERING_AND_OTHER_FOOD_SERVICE_ACTIVITIES: {
    label: '562 Event catering and other food service activities',
    children: [
      {
        value: '_5621_EVENT_CATERING_ACTIVITIES',
        label: '5621 Event catering activities',
      },
      {
        value: '_5629_OTHER_FOOD_SERVICE_ACTIVITIES',
        label: '5629 Other food service activities',
      },
    ],
  },
  BEVERAGE_SERVING_ACTIVITIES: {
    value: '_563_BEVERAGE_SERVING_ACTIVITIES',
    label: '563 Beverage serving activities',
  },
  PUBLISHING_OF_BOOKS_PERIODICALS_AND_OTHER_PUBLISHING_ACTIVITIES: {
    label: '581 Publishing of books, periodicals and other publishing activities',
    children: [
      {
        value: '_5811_BOOK_PUBLISHING',
        label: '5811 Book publishing',
      },
      {
        value: '_5812_PUBLISHING_OF_DIRECTORIES_AND_MAILING_LISTS',
        label: '5812 Publishing of directories and mailing lists',
      },
      {
        value: '_5813_PUBLISHING_OF_NEWSPAPERS',
        label: '5813 Publishing of newspapers',
      },
      {
        value: '_5814_PUBLISHING_OF_JOURNALS_AND_PERIODICALS',
        label: '5814 Publishing of journals and periodicals',
      },
      {
        value: '_5819_OTHER_PUBLISHING_ACTIVITIES',
        label: '5819 Other publishing activities',
      },
    ],
  },
  SOFTWARE_PUBLISHING: {
    label: '582 Software publishing',
    children: [
      {
        value: '_5821_PUBLISHING_OF_COMPUTER_GAMES',
        label: '5821 Publishing of computer games',
      },
      {
        value: '_5829_OTHER_SOFTWARE_PUBLISHING',
        label: '5829 Other software publishing',
      },
    ],
  },
  MOTION_PICTURE_VIDEO_AND_TELEVISION_PROGRAMME_ACTIVITIES: {
    label: '591 Motion picture, video and television programme activities',
    children: [
      {
        value: '_5911_MOTION_PICTURE_VIDEO_AND_TELEVISION_PROGRAMME_PRODUCTION_ACTIVITIES',
        label: '5911 Motion picture, video and television programme production activities',
      },
      {
        value: '_5912_MOTION_PICTURE_VIDEO_AND_TELEVISION_PROGRAMME_POST_PRODUCTION_ACTIVITIES',
        label: '5912 Motion picture, video and television programme post-production activities',
      },
      {
        value: '_5913_MOTION_PICTURE_VIDEO_AND_TELEVISION_PROGRAMME_DISTRIBUTION_ACTIVITIES',
        label: '5913 Motion picture, video and television programme distribution activities',
      },
      {
        value: '_5914_MOTION_PICTURE_PROJECTION_ACTIVITIES',
        label: '5914 Motion picture projection activities',
      },
    ],
  },
  SOUND_RECORDING_AND_MUSIC_PUBLISHING_ACTIVITIES: {
    value: '_592_SOUND_RECORDING_AND_MUSIC_PUBLISHING_ACTIVITIES',
    label: '592 sound recording and music publishing activities',
  },
  RADIO_BROADCASTING: {
    value: '_601_RADIO_BROADCASTING',
    label: '601 Radio broadcasting',
  },
  TELEVISION_PROGRAMMING_AND_BROADCASTING_ACTIVITIES: {
    value: '_602_TELEVISION_PROGRAMMING_AND_BROADCASTING_ACTIVITIES',
    label: '602 Television programming and broadcasting activities',
  },
  WIRED_TELECOMMUNICATIONS_ACTIVITIES: {
    value: '_611_WIRED_TELECOMMUNICATIONS_ACTIVITIES',
    label: '611 Wired telecommunications activities',
  },
  WIRELESS_TELECOMMUNICATIONS_ACTIVITIES: {
    value: '_612_WIRELESS_TELECOMMUNICATIONS_ACTIVITIES',
    label: '612 Wireless telecommunications activities',
  },
  SATELLITE_TELECOMMUNICATIONS_ACTIVITIES: {
    value: '_613_SATELLITE_TELECOMMUNICATIONS_ACTIVITIES',
    label: '613 Satellite telecommunications activities',
  },
  OTHER_TELECOMMUNICATIONS_ACTIVITIES: {
    value: '_619_OTHER_TELECOMMUNICATIONS_ACTIVITIES',
    label: '619 Other telecommunications activities',
  },
  COMPUTER_PROGRAMMING_CONSULTANCY_AND_RELATED_ACTIVITIES: {
    label: '620 Computer programming, consultancy and related activities',
    children: [
      {
        value: '_6201_COMPUTER_PROGRAMMING_ACTIVITIES',
        label: '6201 Computer programming activities',
      },
      {
        value: '_6202_COMPUTER_CONSULTANCY_ACTIVITIES',
        label: '6202 Computer consultancy activities',
      },
      {
        value: '_6203_COMPUTER_FACILITIES_MANAGEMENT_ACTIVITIES',
        label: '6203 Computer facilities management activities',
      },
      {
        value: '_6209_OTHER_INFORMATION_TECHNOLOGY_AND_COMPUTER_SERVICE_ACTIVITIES',
        label: '6209 Other information technology and computer service activities',
      },
    ],
  },
  DATA_PROCESSING_HOSTING_AND_RELATED_ACTIVITIES_WEB_PORTALS: {
    label: '631 Data processing, hosting and related activities; web portals',
    children: [
      {
        value: '_6311_DATA_PROCESSING_HOSTING_AND_RELATED_ACTIVITIES',
        label: '6311 Data processing, hosting and related activities',
      },
      {
        value: '_6312_WEB_PORTALS',
        label: '6312 Web portals',
      },
    ],
  },
  OTHER_INFORMATION_SERVICE_ACTIVITIES: {
    label: '639 Other information service activities',
    children: [
      {
        value: '_6391_NEWS_AGENCY_ACTIVITIES',
        label: '6391 News agency activities',
      },
      {
        value: '_6399_OTHER_INFORMATION_SERVICE_ACTIVITIES_N_E_C_',
        label: '6399 Other information service activities n.e.c.',
      },
    ],
  },
  MONETARY_INTERMEDIATION: {
    label: '641 Monetary intermediation',
    children: [
      {
        value: '_6411_CENTRAL_BANKING',
        label: '6411 Central banking',
      },
      {
        value: '_6419_OTHER_MONETARY_INTERMEDIATION',
        label: '6419 Other monetary intermediation',
      },
    ],
  },
  ACTIVITIES_OF_HOLDING_COMPANIES: {
    value: '_642_ACTIVITIES_OF_HOLDING_COMPANIES',
    label: '642 Activities of holding companies',
  },
  TRUSTS_FUNDS_AND_SIMILAR_FINANCIAL_ENTITIES: {
    value: '_643_TRUSTS_FUNDS_AND_SIMILAR_FINANCIAL_ENTITIES',
    label: '643 Trusts, funds and similar financial entities',
  },
  OTHER_FINANCIAL_SERVICE_ACTIVITIES_EXCEPT_INSURANCE_AND_PENSION_FUNDING: {
    label: '649 Other financial service activities, except insurance and pension funding',
    children: [
      {
        value: '_6491_FINANCIAL_LEASING',
        label: '6491 Financial leasing',
      },
      {
        value: '_6492_OTHER_CREDIT_GRANTING',
        label: '6492 Other credit granting',
      },
      {
        value: '_6499_OTHER_FINANCIAL_SERVICE_ACTIVITIES_EXCEPT_INSURANCE_AND_PENSION_FUNDING_N_E_C_',
        label: '6499 Other financial service activities, except insurance and pension funding n.e.c.',
      },
    ],
  },
  INSURANCE: {
    label: '651 Insurance',
    children: [
      {
        value: '_6511_LIFE_INSURANCE',
        label: '6511 Life insurance',
      },
      {
        value: '_6512_NON_LIFE_INSURANCE',
        label: '6512 Non-life insurance',
      },
    ],
  },
  REINSURANCE: {
    value: '_652_REINSURANCE',
    label: '652 Reinsurance',
  },
  PENSION_FUNDING: {
    value: '_653_PENSION_FUNDING',
    label: '653 Pension funding',
  },
  ACTIVITIES_AUXILIARY_TO_FINANCIAL_SERVICES_EXCEPT_INSURANCE_AND_PENSION_FUNDING: {
    label: '661 Activities auxiliary to financial services, except insurance and pension funding',
    children: [
      {
        value: '_6611_ADMINISTRATION_OF_FINANCIAL_MARKETS',
        label: '6611 Administration of financial markets',
      },
      {
        value: '_6612_SECURITY_AND_COMMODITY_CONTRACTS_BROKERAGE',
        label: '6612 Security and commodity contracts brokerage',
      },
      {
        value: '_6619_OTHER_ACTIVITIES_AUXILIARY_TO_FINANCIAL_SERVICES_EXCEPT_INSURANCE_AND_PENSION_FUNDING',
        label: '6619 Other activities auxiliary to financial services, except insurance and pension funding',
      },
    ],
  },
  ACTIVITIES_AUXILIARY_TO_INSURANCE_AND_PENSION_FUNDING: {
    label: '662 Activities auxiliary to insurance and pension funding',
    children: [
      {
        value: '_6621_RISK_AND_DAMAGE_EVALUATION',
        label: '6621 Risk and damage evaluation',
      },
      {
        value: '_6622_ACTIVITIES_OF_INSURANCE_AGENTS_AND_BROKERS',
        label: '6622 Activities of insurance agents and brokers',
      },
      {
        value: '_6629_OTHER_ACTIVITIES_AUXILIARY_TO_INSURANCE_AND_PENSION_FUNDING',
        label: '6629 Other activities auxiliary to insurance and pension funding',
      },
    ],
  },
  FUND_MANAGEMENT_ACTIVITIES: {
    value: '_663_FUND_MANAGEMENT_ACTIVITIES',
    label: '663 Fund management activities',
  },
  BUYING_AND_SELLING_OF_OWN_REAL_ESTATE: {
    value: '_681_BUYING_AND_SELLING_OF_OWN_REAL_ESTATE',
    label: '681 Buying and selling of own real estate',
  },
  RENTING_AND_OPERATING_OF_OWN_OR_LEASED_REAL_ESTATE: {
    value: '_682_RENTING_AND_OPERATING_OF_OWN_OR_LEASED_REAL_ESTATE',
    label: '682 Renting and operating of own or leased real estate',
  },
  REAL_ESTATE_ACTIVITIES_ON_AFEE_OR_CONTRACT_BASIS: {
    label: '683 Real estate activities on a fee or contract basis',
    children: [
      {
        value: '_6831_REAL_ESTATE_AGENCIES',
        label: '6831 Real estate agencies',
      },
      {
        value: '_6832_MANAGEMENT_OF_REAL_ESTATE_ON_A_FEE_OR_CONTRACT_BASIS',
        label: '6832 Management of real estate on a fee or contract basis',
      },
    ],
  },
  LEGAL_ACTIVITIES: {
    value: '_691_LEGAL_ACTIVITIES',
    label: '691 Legal activities',
  },
  ACCOUNTING_BOOKKEEPING_AND_AUDITING_ACTIVITIES_TAX_CONSULTANCY: {
    value: '_692_ACCOUNTING_BOOKKEEPING_AND_AUDITING_ACTIVITIES_TAX_CONSULTANCY',
    label: '692 Accounting, bookkeeping and auditing activities; tax consultancy',
  },
  ACTIVITIES_OF_HEAD_OFFICES: {
    value: '_701_ACTIVITIES_OF_HEAD_OFFICES',
    label: '701 Activities of head offices',
  },
  MANAGEMENT_CONSULTANCY_ACTIVITIES: {
    label: '702 Management consultancy activities',
    children: [
      {
        value: '_7021_PUBLIC_RELATIONS_AND_COMMUNICATION_ACTIVITIES',
        label: '7021 Public relations and communication activities',
      },
      {
        value: '_7022_BUSINESS_AND_OTHER_MANAGEMENT_CONSULTANCY_ACTIVITIES',
        label: '7022 Business and other management consultancy activities',
      },
    ],
  },
  ARCHITECTURAL_AND_ENGINEERING_ACTIVITIES_AND_RELATED_TECHNICAL_CONSULTANCY: {
    label: '711 Architectural and engineering activities and related technical consultancy',
    children: [
      {
        value: '_7111_ARCHITECTURAL_ACTIVITIES',
        label: '7111 Architectural activities',
      },
      {
        value: '_7112_ENGINEERING_ACTIVITIES_AND_RELATED_TECHNICAL_CONSULTANCY',
        label: '7112 Engineering activities and related technical consultancy',
      },
    ],
  },
  TECHNICAL_TESTING_AND_ANALYSIS: {
    value: '_712_TECHNICAL_TESTING_AND_ANALYSIS',
    label: '712 Technical testing and analysis',
  },
  RESEARCH_AND_EXPERIMENTAL_DEVELOPMENT_ON_NATURAL_SCIENCES_AND_ENGINEERING: {
    label: '721 Research and experimental development on natural sciences and engineering',
    children: [
      {
        value: '_7211_RESEARCH_AND_EXPERIMENTAL_DEVELOPMENT_ON_BIOTECHNOLOGY',
        label: '7211 Research and experimental development on biotechnology',
      },
      {
        value: '_7219_OTHER_RESEARCH_AND_EXPERIMENTAL_DEVELOPMENT_ON_NATURAL_SCIENCES_AND_ENGINEERING',
        label: '7219 Other research and experimental development on natural sciences and engineering',
      },
    ],
  },
  RESEARCH_AND_EXPERIMENTAL_DEVELOPMENT_ON_SOCIAL_SCIENCES_AND_HUMANITIES: {
    value: '_722_RESEARCH_AND_EXPERIMENTAL_DEVELOPMENT_ON_SOCIAL_SCIENCES_AND_HUMANITIES',
    label: '722 Research and experimental development on social sciences and humanities',
  },
  ADVERTISING: {
    label: '731 Advertising',
    children: [
      {
        value: '_7311_ADVERTISING_AGENCIES',
        label: '7311 Advertising agencies',
      },
      {
        value: '_7312_MEDIA_REPRESENTATION',
        label: '7312 Media representation',
      },
    ],
  },
  MARKET_RESEARCH_AND_PUBLIC_OPINION_POLLING: {
    value: '_732_MARKET_RESEARCH_AND_PUBLIC_OPINION_POLLING',
    label: '732 Market research and public opinion polling',
  },
  SPECIALISED_DESIGN_ACTIVITIES: {
    value: '_741_SPECIALISED_DESIGN_ACTIVITIES',
    label: '741 Specialised design activities',
  },
  PHOTOGRAPHIC_ACTIVITIES: {
    value: '_742_PHOTOGRAPHIC_ACTIVITIES',
    label: '742 Photographic activities',
  },
  TRANSLATION_AND_INTERPRETATION_ACTIVITIES: {
    value: '_743_TRANSLATION_AND_INTERPRETATION_ACTIVITIES',
    label: '743 Translation and interpretation activities',
  },
  OTHER_PROFESSIONAL_SCIENTIFIC_AND_TECHNICAL_ACTIVITIES_NEC: {
    value: '_749_OTHER_PROFESSIONAL_SCIENTIFIC_AND_TECHNICAL_ACTIVITIES_N_E_C_',
    label: '749 Other professional, scientific and technical activities n.e.c.',
  },
  VETERINARY_ACTIVITIES: {
    value: '_750_VETERINARY_ACTIVITIES',
    label: '750 Veterinary activities',
  },
  RENTING_AND_LEASING_OF_MOTOR_VEHICLES: {
    label: '771 Renting and leasing of motor vehicles',
    children: [
      {
        value: '_7711_RENTING_AND_LEASING_OF_CARS_AND_LIGHT_MOTOR_VEHICLES',
        label: '7711 Renting and leasing of cars and light motor vehicles',
      },
      {
        value: '_7712_RENTING_AND_LEASING_OF_TRUCKS',
        label: '7712 Renting and leasing of trucks',
      },
    ],
  },
  RENTING_AND_LEASING_OF_PERSONAL_AND_HOUSEHOLD_GOODS: {
    label: '772 Renting and leasing of personal and household goods',
    children: [
      {
        value: '_7721_RENTING_AND_LEASING_OF_RECREATIONAL_AND_SPORTS_GOODS',
        label: '7721 Renting and leasing of recreational and sports goods',
      },
      {
        value: '_7722_RENTING_OF_VIDEO_TAPES_AND_DISKS',
        label: '7722 Renting of video taped and discs',
      },
      {
        value: '_7729_RENTING_AND_LEASING_OF_OTHER_PERSONAL_AND_HOUSEHOLD_GOODS',
        label: '7729 Renting and leasing of other personal and household goods',
      },
    ],
  },
  RENTING_AND_LEASING_OF_OTHER_MACHINERY_EQUIPMENT_AND_TANGIBLE_GOODS: {
    label: '773 Renting and leasing of other machinery, equipment and tangible goods',
    children: [
      {
        value: '_7731_RENTING_AND_LEASING_OF_AGRICULTURAL_MACHINERY_AND_EQUIPMENT',
        label: '7731 Renting and leasing of agricultural machinery and equipment',
      },
      {
        value: '_7732_RENTING_AND_LEASING_OF_CONSTRUCTION_AND_CIVIL_ENGINEERING_MACHINERY_AND_EQUIPMENT',
        label: '7732 Renting and leasing of construction and civil engineering machinery and equipment',
      },
      {
        value: '_7733_RENTING_AND_LEASING_OF_OFFICE_MACHINERY_AND_EQUIPMENT_INCLUDING_COMPUTERS',
        label: '7733 Renting and leasing of office machinery and equipment (including computers)',
      },
      {
        value: '_7734_RENTING_AND_LEASING_OF_WATER_TRANSPORT_EQUIPMENT',
        label: '7734 Renting and leasing of water transport equipment',
      },
      {
        value: '_7735_RENTING_AND_LEASING_OF_AIR_TRANSPORT_EQUIPMENT',
        label: '7735 Renting and leasing of air transport equipment',
      },
      {
        value: '_7739_RENTING_AND_LEASING_OF_OTHER_MACHINERY_EQUIPMENT_AND_TANGIBLE_GOODS_N_E_C_',
        label: '7739 Renting and leasing of other machinery, equipment and tangible goods n.e.c.',
      },
    ],
  },
  LEASING_OF_INTELLECTUAL_PROPERTY_AND_SIMILAR_PRODUCTS_EXCEPT_COPYRIGHTED_WORKS: {
    value: '_774_LEASING_OF_INTELLECTUAL_PROPERTY_AND_SIMILAR_PRODUCTS_EXCEPT_COPYRIGHTED_WORKS',
    label: '774 Leasing of intellectual property and similar products, except copyrighted works',
  },
  ACTIVITIES_OF_EMPLOYMENT_PLACEMENT_AGENCIES: {
    value: '_781_ACTIVITIES_OF_EMPLOYMENT_PLACEMENT_AGENCIES',
    label: '781 Activities of employment placement agencies',
  },
  TEMPORARY_EMPLOYMENT_AGENCY_ACTIVITIES: {
    value: '_782_TEMPORARY_EMPLOYMENT_AGENCY_ACTIVITIES',
    label: '782 Temporary employment agency activities',
  },
  OTHER_HUMAN_RESOURCES_PROVISION: {
    value: '_783_OTHER_HUMAN_RESOURCES_PROVISION',
    label: '783 Other human resources provision',
  },
  TRAVEL_AGENCY_AND_TOUR_OPERATOR_ACTIVITIES: {
    label: '791 Travel agency and tour operator activities',
    children: [
      {
        value: '_7911_TRAVEL_AGENCY_ACTIVITIES',
        label: '7911 Travel agency activities',
      },
      {
        value: '_7912_TOUR_OPERATOR_ACTIVITIES',
        label: '7912 Tour operator activities',
      },
    ],
  },
  OTHER_RESERVATION_SERVICE_AND_RELATED_ACTIVITIES: {
    value: '_799_OTHER_RESERVATION_SERVICE_AND_RELATED_ACTIVITIES',
    label: '799 Other reservation service and related activities',
  },
  PRIVATE_SECURITY_ACTIVITIES: {
    value: '_801_PRIVATE_SECURITY_ACTIVITIES',
    label: '801 Private security activities',
  },
  SECURITY_SYSTEMS_SERVICE_ACTIVITIES: {
    value: '_802_SECURITY_SYSTEMS_SERVICE_ACTIVITIES',
    label: '802 Security systems service activities',
  },
  INVESTIGATION_ACTIVITIES: {
    value: '_803_INVESTIGATION_ACTIVITIES',
    label: '803 Investigation activities',
  },
  COMBINED_FACILITIES_SUPPORT_ACTIVITIES: {
    value: '_811_COMBINED_FACILITIES_SUPPORT_ACTIVITIES',
    label: '811 Combined facilities support activities',
  },
  CLEANING_ACTIVITIES: {
    label: '812 Cleaning activities',
    children: [
      {
        value: '_8121_GENERAL_CLEANING_OF_BUILDINGS',
        label: '8121 General cleaning of buildings',
      },
      {
        value: '_8122_OTHER_BUILDING_AND_INDUSTRIAL_CLEANING_ACTIVITIES',
        label: '8122 Other building and industrial cleaning activities',
      },
      {
        value: '_8129_OTHER_CLEANING_ACTIVITIES',
        label: '8129 Other cleaning activities',
      },
    ],
  },
  LANDSCAPE_SERVICE_ACTIVITIES: {
    value: '_813_LANDSCAPE_SERVICE_ACTIVITIES',
    label: '813 Landscape service activities',
  },
  OFFICE_ADMINISTRATIVE_AND_SUPPORT_ACTIVITIES: {
    label: '821 Office administrative and support activities',
    children: [
      {
        value: '_8211_COMBINED_OFFICE_ADMINISTRATIVE_SERVICE_ACTIVITIES',
        label: '8211 Combined office administrative service activities',
      },
      {
        value: '_8219_PHOTOCOPYING_DOCUMENT_PREPARATION_AND_OTHER_SPECIALISED_OFFICE_SUPPORT_ACTIVITIES',
        label: '8219 Photocopying, document preparation and other specialised office support activities',
      },
    ],
  },
  ACTIVITIES_OF_CALL_CENTRES: {
    value: '_822_ACTIVITIES_OF_CALL_CENTRES',
    label: '822 Activities of call centres',
  },
  ORGANISATION_OF_CONVENTIONS_AND_TRADE_SHOWS: {
    value: '_829_ORGANISATION_OF_CONVENTIONS_AND_TRADE_SHOWS',
    label: '829 Organisation of conventions and trade shows',
  },
  BUSINESS_SUPPORT_SERVICE_ACTIVITIES_NEC: {
    label: '829 Business support service activities n.e.c.',
    children: [
      {
        value: '_8291_ACTIVITIES_OF_COLLECTION_AGENCIES_AND_CREDIT_BUREAUS',
        label: '8291 Activities of collection agencies and credit bureaus',
      },
      {
        value: '_8292_PACKAGING_ACTIVITIES',
        label: '8292 Packaging activities',
      },
      {
        value: '_8299_OTHER_BUSINESS_SUPPORT_SERVICE_ACTIVITIES_N_E_C_',
        label: '8299 Other business support service activities n.e.c.',
      },
    ],
  },
  ADMINISTRATION_OF_THE_STATE_AND_THE_ECONOMIC_AND_SOCIAL_POLICY_OF_THE_COMMUNITY: {
    label: '841 Administration of the State and the economic and social policy of the community',
    children: [
      {
        value: '_8411_GENERAL_PUBLIC_ADMINISTRATION_ACTIVITIES',
        label: '8411 General public administration activities',
      },
      {
        value:
          '_8412_REGULATION_OF_THE_ACTIVITIES_OF_PROVIDING_HEALTH_CARE_EDUCATION_CULTURAL_SERVICES_AND_OTHER_SOCIAL_SERVICES_EXCEPT_SOCIAL_SECURITY',
        label:
          '8412 Regulation of the activities of providing health care, education, cultural services and other social services, except social security',
      },
      {
        value: '_8413_REGULATION_OF_AND_CONTRIBUTION_TO_MORE_EFFICIENT_OPERATION_OF_BUSINESSES',
        label: '8413 Regulation of and contribution to more efficient operation of businesses',
      },
    ],
  },
  PROVISION_OF_SERVICES_TO_THE_COMMUNITY_AS_AWHOLE: {
    label: '842 Provision of services to the community as a whole',
    children: [
      {
        value: '_8421_FOREIGN_AFFAIRS',
        label: '8421 Foreign affairs',
      },
      {
        value: '_8422_DEFENCE_ACTIVITIES',
        label: '8422 Defence activities',
      },
      {
        value: '_8423_JUSTICE_AND_JUDICIAL_ACTIVITIES',
        label: '8423 Justice and judicial activities',
      },
      {
        value: '_8424_PUBLIC_ORDER_AND_SAFETY_ACTIVITIES',
        label: '8424 Public order and safety activities',
      },
      {
        value: '_8425_FIRE_SERVICE_ACTIVITIES',
        label: '8425 Fire service activities',
      },
    ],
  },
  COMPULSORY_SOCIAL_SECURITY_ACTIVITIES: {
    value: '_843_COMPULSORY_SOCIAL_SECURITY_ACTIVITIES',
    label: '843 Compulsory social security activities',
  },
  PRE_PRIMARY_EDUCATION: {
    value: '_851_PRE_PRIMARY_EDUCATION',
    label: '851 Pre-primary education',
  },
  PRIMARY_EDUCATION: {
    value: '_852_PRIMARY_EDUCATION',
    label: '852 Primary education',
  },
  SECONDARY_EDUCATION: {
    label: '853 Secondary education',
    children: [
      {
        value: '_8531_GENERAL_SECONDARY_EDUCATION',
        label: '8531 General secondary education',
      },
      {
        value: '_8532_TECHNICAL_AND_VOCATIONAL_SECONDARY_EDUCATION',
        label: '8532 Technical and vocational secondary education',
      },
    ],
  },
  HIGHER_EDUCATION: {
    label: '854 Higher education',
    children: [
      {
        value: '_8541_POST_SECONDARY_NON_TERTIARY_EDUCATION',
        label: '8541 Post-secondary non-tertiary education',
      },
      {
        value: '_8542_TERTIARY_EDUCATION',
        label: '8542 Tertiary education',
      },
    ],
  },
  OTHER_EDUCATION: {
    label: '855 Other education',
    children: [
      {
        value: '_8551_SPORTS_AND_RECREATION_EDUCATION',
        label: '8551 Sports and recreation education',
      },
      {
        value: '_8552_CULTURAL_EDUCATION',
        label: '8552 Cultural education',
      },
      {
        value: '_8553_DRIVING_SCHOOL_ACTIVITIES',
        label: '8553 Driving school activities',
      },
      {
        value: '_8559_OTHER_EDUCATION_N_E_C_',
        label: '8559 Other education n.e.c.',
      },
    ],
  },
  EDUCATIONAL_SUPPORT_ACTIVITIES: {
    value: '_856_EDUCATIONAL_SUPPORT_ACTIVITIES',
    label: '856 Educational support activities',
  },
  HOSPITAL_ACTIVITIES: {
    value: '_861_HOSPITAL_ACTIVITIES',
    label: '861 Hospital activities',
  },
  MEDICAL_AND_DENTAL_PRACTICE_ACTIVITIES: {
    label: '862 Medical and dental practice activities',
    children: [
      {
        value: '_8621_GENERAL_MEDICAL_PRACTICE_ACTIVITIES',
        label: '8621 General medical practice activities',
      },
      {
        value: '_8622_SPECIALIST_MEDICAL_PRACTICE_ACTIVITIES',
        label: '8622 Specialist medical practice activities',
      },
      {
        value: '_8623_DENTAL_PRACTICE_ACTIVITIES',
        label: '8623 Dental practice activities',
      },
    ],
  },
  OTHER_HUMAN_HEALTH_ACTIVITIES: {
    value: '_869_OTHER_HUMAN_HEALTH_ACTIVITIES',
    label: '869 Other human health activities',
  },
  RESIDENTIAL_NURSING_CARE_ACTIVITIES: {
    value: '_871_RESIDENTIAL_NURSING_CARE_ACTIVITIES',
    label: '891 Residential nursing care activities',
  },
  RESIDENTIAL_CARE_ACTIVITIES_FOR_MENTAL_RETARDATION_MENTAL_HEALTH_AND_SUBSTANCE_ABUSE: {
    value: '_872_RESIDENTIAL_CARE_ACTIVITIES_FOR_MENTAL_RETARDATION_MENTAL_HEALTH_AND_SUBSTANCE_ABUSE',
    label: '872 Residential care activities for mental retardation, mental health and substance abuse',
  },
  RESIDENTIAL_CARE_ACTIVITIES_FOR_THE_ELDERLY_AND_DISABLED: {
    value: '_873_RESIDENTIAL_CARE_ACTIVITIES_FOR_THE_ELDERLY_AND_DISABLED',
    label: '873 Residential care activities for the elderly and disabled',
  },
  OTHER_RESIDENTIAL_CARE_ACTIVITIES: {
    value: '_879_OTHER_RESIDENTIAL_CARE_ACTIVITIES',
    label: '879 Other residential care activities',
  },
  SOCIAL_WORK_ACTIVITIES_WITHOUT_ACCOMMODATION_FOR_THE_ELDERLY_AND_DISABLED: {
    value: '_881_SOCIAL_WORK_ACTIVITIES_WITHOUT_ACCOMMODATION_FOR_THE_ELDERLY_AND_DISABLED',
    label: '881 Social work activities without accommodation for the elderly and disabled',
  },
  OTHER_SOCIAL_WORK_ACTIVITIES_WITHOUT_ACCOMMODATION: {
    label: '889 Other social work activities without accommodation',
    children: [
      {
        value: '_8891_CHILD_DAY_CARE_ACTIVITIES',
        label: '8891 Child day-care activities',
      },
      {
        value: '_8899_OTHER_SOCIAL_WORK_ACTIVITIES_WITHOUT_ACCOMMODATION_N_E_C_',
        label: '8899 Other social work activities without accommodation n.e.c.',
      },
    ],
  },
  CREATIVE_ARTS_AND_ENTERTAINMENT_ACTIVITIES: {
    label: '900 Creative, arts and entertainment activities',
    children: [
      {
        value: '_9001_PERFORMING_ARTS',
        label: '9001 Performing arts',
      },
      {
        value: '_9002_SUPPORT_ACTIVITIES_TO_PERFORMING_ARTS',
        label: '9002 Support activities to performing arts',
      },
      {
        value: '_9003_ARTISTIC_CREATION',
        label: '9003 Artistic creation',
      },
      {
        value: '_9004_OPERATION_OF_ARTS_FACILITIES',
        label: '9004 Operation of arts facilities',
      },
    ],
  },
  LIBRARIES_ARCHIVES_MUSEUMS_AND_OTHER_CULTURAL_ACTIVITIES: {
    label: '910 Creative, arts and entertainment activities',
    children: [
      {
        value: '_9101_LIBRARY_AND_ARCHIVES_ACTIVITIES',
        label: '9101 Library and archives activities',
      },
      {
        value: '_9102_MUSEUMS_ACTIVITIES',
        label: '9102 Museums activities',
      },
      {
        value: '_9103_OPERATION_OF_HISTORICAL_SITES_AND_BUILDINGS_AND_SIMILAR_VISITOR_ATTRACTIONS',
        label: '9103 Operation of historical sites and buildings and similar visitor attractions',
      },
      {
        value: '_9104_BOTANICAL_AND_ZOOLOGICAL_GARDENS_AND_NATURE_RESERVES_ACTIVITIES',
        label: '9104 Botanical and zoological gardens and nature reserves activities',
      },
    ],
  },
  GAMBLING_AND_BETTING_ACTIVITIES: {
    value: '_920_GAMBLING_AND_BETTING_ACTIVITIES',
    label: '920 Gambling and betting activities',
  },
  SPORTS_ACTIVITIES: {
    label: '931 Sports activities',
    children: [
      {
        value: '_9311_OPERATION_OF_SPORTS_FACILITIES',
        label: '9311 Operation of sports facilities',
      },
      {
        value: '_9312_ACTIVITIES_OF_SPORT_CLUBS',
        label: '9312 Activities of sport clubs',
      },
      {
        value: '_9313_FITNESS_FACILITIES',
        label: '9313 Fitness facilities',
      },
      {
        value: '_9319_OTHER_SPORTS_ACTIVITIES',
        label: '9319 Other sports activities',
      },
    ],
  },
  AMUSEMENT_AND_RECREATION_ACTIVITIES: {
    label: '932 Amusement and recreation activities',
    children: [
      {
        value: '_9321_ACTIVITIES_OF_AMUSEMENT_PARKS_AND_THEME_PARKS',
        label: '9321 Activities of amusement parks and theme parks',
      },
      {
        value: '_9329_OTHER_AMUSEMENT_AND_RECREATION_ACTIVITIES',
        label: '9329 Other amusement and recreation activities',
      },
    ],
  },
  ACTIVITIES_OF_BUSINESS_EMPLOYERS_AND_PROFESSIONAL_MEMBERSHIP_ORGANISATIONS: {
    label: '941 Activities of business, employers and professional membership organisations',
    children: [
      {
        value: '_9411_ACTIVITIES_OF_BUSINESS_AND_EMPLOYES_MEMBERSHIP_ORGANIZATIONS',
        label: '9411 Activities of business and employers membership organisations',
      },
      {
        value: '_9412_ACTIVITIES_OF_PROFESSIONAL_MEMBERSHIP_ORGANIZATIONS',
        label: '9412 Activities of professional membership organisations',
      },
    ],
  },
  ACTIVITIES_OF_TRADE_UNIONS: {
    value: '_942_ACTIVITIES_OF_TRADE_UNIONS',
    label: '942 Activities of trade unions',
  },
  ACTIVITIES_OF_OTHER_MEMBERSHIP_ORGANISATIONS: {
    label: '949 Activities of other membership organisations',
    children: [
      {
        value: '_9491_ACTIVITIES_OF_RELIGIOUS_ORGANIZATIONS',
        label: '9491 Activities of religious organisations',
      },
      {
        value: '_9492_ACTIVITIES_OF_POLITICAL_ORGANIZATIONS',
        label: '9492 Activities of political organisations',
      },
      {
        value: '_9499_ACTIVITIES_OF_OTHER_MEMBERSHIP_ORGANIZATIONS_N_E_C_',
        label: '9499 Activities of other membership organisations n.e.c.',
      },
    ],
  },
  REPAIR_OF_COMPUTERS_AND_COMMUNICATION_EQUIPMENT: {
    label: '951 Repair of computers and communication equipment',
    children: [
      {
        value: '_9511_REPAIR_OF_COMPUTERS_AND_PERIPHERAL_EQUIPMENT',
        label: '9511 Repair of computers and peripheral equipment',
      },
      {
        value: '_9512_REPAIR_OF_COMMUNICATION_EQUIPMENT',
        label: '9512 Repair of communication equipment',
      },
    ],
  },
  REPAIR_OF_PERSONAL_AND_HOUSEHOLD_GOODS: {
    label: '952 Repair of personal and household goods',
    children: [
      {
        value: '_9521_REPAIR_OF_CONSUMER_ELECTRONICS',
        label: '9521 Repair of consumer electronics',
      },
      {
        value: '_9522_REPAIR_OF_HOUSEHOLD_APPLIANCES_AND_HOME_AND_GARDEN_EQUIPMENT',
        label: '9522 Repair of household appliances and home and garden equipment',
      },
      {
        value: '_9523_REPAIR_OF_FOOTWEAR_AND_LEATHER_GOODS',
        label: '9523 Repair of footwear and leather goods',
      },
      {
        value: '_9524_REPAIR_OF_FURNITURE_AND_HOME_FURNISHINGS',
        label: '9524 Repair of furniture and home furnishings',
      },
      {
        value: '_9525_REPAIR_OF_WATCHES_CLOCKS_AND_JEWELLERY',
        label: '9525 Repair of watches, clocks and jewellery',
      },
      {
        value: '_9529_REPAIR_OF_OTHER_PERSONAL_AND_HOUSEHOLD_GOODS',
        label: '9529 Repair of other personal and household goods',
      },
    ],
  },
  OTHER_PERSONAL_SERVICE_ACTIVITIES: {
    label: '960 Other personal service activities',
    children: [
      {
        value: '_9601_WASHING_AND_DRY_CLEANING_OF_TEXTILE_AND_FUR_PRODUCTS',
        label: '9601 Washing and (dry-)cleaning of textile and fur products',
      },
      {
        value: '_9602_HAIRDRESSING_AND_OTHER_BEAUTY_TREATMENT',
        label: '9602 Hairdressing and other beauty treatment',
      },
      {
        value: '_9603_FUNERAL_AND_RELATED_ACTIVITIES',
        label: '9603 Funeral and related activities',
      },
      {
        value: '_9604_PHYSICAL_WELL_BEING_ACTIVITIES',
        label: '9604 Physical well-being activities',
      },
      {
        value: '_9609_OTHER_PERSONAL_SERVICE_ACTIVITIES_N_E_C',
        label: '9609 Other personal service activities n.e.c.',
      },
    ],
  },
  ACTIVITIES_OF_HOUSEHOLDS_AS_EMPLOYERS_OF_DOMESTIC_PERSONNEL: {
    value: '_970_ACTIVITIES_OF_HOUSEHOLDS_AS_EMPLOYERS_OF_DOMESTIC_PERSONNEL',
    label: '970 Activities of households as employers of domestic personnel',
  },
  UNDIFFERENTIATED_GOODS_PRODUCING_ACTIVITIES_OF_PRIVATE_HOUSEHOLDS_FOR_OWN_USE: {
    value: '_981_UNDIFFERENTIATED_GOODS_PRODUCING_ACTIVITIES_OF_PRIVATE_HOUSEHOLDS_FOR_OWN_USE',
    label: '981 Undifferentiated goods-producing activities of private households for own use',
  },
  UNDIFFERENTIATED_SERVICE_PRODUCING_ACTIVITIES_OF_PRIVATE_HOUSEHOLDS_FOR_OWN_USE: {
    value: '_982_UNDIFFERENTIATED_SERVICE_PRODUCING_ACTIVITIES_OF_PRIVATE_HOUSEHOLDS_FOR_OWN_USE',
    label: '982 Undifferentiated service-producing activities of private households for own use',
  },
  ACTIVITIES_OF_EXTRATERRITORIAL_ORGANISATIONS_AND_BODIES: {
    value: '_990_ACTIVITIES_OF_EXTRATERRITORIAL_ORGANIZATIONS_AND_BODIES',
    label: '990 Activities of extraterritorial organisations and bodies',
  },
};

export const subCategoryInstallationActivityMap: Record<SubCategory, InstallationActivity[]> = {
  CROP_AND_ANIMAL_PRODUCTION_HUNTING_AND_RELATED_SERVICE_ACTIVITIES: [
    'GROWING_OF_NON_PERENNIAL_CROPS',
    'GROWING_OF_PERENNIAL_CROPS',
    'PLANT_PROPAGATION',
    'ANIMAL_PRODUCTION',
    'MIXED_FARMING',
    'SUPPORT_ACTIVITIES_TO_AGRICULTURE_AND_POST_HARVEST_CROP_ACTIVITIES',
    'HUNTING_TRAPPING_AND_RELATED_SERVICE_ACTIVITIES',
  ],
  FORESTRY_AND_LOGGING: [
    'SILVICULTURE_AND_OTHER_FORESTRY_ACTIVITIES',
    'LOGGING',
    'GATHERING_OF_WILD_GROWING_NON_WOOD_PRODUCTS',
    'SUPPORT_SERVICES_TO_FORESTRY',
  ],
  FISHING_AND_AQUACULTURE: ['FISHING', 'AQUACULTURE'],
  MINING_OF_COAL_AND_LIGNITE: ['MINING_OF_HARD_COAL', 'MINING_OF_LIGNITE'],
  EXTRACTION_OF_CRUDE_PETROLEUM_AND_NATURAL_GAS: ['EXTRACTION_OF_CRUDE_PETROLEUM', 'EXTRACTION_OF_NATURAL_GAS'],
  MINING_OF_METAL_ORES: ['MINING_OF_IRON_ORES', 'MINING_OF_NON_FERROUS_METAL_ORES'],
  OTHER_MINING_AND_QUARRYING: ['QUARRYING_OF_STONE_SAND_AND_CLAY', 'MINING_AND_QUARRYING_NEC'],
  MINING_SUPPORT_SERVICE_ACTIVITIES: [
    'SUPPORT_ACTIVITIES_FOR_PETROLEUM_AND_NATURAL_GAS_EXTRACTION',
    'SUPPORT_ACTIVITIES_FOR_OTHER_MINING_AND_QUARRYING',
  ],
  MANUFACTURE_OF_FOOD_PRODUCTS: [
    'PROCESSING_AND_PRESERVING_OF_MEAT_AND_PRODUCTION_OF_MEAT_PRODUCTS',
    'PROCESSING_AND_PRESERVING_OF_FISH_CRUSTACEANS_AND_MOLLUSCS',
    'PROCESSING_AND_PRESERVING_OF_FRUIT_AND_VEGETABLES',
    'MANUFACTURE_OF_VEGETABLE_AND_ANIMAL_OILS_AND_FATS',
    'MANUFACTURE_OF_DAIRY_PRODUCTS',
    'MANUFACTURE_OF_GRAIN_MILL_PRODUCTS_STARCHES_AND_STARCH_PRODUCTS',
    'MANUFACTURE_OF_BAKERY_AND_FARINACEOUS_PRODUCTS',
    'MANUFACTURE_OF_OTHER_FOOD_PRODUCTS',
    'MANUFACTURE_OF_PREPARED_ANIMAL_FEEDS',
  ],
  MANUFACTURE_OF_BEVERAGES: ['MANUFACTURE_OF_BEVERAGES'],
  MANUFACTURE_OF_TOBACCO_PRODUCTS: ['MANUFACTURE_OF_TOBACCO_PRODUCTS'],
  MANUFACTURE_OF_TEXTILES: [
    'PREPARATION_AND_SPINNING_OF_TEXTILE_FIBRES',
    'WEAVING_OF_TEXTILES',
    'FINISHING_OF_TEXTILES',
    'MANUFACTURE_OF_OTHER_TEXTILES',
  ],
  MANUFACTURE_OF_WEARING_APPAREL: [
    'MANUFACTURE_OF_WEARING_APPAREL_EXCEPT_FUR_APPAREL',
    'MANUFACTURE_OF_ARTICLES_OF_FUR',
    'MANUFACTURE_OF_KNITTED_AND_CROCHETED_APPAREL',
  ],
  MANUFACTURE_OF_LEATHER_AND_RELATED_PRODUCTS: [
    'TANNING_AND_DRESSING_OF_LEATHER_MANUFACTURE_OF_LUGGAGE_HANDBAGS_SADDLERY_AND_HARNESS_DRESSING_AND_DYEING_OF_FUR',
    'MANUFACTURE_OF_FOOTWEAR',
  ],
  MANUFACTURE_OF_WOOD_AND_OF_PRODUCTS_OF_WOOD_AND_CORK_EXCEPT_FURNITURE_MANUFACTURE_OF_ARTICLES_OF_STRAW_AND_PLAITING_MATERIALS:
    ['SAWMILLING_AND_PLANING_OF_WOOD', 'MANUFACTURE_OF_PRODUCTS_OF_WOOD_CORK_STRAW_AND_PLAITING_MATERIALS'],
  MANUFACTURE_OF_PAPER_AND_PAPER_PRODUCTS: [
    'MANUFACTURE_OF_PULP_PAPER_AND_PAPERBOARD',
    'MANUFACTURE_OF_ARTICLES_OF_PAPER_AND_PAPERBOARD',
  ],
  PRINTING_AND_REPRODUCTION_OF_RECORDED_MEDIA: [
    'PRINTING_AND_SERVICE_ACTIVITIES_RELATED_TO_PRINTING',
    'REPRODUCTION_OF_RECORDED_MEDIA',
  ],
  MANUFACTURE_OF_COKE_AND_REFINED_PETROLEUM_PRODUCTS: [
    'MANUFACTURE_OF_COKE_OVEN_PRODUCTS',
    'MANUFACTURE_OF_REFINED_PETROLEUM_PRODUCTS',
  ],
  MANUFACTURE_OF_CHEMICALS_AND_CHEMICAL_PRODUCTS: [
    'MANUFACTURE_OF_BASIC_CHEMICALS_FERTILISERS_AND_NITROGEN_COMPOUNDS_PLASTICS_AND_SYNTHETIC_RUBBER_IN_PRIMARY_FORMS',
    'MANUFACTURE_OF_PESTICIDES_AND_OTHER_AGROCHEMICAL_PRODUCTS',
    'MANUFACTURE_OF_PAINTS_VARNISHES_AND_SIMILAR_COATINGS_PRINTING_INK_AND_MASTICS',
    'MANUFACTURE_OF_SOAP_AND_DETERGENTS_CLEANING_AND_POLISHING_PREPARATIONS_PERFUMES_AND_TOILET_PREPARATIONS',
    'MANUFACTURE_OF_OTHER_CHEMICAL_PRODUCTS',
    'MANUFACTURE_OF_MAN_MADE_FIBRES',
  ],
  MANUFACTURE_OF_BASIC_PHARMACEUTICAL_PRODUCTS_AND_PHARMACEUTICAL_PREPARATIONS: [
    'MANUFACTURE_OF_BASIC_PHARMACEUTICAL_PRODUCTS',
    'MANUFACTURE_OF_PHARMACEUTICAL_PREPARATIONS',
  ],
  MANUFACTURE_OF_RUBBER_AND_PLASTIC_PRODUCTS: ['MANUFACTURE_OF_RUBBER_PRODUCTS', 'MANUFACTURE_OF_PLASTICS_PRODUCTS'],
  MANUFACTURE_OF_OTHER_NON_METALLIC_MINERAL_PRODUCTS: [
    'MANUFACTURE_OF_GLASS_AND_GLASS_PRODUCTS',
    'MANUFACTURE_OF_REFRACTORY_PRODUCTS',
    'MANUFACTURE_OF_CLAY_BUILDING_MATERIALS',
    'MANUFACTURE_OF_OTHER_PORCELAIN_AND_CERAMIC_PRODUCTS',
    'MANUFACTURE_OF_CEMENT_LIME_AND_PLASTER',
    'MANUFACTURE_OF_ARTICLES_OF_CONCRETE_CEMENT_AND_PLASTER',
    'CUTTING_SHAPING_AND_FINISHING_OF_STONE',
    'MANUFACTURE_OF_ABRASIVE_PRODUCTS_AND_NON_METALLIC_MINERAL_PRODUCTS_NEC',
  ],
  MANUFACTURE_OF_BASIC_METALS: [
    'MANUFACTURE_OF_BASIC_IRON_AND_STEEL_AND_OF_FERRO_ALLOYS',
    'MANUFACTURE_OF_TUBES_PIPES_HOLLOW_PROFILES_AND_RELATED_FITTINGS_OF_STEEL',
    'MANUFACTURE_OF_OTHER_PRODUCTS_OF_FIRST_PROCESSING_OF_STEEL',
    'MANUFACTURE_OF_BASIC_PRECIOUS_AND_OTHER_NON_FERROUS_METALS',
    'CASTING_OF_METALS',
  ],
  MANUFACTURE_OF_FABRICATED_METAL_PRODUCTS_EXCEPT_MACHINERY_AND_EQUIPMENT: [
    'MANUFACTURE_OF_STRUCTURAL_METAL_PRODUCTS',
    'MANUFACTURE_OF_TANKS_RESERVOIRS_AND_CONTAINERS_OF_METAL',
    'MANUFACTURE_OF_STEAM_GENERATORS_EXCEPT_CENTRAL_HEATING_HOT_WATER_BOILERS',
    'MANUFACTURE_OF_WEAPONS_AND_AMMUNITION',
    'FORGING_PRESSING_STAMPING_AND_ROLL_FORMING_OF_METAL_POWDER_METALLURGY',
    'TREATMENT_AND_COATING_OF_METALS_MACHINING',
    'MANUFACTURE_OF_CUTLERY_TOOLS_AND_GENERAL_HARDWARE',
    'MANUFACTURE_OF_OTHER_FABRICATED_METAL_PRODUCTS',
  ],
  MANUFACTURE_OF_COMPUTER_ELECTRONIC_AND_OPTICAL_PRODUCTS: [
    'MANUFACTURE_OF_ELECTRONIC_COMPONENTS_AND_BOARDS',
    'MANUFACTURE_OF_COMPUTERS_AND_PERIPHERAL_EQUIPMENT',
    'MANUFACTURE_OF_COMMUNICATION_EQUIPMENT',
    'MANUFACTURE_OF_CONSUMER_ELECTRONICS',
    'MANUFACTURE_OF_INSTRUMENTS_AND_APPLIANCES_FOR_MEASURING_TESTING_AND_NAVIGATION_WATCHES_AND_CLOCKS',
    'MANUFACTURE_OF_IRRADIATION_ELECTROMEDICAL_AND_ELECTROTHERAPEUTIC_EQUIPMENT',
    'MANUFACTURE_OF_OPTICAL_INSTRUMENTS_AND_PHOTOGRAPHIC_EQUIPMENT',
    'MANUFACTURE_OF_MAGNETIC_AND_OPTICAL_MEDIA',
  ],
  MANUFACTURE_OF_ELECTRICAL_EQUIPMENT: [
    'MANUFACTURE_OF_ELECTRIC_MOTORS_GENERATORS_TRANSFORMERS_AND_ELECTRICITY_DISTRIBUTION_AND_CONTROL_APPARATUS',
    'MANUFACTURE_OF_BATTERIES_AND_ACCUMULATORS',
    'MANUFACTURE_OF_WIRING_AND_WIRING_DEVICES',
    'MANUFACTURE_OF_ELECTRIC_LIGHTING_EQUIPMENT',
    'MANUFACTURE_OF_DOMESTIC_APPLIANCES',
    'MANUFACTURE_OF_OTHER_ELECTRICAL_EQUIPMENT',
  ],
  MANUFACTURE_OF_MACHINERY_AND_EQUIPMENT_N_E_C: [
    'MANUFACTURE_OF_GENERAL_PURPOSE_MACHINERY',
    'MANUFACTURE_OF_OTHER_GENERAL_PURPOSE_MACHINERY',
    'MANUFACTURE_OF_AGRICULTURAL_AND_FORESTRY_MACHINERY',
    'MANUFACTURE_OF_METAL_FORMING_MACHINERY_AND_MACHINE_TOOLS',
    'MANUFACTURE_OF_OTHER_SPECIAL_PURPOSE_MACHINERY',
  ],
  MANUFACTURE_OF_MOTOR_VEHICLES_TRAILERS_AND_SEMI_TRAILERS: [
    'MANUFACTURE_OF_MOTOR_VEHICLES',
    'MANUFACTURE_OF_BODIES (COACHWORK) FOR_MOTOR_VEHICLES_MANUFACTURE_OF_TRAILERS_AND_SEMI_TRAILERS',
    'MANUFACTURE_OF_PARTS_AND_ACCESSORIES_FOR_MOTOR_VEHICLES',
  ],
  MANUFACTURE_OF_OTHER_TRANSPORT_EQUIPMENT: [
    'BUILDING_OF_SHIPS_AND_BOATS',
    'MANUFACTURE_OF_RAILWAY_LOCOMOTIVES_AND_ROLLING_STOCK',
    'MANUFACTURE_OF_AIR_AND_SPACECRAFT_AND_RELATED_MACHINERY',
    'MANUFACTURE_OF_MILITARY_FIGHTING_VEHICLES',
    'MANUFACTURE_OF_TRANSPORT_EQUIPMENT_NEC',
  ],
  MANUFACTURE_OF_FURNITURE: ['MANUFACTURE_OF_FURNITURE'],
  OTHER_MANUFACTURING: [
    'MANUFACTURE_OF_JEWELLERY_BIJOUTERIE_AND_RELATED_ARTICLES',
    'MANUFACTURE_OF_MUSICAL_INSTRUMENTS',
    'MANUFACTURE_OF_SPORTS_GOODS',
    'MANUFACTURE_OF_GAMES_AND_TOYS',
    'MANUFACTURE_OF_MEDICAL_AND_DENTAL_INSTRUMENTS_AND_SUPPLIES',
    'MANUFACTURING_NEC',
  ],
  REPAIR_AND_INSTALLATION_OF_MACHINERY_AND_EQUIPMENT: [
    'REPAIR_OF_FABRICATED_METAL_PRODUCTS_MACHINERY_AND_EQUIPMENT',
    'INSTALLATION_OF_INDUSTRIAL_MACHINERY_AND_EQUIPMENT',
  ],
  ELECTRICITY_GAS_STEAM_AND_AIR_CONDITIONING_SUPPLY: [
    'ELECTRIC_POWER_GENERATION_TRANSMISSION_AND_DISTRIBUTION',
    'MANUFACTURE_OF_GAS_DISTRIBUTION_OF_GASEOUS_FUELS_THROUGH_MAINS',
    'STEAM_AND_AIR_CONDITIONING_SUPPLY',
  ],
  WATER_COLLECTION_TREATMENT_AND_SUPPLY: ['WATER_COLLECTION_TREATMENT_AND_SUPPLY'],
  SEWERAGE: ['SEWERAGE'],
  WASTE_COLLECTION_TREATMENT_AND_DISPOSAL_ACTIVITIES_MATERIALS_RECOVERY: [
    'WASTE_COLLECTION',
    'WASTE_TREATMENT_AND_DISPOSAL',
    'MATERIALS_RECOVERY',
  ],
  REMEDIATION_ACTIVITIES_AND_OTHER_WASTE_MANAGEMENT_SERVICES: [
    'REMEDIATION_ACTIVITIES_AND_OTHER_WASTE_MANAGEMENT_SERVICES',
  ],
  CONSTRUCTION_OF_BUILDINGS: [
    'DEVELOPMENT_OF_BUILDING_PROJECTS',
    'CONSTRUCTION_OF_RESIDENTIAL_AND_NON_RESIDENTIAL_BUILDINGS',
  ],
  CIVIL_ENGINEERING: [
    'CONSTRUCTION_OF_ROADS_AND_RAILWAYS',
    'CONSTRUCTION_OF_UTILITY_PROJECTS',
    'CONSTRUCTION_OF_OTHER_CIVIL_ENGINEERING_PROJECTS',
  ],
  SPECIALISED_CONSTRUCTION_ACTIVITIES: [
    'DEMOLITION_AND_SITE_PREPARATION',
    'ELECTRICAL_PLUMBING_AND_OTHER_CONSTRUCTION_INSTALLATION_ACTIVITIES',
    'BUILDING_COMPLETION_AND_FINISHING',
    'OTHER_SPECIALISED_CONSTRUCTION_ACTIVITIES',
  ],
  WHOLESALE_AND_RETAIL_TRADE_AND_REPAIR_OF_MOTOR_VEHICLES_AND_MOTORCYCLES: [
    'SALE_OF_MOTOR_VEHICLES',
    'MAINTENANCE_AND_REPAIR_OF_MOTOR_VEHICLES',
    'SALE_OF_MOTOR_VEHICLE_PARTS_AND_ACCESSORIES',
    'SALE_MAINTENANCE_AND_REPAIR_OF_MOTORCYCLES_AND_RELATED_PARTS_AND_ACCESSORIES',
  ],
  WHOLESALE_TRADE_EXCEPT_OF_MOTOR_VEHICLES_AND_MOTORCYCLES: [
    'WHOLESALE_ON_AFEE_OR_CONTRACT_BASIS',
    'WHOLESALE_OF_AGRICULTURAL_RAW_MATERIALS_AND_LIVE_ANIMALS',
    'WHOLESALE_OF_FOOD_BEVERAGES_AND_TOBACCO',
    'WHOLESALE_OF_HOUSEHOLD_GOODS',
    'WHOLESALE_OF_INFORMATION_AND_COMMUNICATION_EQUIPMENT',
    'WHOLESALE_OF_OTHER_MACHINERY_EQUIPMENT_AND_SUPPLIES',
    'OTHER_SPECIALISED_WHOLESALE',
    'NON_SPECIALISED_WHOLESALE_TRADE',
  ],
  RETAIL_TRADE_EXCEPT_OF_MOTOR_VEHICLES_AND_MOTORCYCLES: [
    'RETAIL_SALE_IN_NON_SPECIALISED_STORES',
    'RETAIL_SALE_OF_FOOD_BEVERAGES_AND_TOBACCO_IN_SPECIALISED_STORES',
    'RETAIL_SALE_OF_AUTOMOTIVE_FUEL_IN_SPECIALISED_STORES',
    'RETAIL_SALE_OF_INFORMATION_AND_COMMUNICATION_EQUIPMENT_IN_SPECIALISED_STORES',
    'RETAIL_SALE_OF_OTHER_HOUSEHOLD_EQUIPMENT_IN_SPECIALISED_STORES',
    'RETAIL_SALE_OF_CULTURAL_AND_RECREATION_GOODS_IN_SPECIALISED_STORES',
    'RETAIL_SALE_OF_OTHER_GOODS_IN_SPECIALISED_STORES',
    'RETAIL_SALE_VIA_STALLS_AND_MARKETS',
    'RETAIL_TRADE_NOT_IN_STORES_STALLS_OR_MARKETS',
  ],
  LAND_TRANSPORT_AND_TRANSPORT_VIA_PIPELINES: [
    'PASSENGER_RAIL_TRANSPORT_INTERURBAN',
    'FREIGHT_RAIL_TRANSPORT',
    'OTHER_PASSENGER_LAND_TRANSPORT',
    'FREIGHT_TRANSPORT_BY_ROAD_AND_REMOVAL_SERVICES',
    'TRANSPORT_VIA_PIPELINE',
  ],
  WATER_TRANSPORT: [
    'SEA_AND_COASTAL_PASSENGER_WATER_TRANSPORT',
    'SEA_AND_COASTAL_FREIGHT_WATER_TRANSPORT',
    'INLAND_PASSENGER_WATER_TRANSPORT',
    'INLAND_FREIGHT_WATER_TRANSPORT',
  ],
  AIR_TRANSPORT: ['PASSENGER_AIR_TRANSPORT', 'FREIGHT_AIR_TRANSPORT_AND_SPACE_TRANSPORT'],
  WAREHOUSING_AND_SUPPORT_ACTIVITIES_FOR_TRANSPORTATION: [
    'WAREHOUSING_AND_STORAGE',
    'SUPPORT_ACTIVITIES_FOR_TRANSPORTATION',
  ],
  POSTAL_AND_COURIER_ACTIVITIES: [
    'POSTAL_ACTIVITIES_UNDER_UNIVERSAL_SERVICE_OBLIGATION',
    'OTHER_POSTAL_AND_COURIER_ACTIVITIES',
  ],
  ACCOMMODATION: [
    'HOTELS_AND_SIMILAR_ACCOMMODATION',
    'HOLIDAY_AND_OTHER_SHORT_STAY_ACCOMMODATION',
    'CAMPING_GROUNDS_RECREATIONAL_VEHICLE_PARKS_AND_TRAILER_PARKS',
    'OTHER_ACCOMMODATION',
  ],
  FOOD_AND_BEVERAGE_SERVICE_ACTIVITIES: [
    'RESTAURANTS_AND_MOBILE_FOOD_SERVICE_ACTIVITIES',
    'EVENT_CATERING_AND_OTHER_FOOD_SERVICE_ACTIVITIES',
    'BEVERAGE_SERVING_ACTIVITIES',
  ],
  PUBLISHING_ACTIVITIES: ['PUBLISHING_OF_BOOKS_PERIODICALS_AND_OTHER_PUBLISHING_ACTIVITIES', 'SOFTWARE_PUBLISHING'],
  MOTION_PICTURE_VIDEO_AND_TELEVISION_PROGRAMME_PRODUCTION_SOUND_RECORDING_AND_MUSIC_PUBLISHING_ACTIVITIES: [
    'MOTION_PICTURE_VIDEO_AND_TELEVISION_PROGRAMME_ACTIVITIES',
    'SOUND_RECORDING_AND_MUSIC_PUBLISHING_ACTIVITIES',
  ],
  PROGRAMMING_AND_BROADCASTING_ACTIVITIES: ['RADIO_BROADCASTING', 'TELEVISION_PROGRAMMING_AND_BROADCASTING_ACTIVITIES'],
  TELECOMMUNICATIONS: [
    'WIRED_TELECOMMUNICATIONS_ACTIVITIES',
    'WIRELESS_TELECOMMUNICATIONS_ACTIVITIES',
    'SATELLITE_TELECOMMUNICATIONS_ACTIVITIES',
    'OTHER_TELECOMMUNICATIONS_ACTIVITIES',
  ],
  COMPUTER_PROGRAMMING_CONSULTANCY_AND_RELATED_ACTIVITIES: ['COMPUTER_PROGRAMMING_CONSULTANCY_AND_RELATED_ACTIVITIES'],
  INFORMATION_SERVICE_ACTIVITIES: [
    'DATA_PROCESSING_HOSTING_AND_RELATED_ACTIVITIES_WEB_PORTALS',
    'OTHER_INFORMATION_SERVICE_ACTIVITIES',
  ],
  FINANCIAL_SERVICE_ACTIVITIES_EXCEPT_INSURANCE_AND_PENSION_FUNDING: [
    'MONETARY_INTERMEDIATION',
    'ACTIVITIES_OF_HOLDING_COMPANIES',
    'TRUSTS_FUNDS_AND_SIMILAR_FINANCIAL_ENTITIES',
    'OTHER_FINANCIAL_SERVICE_ACTIVITIES_EXCEPT_INSURANCE_AND_PENSION_FUNDING',
  ],
  INSURANCE_REINSURANCE_AND_PENSION_FUNDING_EXCEPT_COMPULSORY_SOCIAL_SECURITY: [
    'INSURANCE',
    'REINSURANCE',
    'PENSION_FUNDING',
  ],
  ACTIVITIES_AUXILIARY_TO_FINANCIAL_SERVICES_AND_INSURANCE_ACTIVITIES: [
    'ACTIVITIES_AUXILIARY_TO_FINANCIAL_SERVICES_EXCEPT_INSURANCE_AND_PENSION_FUNDING',
    'ACTIVITIES_AUXILIARY_TO_INSURANCE_AND_PENSION_FUNDING',
    'FUND_MANAGEMENT_ACTIVITIES',
  ],
  REAL_ESTATE_ACTIVITIES: [
    'BUYING_AND_SELLING_OF_OWN_REAL_ESTATE',
    'RENTING_AND_OPERATING_OF_OWN_OR_LEASED_REAL_ESTATE',
    'REAL_ESTATE_ACTIVITIES_ON_AFEE_OR_CONTRACT_BASIS',
  ],
  LEGAL_AND_ACCOUNTING_ACTIVITIES: [
    'LEGAL_ACTIVITIES',
    'ACCOUNTING_BOOKKEEPING_AND_AUDITING_ACTIVITIES_TAX_CONSULTANCY',
  ],
  ACTIVITIES_OF_HEAD_OFFICES_MANAGEMENT_CONSULTANCY_ACTIVITIES: [
    'ACTIVITIES_OF_HEAD_OFFICES',
    'MANAGEMENT_CONSULTANCY_ACTIVITIES',
  ],
  ARCHITECTURAL_AND_ENGINEERING_ACTIVITIES_TECHNICAL_TESTING_AND_ANALYSIS: [
    'ARCHITECTURAL_AND_ENGINEERING_ACTIVITIES_AND_RELATED_TECHNICAL_CONSULTANCY',
    'TECHNICAL_TESTING_AND_ANALYSIS',
  ],
  SCIENTIFIC_RESEARCH_AND_DEVELOPMENT: [
    'RESEARCH_AND_EXPERIMENTAL_DEVELOPMENT_ON_NATURAL_SCIENCES_AND_ENGINEERING',
    'RESEARCH_AND_EXPERIMENTAL_DEVELOPMENT_ON_SOCIAL_SCIENCES_AND_HUMANITIES',
  ],
  ADVERTISING_AND_MARKET_RESEARCH: ['ADVERTISING', 'MARKET_RESEARCH_AND_PUBLIC_OPINION_POLLING'],
  OTHER_PROFESSIONAL_SCIENTIFIC_AND_TECHNICAL_ACTIVITIES: [
    'SPECIALISED_DESIGN_ACTIVITIES',
    'PHOTOGRAPHIC_ACTIVITIES',
    'TRANSLATION_AND_INTERPRETATION_ACTIVITIES',
    'OTHER_PROFESSIONAL_SCIENTIFIC_AND_TECHNICAL_ACTIVITIES_NEC',
  ],
  VETERINARY_ACTIVITIES: ['VETERINARY_ACTIVITIES'],
  RENTAL_AND_LEASING_ACTIVITIES: [
    'RENTING_AND_LEASING_OF_MOTOR_VEHICLES',
    'RENTING_AND_LEASING_OF_PERSONAL_AND_HOUSEHOLD_GOODS',
    'RENTING_AND_LEASING_OF_OTHER_MACHINERY_EQUIPMENT_AND_TANGIBLE_GOODS',
    'RENTING_AND_LEASING_OF_OTHER_MACHINERY_EQUIPMENT_AND_TANGIBLE_GOODS',
    'LEASING_OF_INTELLECTUAL_PROPERTY_AND_SIMILAR_PRODUCTS_EXCEPT_COPYRIGHTED_WORKS',
  ],
  EMPLOYMENT_ACTIVITIES: [
    'ACTIVITIES_OF_EMPLOYMENT_PLACEMENT_AGENCIES',
    'TEMPORARY_EMPLOYMENT_AGENCY_ACTIVITIES',
    'OTHER_HUMAN_RESOURCES_PROVISION',
  ],
  TRAVEL_AGENCY_TOUR_OPERATOR_RESERVATION_SERVICE_AND_RELATED_ACTIVITIES: [
    'TRAVEL_AGENCY_AND_TOUR_OPERATOR_ACTIVITIES',
    'OTHER_RESERVATION_SERVICE_AND_RELATED_ACTIVITIES',
  ],
  SECURITY_AND_INVESTIGATION_ACTIVITIES: [
    'PRIVATE_SECURITY_ACTIVITIES',
    'SECURITY_SYSTEMS_SERVICE_ACTIVITIES',
    'INVESTIGATION_ACTIVITIES',
  ],
  SERVICES_TO_BUILDINGS_AND_LANDSCAPE_ACTIVITIES: [
    'COMBINED_FACILITIES_SUPPORT_ACTIVITIES',
    'CLEANING_ACTIVITIES',
    'LANDSCAPE_SERVICE_ACTIVITIES',
  ],
  OFFICE_ADMINISTRATIVE_OFFICE_SUPPORT_AND_OTHER_BUSINESS_SUPPORT_ACTIVITIES: [
    'OFFICE_ADMINISTRATIVE_AND_SUPPORT_ACTIVITIES',
    'ACTIVITIES_OF_CALL_CENTRES',
    'ORGANISATION_OF_CONVENTIONS_AND_TRADE_SHOWS',
    'BUSINESS_SUPPORT_SERVICE_ACTIVITIES_NEC',
  ],
  PUBLIC_ADMINISTRATION_AND_DEFENCE_COMPULSORY_SOCIAL_SECURITY: [
    'ADMINISTRATION_OF_THE_STATE_AND_THE_ECONOMIC_AND_SOCIAL_POLICY_OF_THE_COMMUNITY',
    'PROVISION_OF_SERVICES_TO_THE_COMMUNITY_AS_AWHOLE',
    'COMPULSORY_SOCIAL_SECURITY_ACTIVITIES',
  ],
  EDUCATION: [
    'PRE_PRIMARY_EDUCATION',
    'PRIMARY_EDUCATION',
    'SECONDARY_EDUCATION',
    'HIGHER_EDUCATION',
    'OTHER_EDUCATION',
    'EDUCATIONAL_SUPPORT_ACTIVITIES',
  ],
  HUMAN_HEALTH_ACTIVITIES: [
    'HOSPITAL_ACTIVITIES',
    'MEDICAL_AND_DENTAL_PRACTICE_ACTIVITIES',
    'OTHER_HUMAN_HEALTH_ACTIVITIES',
  ],
  RESIDENTIAL_CARE_ACTIVITIES: [
    'RESIDENTIAL_NURSING_CARE_ACTIVITIES',
    'RESIDENTIAL_CARE_ACTIVITIES_FOR_MENTAL_RETARDATION_MENTAL_HEALTH_AND_SUBSTANCE_ABUSE',
    'RESIDENTIAL_CARE_ACTIVITIES_FOR_THE_ELDERLY_AND_DISABLED',
    'OTHER_RESIDENTIAL_CARE_ACTIVITIES',
  ],
  SOCIAL_WORK_ACTIVITIES_WITHOUT_ACCOMMODATION: [
    'SOCIAL_WORK_ACTIVITIES_WITHOUT_ACCOMMODATION_FOR_THE_ELDERLY_AND_DISABLED',
    'OTHER_SOCIAL_WORK_ACTIVITIES_WITHOUT_ACCOMMODATION',
  ],
  CREATIVE_ARTS_AND_ENTERTAINMENT_ACTIVITIES: ['CREATIVE_ARTS_AND_ENTERTAINMENT_ACTIVITIES'],
  LIBRARIES_ARCHIVES_MUSEUMS_AND_OTHER_CULTURAL_ACTIVITIES: [
    'LIBRARIES_ARCHIVES_MUSEUMS_AND_OTHER_CULTURAL_ACTIVITIES',
  ],
  GAMBLING_AND_BETTING_ACTIVITIES: ['GAMBLING_AND_BETTING_ACTIVITIES'],
  SPORTS_ACTIVITIES_AND_AMUSEMENT_AND_RECREATION_ACTIVITIES: [
    'SPORTS_ACTIVITIES',
    'AMUSEMENT_AND_RECREATION_ACTIVITIES',
  ],
  ACTIVITIES_OF_MEMBERSHIP_ORGANISATIONS: [
    'ACTIVITIES_OF_BUSINESS_EMPLOYERS_AND_PROFESSIONAL_MEMBERSHIP_ORGANISATIONS',
    'ACTIVITIES_OF_TRADE_UNIONS',
    'ACTIVITIES_OF_OTHER_MEMBERSHIP_ORGANISATIONS',
  ],
  REPAIR_OF_COMPUTERS_AND_PERSONAL_AND_HOUSEHOLD_GOODS: [
    'REPAIR_OF_COMPUTERS_AND_COMMUNICATION_EQUIPMENT',
    'REPAIR_OF_PERSONAL_AND_HOUSEHOLD_GOODS',
  ],
  OTHER_PERSONAL_SERVICE_ACTIVITIES: ['OTHER_PERSONAL_SERVICE_ACTIVITIES'],
  ACTIVITIES_OF_HOUSEHOLDS_AS_EMPLOYERS_OF_DOMESTIC_PERSONNEL: [
    'ACTIVITIES_OF_HOUSEHOLDS_AS_EMPLOYERS_OF_DOMESTIC_PERSONNEL',
  ],
  UNDIFFERENTIATED_GOODS_AND_SERVICES_PRODUCING_ACTIVITIES_OF_PRIVATE_HOUSEHOLDS_FOR_OWN_USE: [
    'UNDIFFERENTIATED_GOODS_PRODUCING_ACTIVITIES_OF_PRIVATE_HOUSEHOLDS_FOR_OWN_USE',
    'UNDIFFERENTIATED_SERVICE_PRODUCING_ACTIVITIES_OF_PRIVATE_HOUSEHOLDS_FOR_OWN_USE',
  ],
  ACTIVITIES_OF_EXTRATERRITORIAL_ORGANISATIONS_AND_BODIES: ['ACTIVITIES_OF_EXTRATERRITORIAL_ORGANISATIONS_AND_BODIES'],
};

export function getInstallationActivityLabelByValue(value: NaceCode): string {
  const activity = getInstallationActivityByValue(value);
  return activity ? activity[1].label : getInstallationActivityChildByValue(value).label;
}

function getInstallationActivityByValue(value: NaceCode): [string, InstallationActivityDetails] {
  return Object.entries(installationActivities).find((entry) => entry[1].value === value);
}

function getInstallationActivityChildByValue(value: NaceCode): { value: NaceCode; label: string } {
  return Object.values(installationActivities)
    .reduce((res, details) => res.concat(details.children ?? []), [])
    .find((entry) => entry.value === value);
}
