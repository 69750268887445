import { Pipe, PipeTransform } from '@angular/core';

import { RequestActionInfoDTO } from 'pmrv-api';

import { ItemActionTypePipe } from './item-action-type.pipe';

@Pipe({ name: 'itemActionHeader' })
export class ItemActionHeaderPipe implements PipeTransform {
  transform(item: RequestActionInfoDTO): string {
    const itemActionTypePipe = new ItemActionTypePipe();

    switch (item.type) {
      case 'PERMIT_ISSUANCE_APPLICATION_AMENDS_SUBMITTED':
      case 'PERMIT_ISSUANCE_APPLICATION_DEEMED_WITHDRAWN':
      case 'PERMIT_ISSUANCE_APPLICATION_GRANTED':
      case 'PERMIT_ISSUANCE_APPLICATION_PEER_REVIEWER_ACCEPTED':
      case 'PERMIT_ISSUANCE_APPLICATION_PEER_REVIEWER_REJECTED':
      case 'PERMIT_ISSUANCE_APPLICATION_REJECTED':
      case 'PERMIT_ISSUANCE_APPLICATION_RETURNED_FOR_AMENDS':
      case 'PERMIT_ISSUANCE_APPLICATION_SUBMITTED':
      case 'PERMIT_ISSUANCE_PEER_REVIEW_REQUESTED':
      case 'PERMIT_ISSUANCE_RECALLED_FROM_AMENDS':
        return `${itemActionTypePipe.transform(item.type)} by ${item.submitter}`;

      case 'PERMIT_SURRENDER_APPLICATION_CANCELLED':
      case 'PERMIT_SURRENDER_APPLICATION_PEER_REVIEWER_ACCEPTED':
      case 'PERMIT_SURRENDER_APPLICATION_PEER_REVIEWER_REJECTED':
      case 'PERMIT_SURRENDER_APPLICATION_SUBMITTED':
      case 'PERMIT_SURRENDER_PEER_REVIEW_REQUESTED':
      case 'PERMIT_SURRENDER_CESSATION_COMPLETED':
      case 'PERMIT_SURRENDER_APPLICATION_DEEMED_WITHDRAWN':
      case 'PERMIT_SURRENDER_APPLICATION_GRANTED':
      case 'PERMIT_SURRENDER_APPLICATION_REJECTED':
        return `${itemActionTypePipe.transform(item.type)} by ${item.submitter}`;

      case 'PERMIT_REVOCATION_APPLICATION_CANCELLED':
      case 'PERMIT_REVOCATION_PEER_REVIEW_REQUESTED':
      case 'PERMIT_REVOCATION_APPLICATION_PEER_REVIEWER_ACCEPTED':
      case 'PERMIT_REVOCATION_APPLICATION_PEER_REVIEWER_REJECTED':
      case 'PERMIT_REVOCATION_CESSATION_COMPLETED':
      case 'PERMIT_REVOCATION_APPLICATION_SUBMITTED':
      case 'PERMIT_REVOCATION_APPLICATION_WITHDRAWN':
        return `${itemActionTypePipe.transform(item.type)} by ${item.submitter}`;

      case 'PERMIT_NOTIFICATION_APPLICATION_SUBMITTED':
      case 'PERMIT_NOTIFICATION_FOLLOW_UP_RESPONSE_SUBMITTED':
      case 'PERMIT_NOTIFICATION_APPLICATION_GRANTED':
      case 'PERMIT_NOTIFICATION_APPLICATION_REJECTED':
      case 'PERMIT_NOTIFICATION_FOLLOW_UP_DATE_EXTENDED':
      case 'PERMIT_NOTIFICATION_PEER_REVIEW_REQUESTED':
      case 'PERMIT_NOTIFICATION_APPLICATION_PEER_REVIEWER_ACCEPTED':
      case 'PERMIT_NOTIFICATION_APPLICATION_PEER_REVIEWER_REJECTED':
      case 'PERMIT_NOTIFICATION_APPLICATION_COMPLETED':
      case 'PERMIT_NOTIFICATION_FOLLOW_UP_RETURNED_FOR_AMENDS':
      case 'PERMIT_NOTIFICATION_FOLLOW_UP_RECALLED_FROM_AMENDS':
      case 'PERMIT_NOTIFICATION_FOLLOW_UP_APPLICATION_AMENDS_SUBMITTED':
        return `${itemActionTypePipe.transform(item.type)} by ${item.submitter}`;

      case 'PERMIT_VARIATION_APPLICATION_GRANTED':
      case 'PERMIT_VARIATION_APPLICATION_REJECTED':
      case 'PERMIT_VARIATION_APPLICATION_DEEMED_WITHDRAWN':
      case 'PERMIT_VARIATION_APPLICATION_SUBMITTED':
      case 'PERMIT_VARIATION_PEER_REVIEW_REQUESTED':
      case 'PERMIT_VARIATION_APPLICATION_PEER_REVIEWER_ACCEPTED':
      case 'PERMIT_VARIATION_APPLICATION_PEER_REVIEWER_REJECTED':
      case 'PERMIT_VARIATION_APPLICATION_RETURNED_FOR_AMENDS':
      case 'PERMIT_VARIATION_APPLICATION_AMENDS_SUBMITTED':
      case 'PERMIT_VARIATION_RECALLED_FROM_AMENDS':
      case 'PERMIT_VARIATION_APPLICATION_REGULATOR_LED_APPROVED':
        return `${itemActionTypePipe.transform(item.type)} by ${item.submitter}`;

      case 'AER_APPLICATION_SUBMITTED':
      case 'AER_APPLICATION_SENT_TO_VERIFIER':
      case 'AER_RECALLED_FROM_VERIFICATION':
      case 'AER_APPLICATION_COMPLETED':
      case 'AER_APPLICATION_VERIFICATION_SUBMITTED':
      case 'AER_APPLICATION_RETURNED_FOR_AMENDS':
      case 'AER_APPLICATION_RE_INITIATED':
      case 'AER_APPLICATION_AMENDS_SUBMITTED':
      case 'AER_APPLICATION_AMENDS_SENT_TO_VERIFIER':
        return `${itemActionTypePipe.transform(item.type)} by ${item.submitter}`;

      case 'VIR_APPLICATION_SUBMITTED':
      case 'VIR_APPLICATION_REVIEWED':
      case 'VIR_APPLICATION_RESPONDED_TO_REGULATOR_COMMENTS':
        return `${itemActionTypePipe.transform(item.type)} by ${item.submitter}`;

      case 'AIR_APPLICATION_SUBMITTED':
      case 'AIR_APPLICATION_REVIEWED':
      case 'AIR_APPLICATION_RESPONDED_TO_REGULATOR_COMMENTS':
        return `${itemActionTypePipe.transform(item.type)} by ${item.submitter}`;

      case 'VERIFICATION_STATEMENT_CANCELLED':
        return `${itemActionTypePipe.transform(item.type)} due to a change of verification body`;

      case 'PAYMENT_MARKED_AS_PAID':
        return `Payment marked as paid by ${item.submitter} (BACS)`;

      case 'PAYMENT_CANCELLED':
      case 'PAYMENT_MARKED_AS_RECEIVED':
        return `${itemActionTypePipe.transform(item.type)} by ${item.submitter}`;

      case 'RDE_ACCEPTED':
      case 'RDE_CANCELLED':
      case 'RDE_REJECTED':
      case 'RDE_FORCE_REJECTED':
      case 'RDE_FORCE_ACCEPTED':
      case 'RDE_SUBMITTED':
        return `${itemActionTypePipe.transform(item.type)} by ${item.submitter}`;

      case 'RFI_CANCELLED':
      case 'RFI_RESPONSE_SUBMITTED':
      case 'RFI_SUBMITTED':
        return `${itemActionTypePipe.transform(item.type)} by ${item.submitter}`;

      case 'PERMIT_TRANSFER_A_APPLICATION_SUBMITTED':
      case 'PERMIT_TRANSFER_B_APPLICATION_SUBMITTED':
        return `${itemActionTypePipe.transform(item.type)} by ${item.submitter}`;

      case 'DRE_APPLICATION_PEER_REVIEW_REQUESTED':
      case 'DRE_APPLICATION_PEER_REVIEWER_ACCEPTED':
      case 'DRE_APPLICATION_PEER_REVIEWER_REJECTED':
      case 'DRE_APPLICATION_CANCELLED':
        return `${itemActionTypePipe.transform(item.type)} by ${item.submitter}`;

      case 'DOAL_APPLICATION_PROCEEDED_TO_AUTHORITY':
      case 'DOAL_APPLICATION_CLOSED':
      case 'DOAL_APPLICATION_PEER_REVIEW_REQUESTED':
      case 'DOAL_APPLICATION_PEER_REVIEWER_ACCEPTED':
      case 'DOAL_APPLICATION_PEER_REVIEWER_REJECTED':
      case 'DOAL_APPLICATION_ACCEPTED':
      case 'DOAL_APPLICATION_ACCEPTED_WITH_CORRECTIONS':
      case 'DOAL_APPLICATION_REJECTED':
      case 'DOAL_APPLICATION_CANCELLED':
        return `${itemActionTypePipe.transform(item.type)} by ${item.submitter}`;

      case 'BATCH_REISSUE_SUBMITTED':
        return `${itemActionTypePipe.transform(item.type)} by ${item.submitter}`;

      case 'BATCH_REISSUE_COMPLETED':
        return `${itemActionTypePipe.transform(item.type)} by ${item.submitter}`;

      case 'REISSUE_COMPLETED':
        return `${itemActionTypePipe.transform(item.type)} by ${item.submitter}`;

      case 'EMP_ISSUANCE_UKETS_APPLICATION_SUBMITTED':
      case 'EMP_ISSUANCE_UKETS_APPLICATION_APPROVED':
      case 'EMP_ISSUANCE_UKETS_APPLICATION_DEEMED_WITHDRAWN':
      case 'EMP_ISSUANCE_CORSIA_APPLICATION_APPROVED':
      case 'EMP_ISSUANCE_CORSIA_APPLICATION_DEEMED_WITHDRAWN':
      case 'EMP_ISSUANCE_UKETS_PEER_REVIEW_REQUESTED':
      case 'EMP_ISSUANCE_UKETS_APPLICATION_RETURNED_FOR_AMENDS':
      case 'EMP_ISSUANCE_CORSIA_APPLICATION_RETURNED_FOR_AMENDS':
      case 'EMP_VARIATION_UKETS_APPLICATION_SUBMITTED':
      case 'EMP_VARIATION_CORSIA_APPLICATION_SUBMITTED':
      case 'EMP_VARIATION_APPLICATION_CANCELLED':
      case 'EMP_VARIATION_UKETS_PEER_REVIEW_REQUESTED':
      case 'EMP_VARIATION_CORSIA_PEER_REVIEW_REQUESTED':
      case 'EMP_VARIATION_UKETS_APPLICATION_REGULATOR_LED_APPROVED':
      case 'EMP_VARIATION_CORSIA_APPLICATION_REGULATOR_LED_APPROVED':
      case 'EMP_ISSUANCE_UKETS_APPLICATION_AMENDS_SUBMITTED':
      case 'EMP_ISSUANCE_CORSIA_APPLICATION_AMENDS_SUBMITTED':
      case 'EMP_ISSUANCE_UKETS_APPLICATION_PEER_REVIEWER_ACCEPTED':
      case 'EMP_ISSUANCE_UKETS_APPLICATION_PEER_REVIEWER_REJECTED':
      case 'EMP_ISSUANCE_CORSIA_PEER_REVIEW_REQUESTED':
      case 'EMP_ISSUANCE_CORSIA_APPLICATION_PEER_REVIEWER_ACCEPTED':
      case 'EMP_ISSUANCE_CORSIA_APPLICATION_PEER_REVIEWER_REJECTED':
      case 'EMP_VARIATION_UKETS_APPLICATION_PEER_REVIEWER_ACCEPTED':
      case 'EMP_VARIATION_UKETS_APPLICATION_PEER_REVIEWER_REJECTED':
      case 'EMP_VARIATION_CORSIA_APPLICATION_PEER_REVIEWER_REJECTED':
      case 'EMP_VARIATION_CORSIA_APPLICATION_PEER_REVIEWER_ACCEPTED':
      case 'EMP_ISSUANCE_UKETS_RECALLED_FROM_AMENDS':
      case 'EMP_VARIATION_UKETS_APPLICATION_APPROVED':
      case 'EMP_VARIATION_UKETS_APPLICATION_DEEMED_WITHDRAWN':
      case 'EMP_VARIATION_UKETS_APPLICATION_REJECTED':
      case 'EMP_VARIATION_CORSIA_APPLICATION_APPROVED':
      case 'EMP_VARIATION_CORSIA_APPLICATION_DEEMED_WITHDRAWN':
      case 'EMP_VARIATION_CORSIA_APPLICATION_REJECTED':
      case 'EMP_VARIATION_UKETS_APPLICATION_AMENDS_SUBMITTED':
      case 'EMP_VARIATION_CORSIA_APPLICATION_AMENDS_SUBMITTED':
      case 'EMP_VARIATION_UKETS_RECALLED_FROM_AMENDS':
      case 'EMP_VARIATION_CORSIA_RECALLED_FROM_AMENDS':
      case 'EMP_VARIATION_UKETS_APPLICATION_RETURNED_FOR_AMENDS':
      case 'EMP_VARIATION_CORSIA_APPLICATION_RETURNED_FOR_AMENDS':
      case 'EMP_ISSUANCE_CORSIA_APPLICATION_SUBMITTED':
      case 'EMP_ISSUANCE_CORSIA_RECALLED_FROM_AMENDS':
        return `${itemActionTypePipe.transform(item.type)} by ${item.submitter}`;

      case 'NON_COMPLIANCE_APPLICATION_SUBMITTED':
      case 'NON_COMPLIANCE_APPLICATION_CLOSED':
        return `${itemActionTypePipe.transform(item.type)} by ${item.submitter}`;

      case 'NON_COMPLIANCE_DAILY_PENALTY_NOTICE_APPLICATION_SUBMITTED':
      case 'NON_COMPLIANCE_DAILY_PENALTY_NOTICE_PEER_REVIEW_REQUESTED':
      case 'NON_COMPLIANCE_DAILY_PENALTY_NOTICE_PEER_REVIEWER_ACCEPTED':
      case 'NON_COMPLIANCE_DAILY_PENALTY_NOTICE_PEER_REVIEWER_REJECTED':
        return `${itemActionTypePipe.transform(item.type)} by ${item.submitter}`;

      case 'NON_COMPLIANCE_NOTICE_OF_INTENT_APPLICATION_SUBMITTED':
      case 'NON_COMPLIANCE_NOTICE_OF_INTENT_PEER_REVIEW_REQUESTED':
      case 'NON_COMPLIANCE_NOTICE_OF_INTENT_PEER_REVIEWER_ACCEPTED':
      case 'NON_COMPLIANCE_NOTICE_OF_INTENT_PEER_REVIEWER_REJECTED':
        return `${itemActionTypePipe.transform(item.type)} by ${item.submitter}`;

      case 'NON_COMPLIANCE_CIVIL_PENALTY_APPLICATION_SUBMITTED':
      case 'NON_COMPLIANCE_CIVIL_PENALTY_PEER_REVIEW_REQUESTED':
      case 'NON_COMPLIANCE_CIVIL_PENALTY_PEER_REVIEWER_ACCEPTED':
      case 'NON_COMPLIANCE_CIVIL_PENALTY_PEER_REVIEWER_REJECTED':
        return `${itemActionTypePipe.transform(item.type)} by ${item.submitter}`;

      case 'NON_COMPLIANCE_FINAL_DETERMINATION_APPLICATION_SUBMITTED':
        return `${itemActionTypePipe.transform(item.type)} by ${item.submitter}`;

      case 'WITHHOLDING_OF_ALLOWANCES_APPLICATION_SUBMITTED':
      case 'WITHHOLDING_OF_ALLOWANCES_APPLICATION_WITHDRAWN':
      case 'WITHHOLDING_OF_ALLOWANCES_APPLICATION_CLOSED':
      case 'WITHHOLDING_OF_ALLOWANCES_PEER_REVIEW_REQUESTED':
      case 'WITHHOLDING_OF_ALLOWANCES_APPLICATION_PEER_REVIEWER_ACCEPTED':
      case 'WITHHOLDING_OF_ALLOWANCES_APPLICATION_PEER_REVIEWER_REJECTED':
      case 'WITHHOLDING_OF_ALLOWANCES_APPLICATION_CANCELLED':
        return `${itemActionTypePipe.transform(item.type)} by ${item.submitter}`;

      case 'AVIATION_ACCOUNT_CLOSURE_CANCELLED':
      case 'AVIATION_ACCOUNT_CLOSURE_SUBMITTED':
        return `${itemActionTypePipe.transform(item.type)} by ${item.submitter}`;

      case 'AVIATION_AER_UKETS_APPLICATION_SENT_TO_VERIFIER':
      case 'AVIATION_AER_CORSIA_APPLICATION_SENT_TO_VERIFIER':
      case 'AVIATION_AER_UKETS_APPLICATION_AMENDS_SENT_TO_VERIFIER':
      case 'AVIATION_AER_CORSIA_APPLICATION_AMENDS_SENT_TO_VERIFIER':
      case 'AVIATION_AER_UKETS_APPLICATION_VERIFICATION_SUBMITTED':
      case 'AVIATION_AER_CORSIA_APPLICATION_VERIFICATION_SUBMITTED':
      case 'AVIATION_AER_UKETS_APPLICATION_SUBMITTED':
      case 'AVIATION_AER_CORSIA_APPLICATION_SUBMITTED':
      case 'AVIATION_AER_UKETS_APPLICATION_AMENDS_SUBMITTED':
      case 'AVIATION_AER_CORSIA_APPLICATION_AMENDS_SUBMITTED':
      case 'AVIATION_AER_UKETS_APPLICATION_COMPLETED':
      case 'AVIATION_AER_CORSIA_APPLICATION_COMPLETED':
      case 'AVIATION_AER_UKETS_APPLICATION_REVIEW_SKIPPED':
      case 'AVIATION_AER_CORSIA_APPLICATION_REVIEW_SKIPPED':
      case 'AVIATION_AER_RECALLED_FROM_VERIFICATION':
      case 'AVIATION_AER_UKETS_APPLICATION_RETURNED_FOR_AMENDS':
      case 'AVIATION_AER_CORSIA_APPLICATION_RETURNED_FOR_AMENDS':
      case 'AVIATION_DRE_APPLICATION_CANCELLED':
      case 'AVIATION_DRE_UKETS_PEER_REVIEW_REQUESTED':
      case 'AVIATION_DRE_UKETS_PEER_REVIEWER_ACCEPTED':
      case 'AVIATION_DRE_UKETS_APPLICATION_SUBMITTED':
      case 'AVIATION_DRE_UKETS_PEER_REVIEWER_REJECTED':
      case 'AVIATION_VIR_APPLICATION_SUBMITTED':
      case 'AVIATION_VIR_APPLICATION_REVIEWED':
      case 'AVIATION_VIR_APPLICATION_RESPONDED_TO_REGULATOR_COMMENTS':
        return `${itemActionTypePipe.transform(item.type)} by ${item.submitter}`;

      case 'RETURN_OF_ALLOWANCES_APPLICATION_SUBMITTED':
      case 'RETURN_OF_ALLOWANCES_PEER_REVIEW_REQUESTED':
      case 'RETURN_OF_ALLOWANCES_APPLICATION_PEER_REVIEWER_ACCEPTED':
      case 'RETURN_OF_ALLOWANCES_APPLICATION_PEER_REVIEWER_REJECTED':
      case 'RETURN_OF_ALLOWANCES_RETURNED_APPLICATION_COMPLETED':
        return `${itemActionTypePipe.transform(item.type)} by ${item.submitter}`;

      default:
        return itemActionTypePipe.transform(item.type);
    }
  }
}
