<p class="govuk-body">
  To draft an annual emissions monitoring plan you must select one or more monitoring approaches relevant to your
  installation.
</p>

<p class="govuk-body">
  Each selected monitoring approach will require additional information about source stream categories - where an
  estimate is provided for greenhouse gasses produced by each source stream in the upcoming year.
</p>

<p class="govuk-body">
  Get help with
  <a [routerLink]="[]" (click)="goToHelpPage('help-with-monitoring-approaches')" govukLink>monitoring approaches</a>.
</p>

<p class="govuk-body">
  Get help with
  <a [routerLink]="[]" (click)="goToHelpPage('help-with-source-stream-categories')" govukLink>source stream categories</a
  >.
</p>
