<div class="govuk-grid-row">
  <div class="govuk-grid-column-two-thirds">
    <form (ngSubmit)="onSubmit()" *ngIf="form$ | async as form" [formGroup]="form">
      <govuk-error-summary *ngIf="isSummaryDisplayed | async" [form]="form"></govuk-error-summary>
      <app-page-heading size="xl">User details</app-page-heading>
      <app-user-input phoneType="full"></app-user-input>

      <app-two-fa-link
        [title]="
          (isLoggedUser$ | async) === true ? 'Change two factor authentication' : 'Reset two-factor authentication'
        "
        [link]="(isLoggedUser$ | async) === true ? '/2fa/change' : '/2fa/reset-2fa'"
        [userId]="userId$ | async"
        [accountId]="accountId$ | async"
        [userName]="userFullName"
        [role]="'OPERATOR'"
      >
      </app-two-fa-link>

      <button appPendingButton govukButton type="submit">Save</button>
    </form>
  </div>
</div>
