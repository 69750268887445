<section class="gem-c-attachment">
  <div class="gem-c-attachment__thumbnail">
    <a [href]="url" aria-hidden="true" govukLink tabindex="-1" target="_self">
      <svg
        aria-hidden="true"
        class="gem-c-attachment__thumbnail-image"
        height="140"
        version="1.1"
        viewBox="0 0 99 140"
        width="99"
      >
        <path d="M12 12h75v27H12zm0 47h18.75v63H12zm55 2v59H51V61h16m2-2H49v63h20V59z" stroke-width="0" />
        <path
          d="M49 61.05V120H32.8V61.05H49m2-2H30.75v63H51V59zm34 2V120H69.05V61.05H85m2-2H67v63h20V59z"
          stroke-width="0"
        />
        <path
          d="M30 68.5h56.5M30 77.34h56.5M30 112.7h56.5M30 95.02h56.5M30 86.18h56.5M30 103.86h56.5"
          fill="none"
          stroke-miterlimit="10"
          stroke-width="2"
        />
      </svg>
    </a>
  </div>
  <div class="gem-c-attachment__details">
    <p class="gem-c-attachment__title">
      <a [href]="url" class="gem-c-attachment__link" govukLink target="_self">{{ title }}</a>
    </p>

    <p class="gem-c-attachment__metadata">
      <span class="gem-c-attachment__attribute">
        <abbr class="gem-c-attachment__abbr" title="Comma-separated Values">{{ type }}</abbr> </span
      >,
      <span class="gem-c-attachment__size">{{ size }}</span>
    </p>
  </div>
</section>
