<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<div class="govuk-heading-m govuk-!-margin-bottom-6" *ngIf="previewDocuments">
  Document preview
  <div class="govuk-body" *ngFor="let document of previewDocuments">
    <a class="govuk-link" href="#" *ngIf="(disabled$ | async) === false" (click)="getDocument(document, $event)">{{
      document.filename
    }}</a>
    <div *ngIf="disabled$ | async">{{ document.filename }}</div>
  </div>
</div>
