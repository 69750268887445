<div govuk-text-input autoComplete="address-line1" formControlName="line1" label="Address line 1"></div>
<div govuk-text-input autoComplete="address-line2" formControlName="line2" label="Address line 2 (optional)"></div>
<div
  govuk-text-input
  autoComplete="address-level2"
  formControlName="city"
  label="Town or city"
  widthClass="govuk-!-width-one-third"
></div>
<div
  *ngIf="form.get('country')"
  govuk-select
  appCountries
  formControlName="country"
  label="Country"
  widthClass="govuk-!-width-one-third"
></div>
<div
  govuk-text-input
  autoComplete="postal-code"
  formControlName="postcode"
  label="Postcode"
  widthClass="govuk-!-width-one-third"
></div>
