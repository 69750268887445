<app-wizard-step
  (formSubmit)="onSubmit()"
  [formGroup]="form"
  [heading]="isEditing ? 'Edit source stream' : 'Add a source stream'"
  caption="Fuels and equipment inventory"
  submitText="Save and continue"
>
  <p class="govuk-body">Add all the source streams (fuels or raw materials) that relate to a regulated activity</p>
  <govuk-details summary="What is a source stream"
    >A source stream is the fuels and materials which are the source of greenhouse gas emissions at the
    installation.</govuk-details
  >
  <div formControlName="reference" govuk-text-input hint="For example, ‘F1’" label="Reference"></div>
  <app-select-other formControlName="description" label="Description">
    <option *ngFor="let option of descriptionOptions" [value]="option">
      {{ option | sourceStreamDescription }}
    </option>
    <div govukConditionalContent>
      <div formControlName="otherDescriptionName" govuk-text-input label="Provide a short name"></div>
    </div>
  </app-select-other>
  <div formControlName="type" govuk-select label="Type">
    <option *ngFor="let option of sourceStreamTypesArray" [value]="option.value">
      {{ option.label }}
    </option>
  </div>
</app-wizard-step>
<a govukLink routerLink="..">Return to: Source streams (fuels and materials)</a>
